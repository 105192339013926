/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useRef, useEffect, memo } from 'react';
import { MdDelete, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import DatePicker from '../../../components/DatePickerMaterial';
import { alertaExclusao, getUTCDate } from '../../../functions/curriculos';
import { InputResume } from '../styles';
import {
  Container,
  Header,
  SubHeaderContainer,
  ToolsContainer,
  DeleteContainer,
  BodyForm,
  DescriptionContainer,
  PeriodoContainer,
} from './styles';

const HistoricoCursos = ({ itemIndex, remove, data, setHistorico, dadosItem, refresh, handleSubmit, cid }) => {
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [instituicao, setInstituicao] = useState('');
  const [nome, setNome] = useState('(não especifiado)');
  const [showBody, setShowBody] = useState(false);

  useEffect(() => {
    setNome(dadosItem.nome);
    setInstituicao(dadosItem.instituicao);
    setDataInicial(dadosItem.dataInicio);
    setDataFinal(dadosItem.dataFim);
    if (!dadosItem.nome) {
      setShowBody(true);
    }
  }, []);

  const innerRef = useRef(null);

  return (
    <Container ref={innerRef}>
      <Header>
        <SubHeaderContainer onClick={() => setShowBody(!showBody)}>
          <DescriptionContainer className="hover-title">
            Cursou {nome} na {instituicao}
          </DescriptionContainer>
          <PeriodoContainer>
            {dataInicial && format(getUTCDate(dataInicial), 'MMM yyyy')} {dataInicial && dataFinal && ' - '}
            {dataFinal && format(getUTCDate(dataFinal), 'MMM yyyy')}
          </PeriodoContainer>
        </SubHeaderContainer>
        <ToolsContainer className="tools-container">
          {showBody ? (
            <MdKeyboardArrowUp size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          ) : (
            <MdKeyboardArrowDown size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          )}
        </ToolsContainer>
        <DeleteContainer className="tools-container">
          <MdDelete
            size={24}
            className="tools-button"
            onClick={() =>
              alertaExclusao(`Confirma a exclusão do curso "${nome}"?`, () =>
                remove(data, setHistorico, itemIndex, 'historicoCursos', dadosItem.id, refresh, cid),
              )
            }
          />
        </DeleteContainer>
      </Header>
      <BodyForm show={showBody}>
        <InputResume id="id" name="id" type="hidden" />
        <InputResume id="cid" name="cid" type="hidden" />
        <InputResume id="sequencia" name="sequencia" type="hidden" />
        <div className="row-fields">
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="nome">Nome do Curso *</label>
              <InputResume id="nome" name="nome" onChange={e => setNome(e.target.value)} maxLength={100} />
            </div>
          </div>
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="instituicao">Instituição *</label>
              <InputResume id="instituicao" name="instituicao" onChange={e => setInstituicao(e.target.value)} maxLength={100} />
            </div>
          </div>
        </div>
        <div className="row-fields">
          <div className="column-field-double">
            <div className="sub-column-field">
              <div className="field-sub-container">
                <label htmlFor="dataInicio">Período Inicial *</label>
                <DatePicker
                  id="dataInicio"
                  name="dataInicio"
                  placeholder="07/2019"
                  dateFormat="MM/yyyy"
                  views={['month', 'year']}
                  type="date"
                  onClose={handleSubmit}
                />
              </div>
            </div>
            <div className="sub-column-field">
              <div className="field-sub-container">
                <label htmlFor="dataFim">Período Final *</label>
                <DatePicker
                  id="dataFim"
                  name="dataFim"
                  placeholder="07/2019"
                  dateFormat="MM/yyyy"
                  views={['month', 'year']}
                  type="date"
                  onClose={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row-fields">
          <label htmlFor="descricao">Descrição</label>
          <InputResume id="descricao" name="descricao" multiline rows={10} style={{ textAlign: 'justify' }} />
        </div>
      </BodyForm>
    </Container>
  );
};

HistoricoCursos.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  setHistorico: PropTypes.func.isRequired,
  dadosItem: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cid: PropTypes.string,
};

HistoricoCursos.defaultProps = {
  cid: null,
};

export default memo(HistoricoCursos);
