import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 43%;
  height: calc(100vh - 190px);
  max-height: 500px;
  background: #fff;
  z-index: 9999;
  border: 1px solid #c4cbcf;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);

  @media (max-width: 768px) {
    margin-right: 0 !important;
    width: 80%;
    display: block;
  }

  img.ReactCrop__image {
    width: 320px;
  }

  img.crop-preview {
    width: 200px;
    height: 200px;
  }

  button.save-cropped-image-button {
    border: none;
    background: #00995d;
    padding: 5px 10px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #fff;
    border-radius: 5px;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 15px;
`;

export const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  background: #ed1651;
  color: #fff;
  right: 15px;
  cursor: pointer;
  position: absolute;
  text-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  align-content: center;
  display: flex;
  font-weight: 500;
`;

export const ImageEditContainer = styled.div`
  position: inherit;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 80px;
`;
