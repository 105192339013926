import React, { memo, useState, useCallback, useEffect } from 'react';

import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import UserProfile from '../../assets/user.png';
import { storage } from '../../firebase';
import { Container } from './styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 290,
  },
  media: {
    display: 'block',
    height: 69,
    objectFit: 'cover',
  },
  typography: {
    color: '#411564',
    fontSize: '16px',
  },
});

const UserPhoto = ({ userId }) => {
  const [imageUrl, setImageUrl] = useState();

  const fetchUserPhoto = useCallback(async () => {
    try {
      setImageUrl(await storage.doUserPhotoUrlLink(userId));
    } catch (error) {
      // do nothing
    }
  }, [userId]);

  useEffect(() => {
    fetchUserPhoto();
  }, [fetchUserPhoto]);
  const classes = useStyles();

  return (
    <Container>
      <CardMedia className={classes.media} image={imageUrl || UserProfile} title="Foto do candidato" />
    </Container>
  );
};

UserPhoto.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default memo(UserPhoto);
