import { useEffect } from 'react';

const useOuterClickNotifier = (onOuterClick, innerRef) => {
  function handleClick(e) {
    return !innerRef.current.contains(e.target) && onOuterClick(e);
  }

  useEffect(() => {
    if (innerRef.current) {
      global.document.addEventListener('click', handleClick);
    }

    return () => global.document.removeEventListener('click', handleClick);
	}, [onOuterClick, innerRef]); // eslint-disable-line
};

export default useOuterClickNotifier;
