import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 10px;
  background: #fff;
  height: auto;
  color: #333;
  padding: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  border-radius: 3px;

  h3 {
    text-align: center;
    color: #411564;
  }

  @media print {
    display: block;
    min-width: 100%;
    min-height: 100%;
    padding: 0 20mm !important;
    box-shadow: none;
    border: none;
    font-size: 18px;
    background: #fff;
    overflow: initial !important;
    page-break-after: always;

    @page {
      margin: 12mm 0 15mm 0;
      size: 210mm 297mm;
      marks: none;
      running-head: none;
    }

    @page :top-left {
      display: none;
    }

    /* body {
			margin: 1.6cm;
		} */

    .full-name {
      font-size: 32px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }
`;

export const SubContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 15px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  div {
    display: flex;
    flex-direction: column;
    border-left: 5px solid #411564;
    padding: 10px;
    background: #0a5f55;
    width: 31%;
    color: #fff;

    label {
      color: #b1d34b;
      font-size: 20px;
    }
  }
`;

export const ItemsContainer = styled.div``;

export const ItemHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-top: 15px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      margin: 0 10px;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const TableColumns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${props => (props.backcolor ? props.backcolor : '#0a5f55')};
  color: #fff;
  margin-top: 15px;
  border-bottom: 1px solid #fff;
`;

export const ColumnHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;

  div {
    padding: 10px;
    &:first-child {
      border-bottom: 1px solid #fff;
    }
  }
`;

export const ColumnItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;

  div {
    border-left: 1px solid #fff;
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;

export const ColumnTotal = styled.div`
  background: ${props => (props.backcolor ? props.backcolor : '#5b5c65')};
  color: #ffcb08;
  width: 10%;
  align-items: center;
  display: flex;
  justify-content: center;
  border-left: 1px solid #fff;
`;

export const TableRows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const TableSubRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const RowHeader = styled.div`
  padding: 5px 10px;
  width: 35%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    overflow: visible;
  }
`;

export const RowItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  padding: 5px 10px;

  div {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const RowTotal = styled.div`
  background: ${props => (props.backcolor ? props.backcolor : '#5b5c65')};
  width: 10%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffcb08;
`;
