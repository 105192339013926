import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';

export const toggle = styled.div``;

export const SwitchStyled = styled(Switch)`
  // max-height: 48px !important;
  // float: right !important;

  // span:first-child {
  // 	height: 20px !important;
  // }
`;
