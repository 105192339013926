import React from "react";
import { useState } from "react";
const Checkbox = ({ label}) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="checkbox-wrapper">
      <label>

        <input className="CheckBox" name="check" type="checkbox"
        onChange={() => setIsChecked((prev) => !prev)}/>

        <span className="textlgpd">{label}</span>

      </label>
    </div>
  );
};
export default Checkbox;
