/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef, useEffect, useState, memo } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Container } from './styles';

// Estou utilizando o fieldPointer ao inves do name, pois em alguns casos o nome do campo pode ser diferente quando se trata de uma outra tabela
function ReactSelect({ name, label, options, multiple, closeOnSelect, fieldPointer, getValue, limit = null, ...rest }) {
  const ref = useRef();
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [values, setValues] = useState(null);

  const parseSelectValue = selectValue => {
    if (!multiple) {
      return selectValue[0] && selectValue.length === 1 ? selectValue[0].id : '';
    }
    return selectValue && Array.isArray(selectValue) ? selectValue.map(option => option.id) : [];
  };

  useEffect(() => {
    getValue(values);
  }, [values]);

  // Para não dar estouro de memória usar [ref.current, fieldName]
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'state.value',
      parseValue: selectRef => parseSelectValue(selectRef.props.value),
      clearValue: selectRef => {
        selectRef.select.clearValue();
      },
    });
  }, [ref.current, fieldName]);

  const getDefaultValue = () => {
    if (!defaultValue) return null;
    if (!multiple) {
      if (values === null) {
        const passagem = options.filter(option => defaultValue === option.id);
        const valuesToSet = passagem.map(item => item.id);
        setValues(valuesToSet);
      }
      return options.find(option => option.id === defaultValue);
    }
    if (values === null) {
      const passagem = options.filter(option => defaultValue.some(value => value[fieldPointer] === option.id));
      const valuesToSet = passagem.map(item => item.id);
      setValues(valuesToSet);
    }
    return options.filter(option => defaultValue.some(value => value[fieldPointer] === option.id));
  };

  return (
    <Container>
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        components={multiple && makeAnimated()}
        styles={
          multiple && {
            multiValueLabel: base => ({
              ...base,
              backgroundColor: '#411564',
              color: '#ffcb08',
              padding: '5px 8px',
              borderRadius: 'none',
              marginBottom: '6px',
            }),
            control: base => ({ ...base, padding: values && values.length > 0 ? '6px 0 0 0' : '6px 0' }),
            clearIndicator: base => ({ ...base, cursor: 'pointer' }),
            dropdownIndicator: base => ({ ...base, cursor: 'pointer' }),
            multiValueRemove: base => ({
              ...base,
              cursor: 'pointer',
              background: '#b1d34b',
              borderRadius: 'none',
              padding: '5px 8px',
              marginBottom: '6px',
              marginRight: '5px',
            }),
          }
        }
        closeMenuOnSelect={closeOnSelect}
        className="react-select-unimed"
        name={fieldName}
        aria-label={fieldName}
        options={values && values.length >= limit && limit !== null ? [] : options}
        isMulti={multiple}
        value={
          Array.isArray(values)
            ? options.filter(option => values.includes(option.id))
            : options.filter(option => option.id === values)
        }
        defaultValue={getDefaultValue()}
        ref={ref}
        placeholder="Selecione..."
        {...rest}
        getOptionLabel={option => option.title}
        getOptionValue={option => option.id}
        onChange={valores =>
          Array.isArray(valores) ? setValues(valores.map(option => option.id)) : valores ? setValues(valores.id) : setValues(null)
        }
        noOptionsMessage={() =>
          values && values.length >= limit && limit !== null
            ? 'Número máximo de setores já selecionado, exclua um, caso deseje inserir outro setor'
            : 'Nada encontrado...'
        }
      />
      {error && <span>{error}</span>}
    </Container>
  );
}

ReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  multiple: PropTypes.bool.isRequired,
  label: PropTypes.string,
  // defaultValue: PropTypes.any,
  closeOnSelect: PropTypes.bool.isRequired,
  fieldPointer: PropTypes.string.isRequired,
  getValue: PropTypes.func,
  limit: PropTypes.number,
};

ReactSelect.defaultProps = {
  label: '',
  limit: null,
  // defaultValue: null,
  getValue: () => {},
};

export default memo(ReactSelect);
