/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

 //---------------------------COMEÇO DO MÉTODO CERTO--------------------------------/
import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import history from '~/services/history';
import { store } from '~/store';
import { toggleLoading, setCurrentPage, setPageDescription } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import MediaCard from '../../components/MediaCard';
import * as routes from '../../configs/Routes';
import { getLocalDate } from '../../functions/curriculos';
import useWindowDimensions from '../../functions/useWindowsDimensions';
import api from '../../services/api';
import ContatoConfirmacao from './ContatoConfirmacao';
import Evaluation from './Evaluation';
import Interview from './Interview';
import RemovedResumeList from './RemovedResumeList';
import Resume from './Resume';
import { Container, StepContentContainer, ButtonContainer, Button, StepInnerContainer, NoApplicantContainer } from './styles';
import Summary from './Summary';

const useStyles = makeStyles(() => ({
  stepperContainerStyle: {
    background: 'none',
    color: '#333 !important',
  },
  labelStyle: {
    color: '#333 !important',
  },
}));

const EtapasProcessoSeletivo = () => {
  const { profile: userData } = store.getState().user;
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepId, setActiveStepId] = useState(0);
  const classes = useStyles();
  const [curriculos, setCurriculos] = useState([]);
  const trash = useMemo(
    () => curriculos.filter(cur => cur.etapaProcessoSeletivoId === activeStepId && cur.excluirDoProcesso),
    [activeStepId, curriculos],
  );
  const [processoSeletivoId, setProcessoSeletivoId] = useState(0);
  const [steps, setSteps] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const { state, search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    dispatch(setCurrentPage('Etapas do Processo Seletivo'));
    dispatch(setPageDescription('Carregando Informações...'));
    setProcessoSeletivoId(state ? state.processoSeletivoId : params.get('id'));

    return () => {
      dispatch(setPageDescription(null));
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setActiveStep(steps.findIndex(stp => stp.id === activeStepId));
  }, [activeStepId, steps]);

  const fetchStepData = useCallback(async () => {
    if (!processoSeletivoId) return;
    const {
      data: { data: etapasDoProcesso },
    } = await api.get(`/etapaprocessoseletivo/processoseletivo/${processoSeletivoId}`);
    //Logica implementada...

    //fim da lógica implementada...
    etapasDoProcesso.forEach(item => {
      setSteps(stp => [
        ...stp,
        { id: item.id, label: item.etapa.nome, lancarValor: Boolean(item.lancarValor), aptoInapto: Boolean(item.aptoInapto) },
      ]);
    });

    const {
      data: { data: processoSeletivoSelecionado },
    } = await api.get('/processoseletivo/one', { headers: { id: processoSeletivoId } });
    setActiveStepId(processoSeletivoSelecionado.etapaProcessoSeletivoId);
  }, [processoSeletivoId]);

  useEffect(() => {
    fetchStepData();
  }, [fetchStepData]);

  const fetchData = useCallback(async () => {
    dispatch(toggleLoading(true));
    const {
      data: { data: dados, status, message },
    } = await api.get('processoseletivo/specific-process', {
      headers: {
        id: parseInt(processoSeletivoId),
        etapaProcessoSeletivoId: parseInt(activeStepId),
      },
    });

    if (!status || !dados) {
      enqueueSnackbar(`Não foi possível retornar dados: ${message}`, { variant: 'error' });
      dispatch(toggleLoading(false));
      return;
    }
    dispatch(setPageDescription(dados.nome));
    setCurriculos(dados.curriculoEtapaProcessoSeletivos);

    if (!dados.etapaProcessoSeletivoId) {
      const primeiraEtapa = dados.etapaProcessoSeletivos[0];
      await api.put('processoseletivo', { ...dados, etapaProcessoSeletivoId: primeiraEtapa.id });
      setActiveStepId(primeiraEtapa.id);
    } else {
      setActiveStepId(dados.etapaProcessoSeletivoId);
    }

    dispatch(toggleLoading(false));
  }, [activeStepId, dispatch, enqueueSnackbar, processoSeletivoId]);

  useEffect(() => {
    if (!processoSeletivoId || activeStepId === 0 || steps.length - 1 === activeStep) return;
    fetchData();
  }, [activeStep]); // eslint-disable-line

  const showForm = useCallback(
    data => {
      const stepToGo = steps[activeStep + 1];
      const passingData = { ...data, etapaProcessoSeletivos: steps };
      switch (activeStep) {
        case 0:
          dispatch(
            toggleModal(
              true,
              <Resume
                curriculoData={passingData}
                stepToGo={stepToGo}
                refresh={setCurriculos}
                processoSeletivoId={parseInt(processoSeletivoId)}
              />,
              'resume',
              '',
            ),
          );
          break;
        case 1:
          dispatch(
            toggleModal(
              true,
              <ContatoConfirmacao
                curriculoData={passingData}
                stepToGo={stepToGo}
                refresh={setCurriculos}
                processoSeletivoId={parseInt(processoSeletivoId)}
              />,
              'contato-confirmacao',
              '',
            ),
          );
          break;
        case steps.length - 1:
          dispatch(
            toggleModal(
              true,
              <Evaluation
                curriculoData={passingData}
                stepToGo={stepToGo}
                refresh={setCurriculos}
                processoSeletivoId={parseInt(processoSeletivoId)}
                id="print-div"
              />,
              'evaluation',
              '',
            ),
          );
          break;
        default:
          dispatch(
            toggleModal(
              true,
              <Interview
                curriculoData={passingData}
                stepToGo={stepToGo}
                refresh={setCurriculos}
                processoSeletivoId={parseInt(processoSeletivoId)}
              />,
              'interview',
              '',
            ),
          );
          break;
      }
    },
    [activeStep, dispatch, processoSeletivoId, steps],
  );

  const getStepContent = useCallback(
    stepIndex => {
      const etapaProcesso = steps[stepIndex];
      if (etapaProcesso) {
        const resumeOfStepList = curriculos.filter(
          cur => cur.etapaProcessoSeletivoId === etapaProcesso.id && !cur.excluirDoProcesso,
        );

        const listItem = resumeOfStepList.map(item => (
          <MediaCard data={item} showForm={() => showForm(item)} key={item.curriculo.id} />
        ));

        if (listItem.length === 0) {
          return (
            <NoApplicantContainer>
              Muito bom! Você analisou todos os currículos desta etapa. Clique em avançar.
            </NoApplicantContainer>
          );
        }
        return listItem;
      }
      return null;
    },
    [curriculos, showForm, steps],
  );

  const handleStepForward = useCallback(async () => {
    if (activeStep === steps.length) return;
    const stepToGo = steps[activeStep + 1];

    if (!stepToGo) return;
    const processoSeletivo = {
      id: processoSeletivoId,
      etapaProcessoSeletivoId: stepToGo.id,
      dataAlteracao: getLocalDate(),
      usuarioAlteracao: userData.id,
    };

    await api.put('processoseletivo', processoSeletivo);
    setActiveStepId(stepToGo.id);
  }, [activeStep, processoSeletivoId, steps, userData.id]);

  const handleStepBackward = useCallback(async () => {
    if (activeStep === 0) return;
    const stepToGo = steps[activeStep - 1];

    if (!stepToGo) return;
    const processoSeletivo = {
      id: processoSeletivoId,
      etapaProcessoSeletivoId: stepToGo.id,
      dataAlteracao: getLocalDate(),
      usuarioAlteracao: userData.id,
    };

    await api.put('processoseletivo', processoSeletivo);
    setActiveStepId(stepToGo.id);
  }, [activeStep, processoSeletivoId, steps, userData.id]);

  const handleFinish = useCallback(() => {
    enqueueSnackbar('Processo Seletivo Finalizado com sucesso', { variant: 'success' });
    history.push(routes.MY_PROCESS);
  }, [enqueueSnackbar]);

  return (
    <Container>
      <>
        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepperContainerStyle}>
          {steps.map(item => (
            <Step key={item.id}>
              <StepLabel className={classes.labelStyle}>{item.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <StepContentContainer>
          <Scrollbars
            style={{ height: 'calc(100vh - 250px)', width: '100%', overflowX: 'hidden' }}
            renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
          >
            {steps.length - 1 !== activeStep ? (
              <StepInnerContainer width={width}>{getStepContent(activeStep)}</StepInnerContainer>
            ) : (
              processoSeletivoId && <Summary processoSeletivoId={parseInt(processoSeletivoId)} />
            )}
          </Scrollbars>
        </StepContentContainer>
        {steps.length > 0 && (
          <ButtonContainer>
            {activeStep !== 0 && (
              <Button type="button" onClick={() => handleStepBackward()}>
                Voltar
              </Button>
            )}
            {activeStep < steps.length - 1 && (
              <Button type="button" onClick={() => handleStepForward()}>
                Avançar
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <>
                <Button type="button" onClick={() => handleFinish()}>
                  Finalizar
                </Button>
                <Button type="Button" backcolor="#411564" onClick={() => {}}>
                  Imprimir
                </Button>
              </>
            )}
          </ButtonContainer>
        )}
      </>
      {trash.length > 0 && <RemovedResumeList curriculos={trash} refresh={setCurriculos} />}
    </Container>
  );
};

EtapasProcessoSeletivo.propTypes = {
  state: PropTypes.instanceOf(Object),
  search: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object).isRequired,
};

EtapasProcessoSeletivo.defaultProps = {
  state: {},
  search: {},
};

export default memo(EtapasProcessoSeletivo);

//---------------------------FIM DO MÉTODO CERTO--------------------------------/
