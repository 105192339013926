import React, { useState, useEffect, useRef, memo } from 'react';
import { MdDashboard, MdFolderOpen, MdAssignment, MdContacts, MdFolderShared, MdHdrWeak, MdVideocam } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { useOuterClickNotifier } from '~/functions/';
import { history, api } from '~/services';
import { store } from '~/store';
import { toggleSideDrawer } from '~/store/modules/main/actions';

import * as routes from '../../configs/Routes';
import MenuButton from '../MenuButton';
import { Container } from './styles';

const MenuLateral = () => {
  const [caminho, setCaminho] = useState('');
  const [, setError] = useState('');
  const [, setPermissions] = useState([]);
  const ref = useRef();
  const { profile: userData } = store.getState().user;
  const allowMyProcess = [2, 3, 4];
  const allowMaster = [2, 3];
  const dispatch = useDispatch();
  const sideDrawerOpen = useSelector(state => state.main.sideDrawerOpen);

  useOuterClickNotifier(() => (!sideDrawerOpen ? dispatch(toggleSideDrawer(!sideDrawerOpen)) : {}), ref);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`tipousuario`);
        const { data } = response;
        if (data.length !== 0) {
          data.forEach(item => {
            setPermissions(permission => [...permission, item]);
          });
        }
      } catch (error) {
        setError('Não foi possível carregar permissão do usuário');
      }
    };
    fetchData();
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    if (caminho === '') return;
    history.push({ pathname: '/empty' });
    history.replace(caminho);
    const hideMenu = async () => {
      await delay(500);
      dispatch(toggleSideDrawer(!sideDrawerOpen));
    };
    hideMenu();
  }, [caminho, dispatch]); // eslint-disable-line

  return (
    <Container open={sideDrawerOpen} ref={ref}>
      {allowMaster.includes(userData.userClaimId) && (
        <MenuButton
          nome="Dashboard"
          onClick={async () => {
            setCaminho('');
            await delay();
            setCaminho(routes.HOME);
          }}
          color={history.location.pathname === routes.HOME ? '#411564' : '#00995D'}
          active={history.location.pathname === routes.HOME}
        >
          <MdDashboard className="icon-menu" />
        </MenuButton>
      )}
      {allowMyProcess.includes(userData.userClaimId) && (
        <MenuButton
          nome="Meus Processos"
          onClick={async () => {
            setCaminho('');
            await delay();
            setCaminho(routes.MY_PROCESS);
          }}
          color={history.location.pathname === routes.MY_PROCESS ? '#411564' : '#00995D'}
          active={history.location.pathname === routes.MY_PROCESS}
        >
          <MdFolderOpen className="icon-menu" />
        </MenuButton>
      )}
      <MenuButton
        nome="Meu Currículo"
        onClick={async () => {
          setCaminho('');
          await delay();
          setCaminho(routes.RESUME);
        }}
        color={history.location.pathname === routes.RESUME ? '#411564' : '#00995D'}
        active={history.location.pathname === routes.RESUME}
      >
        <MdAssignment className="icon-menu" />
      </MenuButton>
      {allowMaster.includes(userData.userClaimId) && (
        <MenuButton
          nome="Banco de Currículos"
          onClick={async () => {
            setCaminho('');
            await delay();
            setCaminho(routes.RESUME_STORE);
          }}
          color={history.location.pathname === routes.RESUME_STORE ? '#411564' : '#00995D'}
          active={history.location.pathname === routes.RESUME_STORE}
        >
          <MdContacts className="icon-menu" />
        </MenuButton>
      )}
      {allowMaster.includes(userData.userClaimId) && (
        <MenuButton
          nome="Processos Seletivos"
          onClick={async () => {
            setCaminho('');
            await delay();
            setCaminho(routes.ADD_JOBS);
          }}
          color={history.location.pathname === routes.ADD_JOBS ? '#411564' : '#00995D'}
          active={history.location.pathname === routes.ADD_JOBS}
        >
          <MdFolderShared className="icon-menu" />
        </MenuButton>
      )}
      {allowMaster.includes(userData.userClaimId) && (
        <MenuButton
          nome="Configurações"
          onClick={async () => {
            setCaminho('');
            await delay();
            setCaminho(routes.SETTINGS);
          }}
          color={history.location.pathname === routes.SETTINGS ? '#411564' : '#00995D'}
          active={history.location.pathname === routes.SETTINGS}
        >
          <MdHdrWeak className="icon-menu" />
        </MenuButton>
      )}

      {allowMaster.includes(userData.userClaimId) && (
        <MenuButton
          nome="Vídeo do Candidato"
          onClick={async () => {
            setCaminho('');
            await delay();
            setCaminho(routes.USER_VIDEO);
          }}
          color={history.location.pathname === routes.USER_VIDEO ? '#411564' : '#00995D'}
          active={history.location.pathname === routes.USER_VIDEO}
        >
          <MdVideocam className="icon-menu" />
        </MenuButton>
      )}
    </Container>
  );
};

export default memo(MenuLateral);
