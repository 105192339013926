import * as funcoes from '~/functions/curriculos';
import api from '~/services/api';

export default async function createUserResume(id) {
  const initialResumeData = {
    usuarioId: id,
    dataCadastro: funcoes.getLocalDate(),
    usuarioCadastro: id,
  };

  await api.post('curriculos', initialResumeData);
}
