import React, { useState, useEffect, memo } from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import UniVerticalTimeLine from '../../components/UniVerticalTimeLine';
import api from '../../services/api';
import { ContainerForm, HeaderForm, NoteContainer, CustomDiv, HistoryTitle } from './styles';

function FormObservacaoCandidato({ data: dados }) {
  const [data] = useState(dados.dataLoad);
  const [observacoes, setObservacoes] = useState([]);
  const [processHistory, setProcessHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: returnedData } = await api.get(`preanalisecurriculo/curriculo/${data}`);
      const { data: returned } = returnedData;
      if (returned.length > 0) {
        returned.forEach(item => {
          if (!item.tipoClassificacaoCurriculoId) return;
          setObservacoes(observacao => [...observacao, item]);
        });
      }
      const { data: processHistoryReturn } = await api.get('/historicoprocesso/bycurriculo', {
        headers: { curriculoId: data },
      });

      if (processHistoryReturn.data && processHistoryReturn.data.length > 0) {
        processHistoryReturn.data.forEach(item => {
          setProcessHistory(his => [
            ...his,
            {
              title: item.processoSeletivo.nome,
              subtitle: item.etapaProcessoSeletivo.etapa.nome,
              date: item.etapaProcessoSeletivo.dataAlteracao,
              description:
                item.etapaProcessoSeletivo.observacaoCurriculoEtapaProcessoSeletivos[0] &&
                item.etapaProcessoSeletivo.observacaoCurriculoEtapaProcessoSeletivos[0].observacao,
              removedFromProcess: Boolean(item.excluirDoProcesso),
            },
          ]);
        });
      }
    };
    fetchData();
  }, []); // eslint-disable-line

  return (
    <ContainerForm>
      <HeaderForm>
        <h1>Observações</h1>
        <span>Observações do candidato na pré análise curricular e em processos seletivos</span>
      </HeaderForm>
      <CustomDiv>
        <div className="pre-analise">
          <HistoryTitle>Pré Análise do Currículo</HistoryTitle>
          {observacoes &&
            observacoes.map(item => (
              <NoteContainer key={item.id}>
                <h4>{item.tipoClassificacaoCurriculo.nome}</h4>
                <span>{item.observacao}</span>
                <p>Data de cadastro da análise: {format(new Date(item.dataCadastro), 'dd/MM/yyyy')}</p>
              </NoteContainer>
            ))}
        </div>
        <div className="pre-analise">
          <HistoryTitle>Histórico de Processos Seletivos</HistoryTitle>
          {processHistory && <UniVerticalTimeLine events={processHistory} />}
        </div>
      </CustomDiv>
    </ContainerForm>
  );
}

FormObservacaoCandidato.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default memo(FormObservacaoCandidato);
