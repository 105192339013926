import styled from 'styled-components';

export const Container = styled.div`
  .option-group {
    max-width: 400px;
    height: 90px;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    font-size: 4rem;
    margin: 0.2em auto;
    will-change: transform;
    transform: translateZ(0);
  }

  .option-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 120%;
    height: 100%;
    margin: 0 -10%;
  }

  .option {
    overflow: hidden;
    flex: 1;
    display: block;
    padding: 0.5em;
    background-color: rgb(242, 245, 250);
    position: relative;
    margin: 0em;
    margin-right: 0.2em;
    &:last-child {
      margin-right: 0;
    }

    border-radius: 3px;

    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    cursor: pointer;

    opacity: 0.5;
    transition-duration: 0.8s, 0.6s;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.98, 0, 0.22, 0.98), linear;
    will-change: transform, opacity;
  }

  .option__indicator {
    display: block;
    transform-origin: left bottom;
    transition: inherit;
    will-change: transform;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    left: 0.5em;

    &:before,
    &:after {
      content: '';
      display: block;
      border: solid 2px #00995d;
      border-radius: 50%;
      width: 0.25em;
      height: 0.25em;
      position: absolute;
      top: 0;
      right: 0;
    }

    &:after {
      background: #00995d;
      transform: scale(0);
      transition: inherit;
      will-change: transform;
    }
  }

  input.option-input {
    position: absolute;
    top: 0;
    z-index: -1;
    visibility: hidden;
  }

  .option__label {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold;

    transform-origin: left bottom;
    transform: translateX(20%) scale(0.7);
    transition: inherit;
    will-change: transform;

    .sub {
      margin-left: 0.25em;
      font-size: 0.15em;
      display: inline-block;
      vertical-align: -1em;
      color: #333;
      top: -15px;
    }

    &:after {
      content: '';
      display: block;
      border: solid 2px #00995d;
      width: 100%;
      transform-origin: 0 0;
      transform: scaleX(0.2);
      transition: inherit;
      will-change: transform;
    }
  }

  .option:last-child {
    .option__label {
      transform: translateX(0%) scale(0.7);
    }
    .option__indicator {
      transform: translateX(-20%);
    }
  }

  .option-input:checked ~ .option {
    transform: translateX(-20%) translateX(0.2em);
    .option__indicator {
      transform: translateX(0%);
    }
    .option__label {
      transform: translateX(40%) scale(0.7);
    }
  }

  .option-input:first-child:checked ~ .option {
    transform: translateX(20%) translateX(-0.2em);
    .option__indicator {
      transform: translateX(-40%);
    }
    .option__label {
      transform: translateX(0%) scale(0.7);
    }
  }

  .option-input:nth-child(1):checked ~ .option:nth-of-type(1),
  .option-input:nth-child(2):checked ~ .option:nth-of-type(2) {
    opacity: 1;
    .option__indicator {
      transform: translateX(0);
      &::after {
        transform: scale(1);
      }
    }
    .option__label,
    .option__label::after {
      transform: scale(1);
    }
  }
`;
