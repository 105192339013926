import React from 'react';

import { Container } from './styles';

export default function LoaderDevices() {
  return (
    <Container>
      <div className="loader">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </Container>
  );
}
