import styled from 'styled-components';

export const DashContainer = styled.div`
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: 100vh;
  width: 100%;
  height: 100%;

  @media print {
    background-color: #fff;
    display: block !important;
    grid-template-columns: 1fr;
  }
`;

export const ConfigContainer = styled.div`
  border-bottom: 1px solid #411564;
  color: #000;
  margin: 5px;
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100vw - 850px);

  h3 {
    color: #411564;
  }

  h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
  }

  @media print {
    display: none;
  }

  span {
    margin-left: 10px;
  }
`;

export const Container = styled.div`
  display: inline-table;
  max-width: 810px;
  min-width: 800px;
  min-height: 100vh;
  background: #fff;
  margin: 0;
  padding: 40px 60px 120px 60px;
  page-break-after: always;

  @media print {
    min-width: 100% !important;
    min-height: 100%;
    padding: 0 20mm !important;
    box-shadow: none;
    border: none;
    font-size: 18px;
    background: #fff;
    overflow: initial !important;
    page-break-after: always;

    @page {
      margin: 12mm 0 15mm 0;
      size: 210mm 297mm;
      marks: none;
      running-head: none;
    }

    @page :top-left {
      display: none;
    }

    /* body {
			margin: 1.6cm;
		} */

    .full-name {
      font-size: 32px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }

  .header {
    margin-bottom: 30px;

    .full-name {
      font-size: 38px;
      text-transform: uppercase;
      margin: 6px 0 20px 0;
      position: relative;

      img {
        position: absolute;
        width: 110px;
        height: 110px;
        border-radius: 15em;
        top: 0px;
        right: -30px;
        border: 3px solid #411564;
        object-fit: cover;
      }
    }

    .first-name {
      font-weight: 700;
    }

    .last-name {
      font-weight: 300;
      width: 595px;
      font-size: 27px;
    }

    .contact-info {
      margin-bottom: 2px;
    }

    .email,
    .phone {
      color: #999;
      font-weight: 300;
    }

    .separator {
      height: 10px;
      display: inline-block;
      border-left: 2px solid #999;
      margin: 0px 10px;
    }

    .position {
      font-weight: bold;
      display: inline-block;
      margin-right: 10px;
      text-decoration: underline;
    }
  }

  .details {
    line-height: 20px;

    .section {
      margin-top: 25px;
    }

    .section:last-of-type {
      margin-bottom: 0px;
    }

    .section__title {
      letter-spacing: 2px;
      color: #411564;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .section__list-item {
      margin-bottom: 10px;
    }

    .section__list-item:last-of-type {
      margin-bottom: 0;
    }

    .left,
    .right {
      vertical-align: top;
      display: inline-block;
    }

    .left {
      width: 60%;
    }

    .right {
      text-align: right;
      width: 39%;
    }

    .name {
      font-weight: bold;
    }

    .addr {
      text-align: justify;
    }

    a {
      text-decoration: none;
      color: #000;
      font-style: italic;
    }

    a:hover {
      text-decoration: underline;
      color: #000;
    }

    .skills {
    }

    .skills__item {
      margin-bottom: 1px;
    }

    .skills__item .right {
      input {
        display: none;
      }

      label {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #c3def3;
        border-radius: 20px;
        margin-right: 3px;
      }

      input:checked + label {
        background: #0a5f55;
      }
    }
  }
`;

export const RightItemsContainer = styled.div`
  position: fixed;
  right: 50px;
  top: 85px;
  border-left: 1px solid rgba(150, 150, 150, 0.5);
  height: calc(100vh - 160px);
  padding: 0 0 0 7px;

  p {
    text-align: justify;
  }

  a.action-buttons {
    margin-left: 15px;
    color: #666;
    cursor: pointer;
    right: 50px;
    position: absolute;
    top: -28px;
  }
`;

export const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  color: #fff;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: 30px;

  svg {
    cursor: pointer;
    color: ${props => (props.down ? '#ff0000' : '#333')};

    &:first-child {
      color: ${props => (props.up ? '#20639b' : '#333')};
      margin-right: 10px;
    }
  }
`;

export const LoadingDataMessage = styled.h1`
  display: flex;
  flex-direction: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #411564;
  animation: blinker 1s linear infinite;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export const ProcessListContainer = styled.div`
  color: #000;
  margin: 5px;
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100vw - 850px);
  height: 330px;
  border-bottom: 1px solid #411564;
  transition: all 0.4s;

  div[class^='-menu'] {
    z-index: 9999 !important;
  }

  @media print {
    display: none;
  }

  h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    display: block;
    caret-color: rgb(33, 150, 243);
    background-color: rgb(242, 245, 250);
    color: rgb(38, 43, 51);
    pointer-events: unset;
    border-radius: 3px;
    padding: 11px 16px;
    outline: none;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    transition: color 0.1s ease 0s;
    font-size: 18px;
    margin-bottom: 24px;
  }

  form {
    /* opacity: ${props => (props.showPreAnalise ? 1 : 0)};
    /* height: ${props => (props.showPreAnalise ? '100%' : '0')}; */
    /* transition: all 0.2s;  */
  }
`;
