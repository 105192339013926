import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import produce from 'immer';
import { useSnackbar } from 'notistack';

import { toggleLoading, setCurrentPage } from '~/store/modules/main/actions';

import List from '../../components/BoardList';
import api from '../../services/api';
import BoardContext from './context';
import { Container } from './styles';

const Vagas = () => {
  const [lists, setList] = useState();
  const [refresh, setRefresh] = useState(false);
  const [setores, setSetores] = useState([]);
  const [coordenadores, setCoordenadores] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [caracteristicas, setCaracteristicas] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPage('Processos Seletivos'));
  }, [dispatch]);

  // retornando dados dos setores
  useEffect(() => {
    const fetchData = async () => {
      const { data: dados } = await api.get('setores');
      if (dados.length) {
        dados.forEach(item => {
          setSetores(setor => [...setor, { id: item.id, title: item.nome }]);
        });
      }

      const { data: dadosCoordenadores } = await api.get('usuarios/coordenadores');
      if (dadosCoordenadores.length) {
        dadosCoordenadores.forEach(item => {
          setCoordenadores(coordenador => [...coordenador, { id: item.id, title: item.nome }]);
        });
      }

      const { data: dadosEtapas } = await api.get('etapa');
      if (dadosEtapas.length) {
        dadosEtapas.forEach(item => {
          setEtapas(etapa => [...etapa, { id: item.id, title: item.nome }]);
        });
      }

      const { data: options } = await api.get('caracteristicacompetencia');
      if (options) {
        options.forEach(item => {
          setCaracteristicas(caracteristica => [...caracteristica, { id: item.id, title: item.nome }]);
        });
      }
    };
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    dispatch(toggleLoading(true));
    const fetchData = async () => {
      setList([]);
      const boardList = await api.get('boardlist');
      if (boardList.status) {
        setList(boardList.data);
      } else {
        enqueueSnackbar(boardList.message, { variant: 'success' });
      }
      dispatch(toggleLoading(false));
    };
    fetchData();
    return () => {
      source.cancel('Usuario redirecionado. Request cancelado');
    };
  }, [refresh, toggleLoading]); // eslint-disable-line

  async function save(fromList, toList, from, to, listId, cardId) {
    if (fromList === toList) return;
    const cardToSave = lists[fromList].processoSeletivo[from];
    const cardMoved = { ...cardToSave, boardListId: listId };
    await api.put('processoSeletivo', cardMoved);
  }

  function move(fromList, toList, from, to, listId, cardId) {
    setList(
      produce(lists, draft => {
        const dragged = draft[fromList].processoSeletivo[from];
        draft[fromList].processoSeletivo.splice(from, 1);
        draft[toList].processoSeletivo.splice(to, 0, dragged);
      }),
    );

    save(fromList, toList, from, to, listId, cardId);
  }

  return (
    <BoardContext.Provider value={{ lists, move, setRefresh, setores, coordenadores, etapas, caracteristicas }}>
      <Container>
        {lists &&
          lists.length > 0 &&
          lists.map((list, index) => <List key={list.id} listId={list.id} index={index} data={list} />)}
      </Container>
    </BoardContext.Provider>
  );
};

export default Vagas;
