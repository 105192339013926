/* eslint-disable react/jsx-props-no-spreading */
import format from 'date-fns/format';
import Props from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import api from '../../services/api';
import { Container, LoadContainer, NoSchedule } from './styles';

const SchedulerMini = ({ etapaProcessoSeletivoId, stepToGo }) => {
  const [scheduledCandidates, setScheduledCandidates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const {
        data: { data },
      } = await api.get('curriculoetapaprocessoseletivo/byetapaprocessoseletivo', {
        headers: {
          etapaProcessoSeletivoId: parseInt(stepToGo.id),
        },
      });
      if (data) {
        setScheduledCandidates(data);
      }
      setLoading(false);
    };
    fetchData();
  }, []); // eslint-disable-line

  return (
    <Container>
      <div className="header">Candidatos já agendados para próxima etapa</div>
      <div className="events-container">
        {loading ? (
          <LoadContainer>Carregando Informação...</LoadContainer>
        ) : (
          <ul>
            <Scrollbars
              style={{ height: 'calc(100vh - 296px)', width: '100%', overflowX: 'hidden' }}
              renderTrackHorizontal={rest => <div {...rest} style={{ display: 'none' }} className="track-horizontal" />}
            >
              {scheduledCandidates.length > 0 ? (
                scheduledCandidates.map(item => (
                  <li key={item.id}>
                    <span className="applicant-name">{item.curriculo.usuario.nome}</span>
                    <span className="scheduler-date">{format(new Date(item.horarioAgendamento), 'dd/MM/yyyy hh:mm')}</span>
                  </li>
                ))
              ) : (
                <NoSchedule>Não há agendamentos</NoSchedule>
              )}
            </Scrollbars>
          </ul>
        )}
      </div>
    </Container>
  );
};

SchedulerMini.propTypes = {
  etapaProcessoSeletivoId: Props.number.isRequired,
  stepToGo: Props.instanceOf(Object).isRequired,
};

export default SchedulerMini;
