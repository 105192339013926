import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 20px;
  bottom: 0;
  width: 300px;
  background: #eeefff;
  color: #333;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 999;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

export const Header = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  padding: 5px;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: red;
  color: #fff;
  cursor: pointer;

  button {
    border: none;
    background: none;

    svg {
      cursor: pointer;
      background: none;
      color: #fff0c7;
      border-radius: 50%;
    }
  }
`;

const calculateHeight = (qte, status) => {
  if (status) {
    return `${36 * qte + 2}px`;
  }
  return '0';
};

export const ListContainer = styled.div`
  transition: height 1s cubic-bezier(0, 1.44, 0.63, 1.42);
  height: ${props => calculateHeight(props.qte, props.open)};
  max-height: 400px;
`;

export const List = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #eeefff;
  padding: 5px 10px;
  margin: 0 0 2px 0;
  font-size: 15px;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    color: #411564;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;
