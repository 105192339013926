import React, { memo, useCallback } from 'react';
import { MdPrint } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form } from '@rocketseat/unform';
import { useSnackbar } from 'notistack';
import Props from 'prop-types';

import { store } from '~/store';
import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import Button from '../../../components/Button';
import DatePickerMaterial from '../../../components/DatePickerMaterial';
import ReactInput from '../../../components/ReactInput';
import SchedulerMini from '../../../components/SchedulerMini';
import * as routes from '../../../configs/Routes';
import { formatPhoneNumber, getLocalDate } from '../../../functions/curriculos';
import api from '../../../services/api';
import {
  DashContainer,
  ButtonContainer,
  FieldContainer,
  SchedulerContainer,
  UserDataContainer,
  RejectButtonContainer,
} from './styles';

function ContatoConfirmacao({ curriculoData, stepToGo, processoSeletivoId, refresh }) {
  const {
    etapaProcessoSeletivoId,
    curriculo: { usuario, telefone1, telefone2 },
  } = curriculoData;
  const { profile: userData } = store.getState().user;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async data => {
      dispatch(toggleLoading(true));
      const curriculoEtapaProcessoSeletivo = {
        id: curriculoData.id,
        etapaProcessoSeletivoId: stepToGo.id,
        dataAtualizacao: getLocalDate(),
        usuarioAtualizacao: userData.id,
        horarioAgendamento: data.horarioAgendamento,
      };

      const { data: retorno } = await api.put('curriculoetapaprocessoseletivo', curriculoEtapaProcessoSeletivo);

      const observacaoCurriculoEtapaProcessoSeletivo = {
        curriculoId: curriculoData.id,
        etapaProcessoSeletivoId: curriculoData.etapaProcessoSeletivoId,
        processoSeletivoId,
        observacao: data.observacao,
        dataCadastro: getLocalDate(),
        usuarioCadastro: userData.id,
        horarioAgendamento: data.horarioAgendamento,
      };

      const { data: retornoObservacao } = await api.post(
        'observacaocurriculoetapaprocessoseletivo',
        observacaoCurriculoEtapaProcessoSeletivo,
      );

      if (retorno.status && retornoObservacao.status) {
        refresh(curriculo => {
          const curriculoEditado = curriculo.filter(c => c.id === curriculoData.id);
          curriculoEditado[0].etapaProcessoSeletivoId = stepToGo.id;
          return [...curriculo.filter(c => c.id !== curriculoData.id), ...curriculoEditado];
        });

        enqueueSnackbar(retorno.message, { variant: 'success' });
        dispatch(toggleModal(false, null, null, null));
      } else {
        enqueueSnackbar(retorno.message ? retorno.message : retornoObservacao.message, { variant: 'error' });
      }
      dispatch(toggleLoading(false));
    },
    [
      curriculoData.etapaProcessoSeletivoId,
      curriculoData.id,
      dispatch,
      enqueueSnackbar,
      processoSeletivoId,
      refresh,
      stepToGo,
      userData.id,
    ],
  );

  const handleRemoveCandidate = useCallback(async () => {
    dispatch(toggleLoading(true));
    const curriculoEtapaProcessoSeletivo = {
      id: curriculoData.id,
      dataAtualizacao: getLocalDate(),
      usuarioAtualizacao: userData.id,
      excluirDoProcesso: 1,
    };

    const { data: retorno } = await api.put('curriculoetapaprocessoseletivo', curriculoEtapaProcessoSeletivo);

    const observacaoCurriculoEtapaProcessoSeletivo = {
      curriculoId: curriculoData.id,
      etapaProcessoSeletivoId: curriculoData.etapaProcessoSeletivoId,
      processoSeletivoId,
      observacao: 'Não foi possível fazer contato',
      dataCadastro: getLocalDate(),
      usuarioCadastro: userData.id,
    };

    const { data: retornoObservacao } = await api.post(
      'observacaocurriculoetapaprocessoseletivo',
      observacaoCurriculoEtapaProcessoSeletivo,
    );

    if (retorno.status && retornoObservacao.status) {
      refresh(curriculo => [...curriculo.filter(c => c.id !== curriculoData.id)]);
      enqueueSnackbar(retorno.message, { variant: 'success' });
      dispatch(toggleModal(false, null, null, null));
    } else {
      enqueueSnackbar(retorno.message ? retorno.message : retornoObservacao.message, { variant: 'error' });
    }
    dispatch(toggleLoading(false));
  }, [
    curriculoData.etapaProcessoSeletivoId,
    curriculoData.id,
    dispatch,
    enqueueSnackbar,
    processoSeletivoId,
    refresh,
    userData.id,
  ]);

  return (
    <DashContainer>
      <Form onSubmit={handleSubmit} initialData={curriculoData}>
        <Link
          className="action-buttons"
          to={{
            pathname: `${routes.ADMIN_RESUME_VIEWER}/?id=${curriculoData.curriculo.usuarioId}`,
            state: { userId: curriculoData.curriculo.usuarioId },
          }}
          target="_blank"
        >
          <MdPrint size={24} className="print-button" />
        </Link>
        <h3>Contato e Agendamento</h3>
        <UserDataContainer>
          <h4>{usuario.nome}</h4>
          <span className="user-contact">Telefone 1: {formatPhoneNumber(telefone1)}</span>
          <span className="user-contact">Telefone 2: {formatPhoneNumber(telefone2)}</span>
          <span className="user-contact">Email: {usuario.email}</span>
        </UserDataContainer>
        <FieldContainer>
          <ReactInput name="id" style={{ display: 'none' }} />
          <ReactInput name="curriculoId" style={{ display: 'none' }} />
          <ReactInput name="etapaProcessoSeletivoId" style={{ display: 'none' }} />
          <ReactInput name="processoSeletivoId" style={{ display: 'none' }} />
          <label htmlFor="horarioAgendamento">Agendar próxima estapa para:</label>
          <DatePickerMaterial
            id="horarioAgendamento"
            name="horarioAgendamento"
            dateFormat="dd/MM/yyyy HH:mm"
            type="keyboardDateTime"
          />
          <ReactInput name="observacao" label="Observação:" placeholder="Observação" rows="5" multiline />
        </FieldContainer>
        <ButtonContainer>
          <Button text="Confirmar Presença" color="#fff" backColor="#00995d" handleClick={() => {}} />
        </ButtonContainer>
      </Form>
      <SchedulerContainer>
        <SchedulerMini etapaProcessoSeletivoId={etapaProcessoSeletivoId} stepToGo={stepToGo} />
      </SchedulerContainer>
      <RejectButtonContainer>
        <Button text="Não foi possível contactar" color="#fff" backColor="#ff0000" handleClick={handleRemoveCandidate} />
      </RejectButtonContainer>
    </DashContainer>
  );
}

ContatoConfirmacao.propTypes = {
  curriculoData: Props.instanceOf(Object).isRequired,
  stepToGo: Props.instanceOf(Object).isRequired,
  processoSeletivoId: Props.number.isRequired,
  refresh: Props.func.isRequired,
};

export default memo(ContatoConfirmacao);
