import React from 'react';

import PropTypes from 'prop-types';

const LoginHistory = ({ formUserData }) => (
  <div>
    <h1>Histórico de Acesso</h1>
    <table>
      <thead>
        <tr>
          <th>Login</th>
          <th>IP</th>
          <th>Local</th>
          <th>Dispositivo</th>
        </tr>
      </thead>
      <tbody>
        {formUserData.loginHistory.map(item => (
          <tr key={item.id}>
            <td>{item.horario}</td>
            <td>{item.ip}</td>
            <td>Indefinido</td>
            <td>{item.dispositivo}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

LoginHistory.propTypes = {
  formUserData: PropTypes.instanceOf(Object).isRequired,
};

export default LoginHistory;
