import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 6px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  margin-left: 1px;

  div {
    max-height: 100%;
  }
`;
