import React, { useState } from 'react';

import { Form } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import { ContainerForm, Header, InputResume, CustomDiv, ErroDescription } from './styles';

function FormErros({ data: dados }) {
  const [data] = useState(dados.dataLoad);
  // const handleSubmit = async formData => {
  // 	toggleLoading();
  // 	let save = {};
  // 	if (!formData.id) {
  // 		save = await api.post('errosreportados', { ...formData, dataCadastro: new Date() });
  // 	} else {
  // 		save = await api.put('errosreportados', {
  // 			...formData,
  // 			dataAlteracao: new Date(),
  // 		});
  // 	}

  // 	const { status, message } = save.data;
  // 	if (status) {
  // 		toggleSnack(true, message, 'success-save', 'success');
  // 		setRefresh(refresh => !refresh);
  // 		close(false, null, null, null);
  // 	} else {
  // 		toggleSnack(true, message, 'error-save', 'error');
  // 	}
  // 	toggleLoading();
  // };

  return (
    <ContainerForm>
      <Header>
        <h1>Erros Reportados</h1>
        <span>Erros que os usuários reportaram</span>
      </Header>
      <ErroDescription>
        <h5>{data?.usuario?.nome}</h5>
        <h6>{data.titulo}</h6>
        <p>
          {'"'}
          {data.descricao}
          {'"'}
        </p>
        <span>{data?.usuario?.email}</span>
      </ErroDescription>
      <Form onSubmit={() => {}} initialData={data}>
        <InputResume id="id" name="id" style={{ display: 'none' }} />
        <CustomDiv>
          <label htmlFor="reposta">Reposta</label>
          <InputResume name="resposta" id="resposta" maxLength={250} />
        </CustomDiv>
        <Button handleClick={() => {}} text="Salvar" backColor="#5b5c65" color="#FFF" style={{ marginTop: '10px', right: '0' }} />
      </Form>
    </ContainerForm>
  );
}

FormErros.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default FormErros;
