/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef, memo } from 'react';
import { MdDelete, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import PropTypes from 'prop-types';

import RatingSlide from '../../../components/RatingSlide';
import { niveis } from '../../../configs/helpers/skilllevel';
import { alertaExclusao } from '../../../functions/curriculos';
import { InputResume } from '../styles';
import {
  Container,
  Header,
  SubHeaderContainer,
  DeleteContainer,
  BodyForm,
  ToolsContainer,
  DescriptionContainer,
  PeriodoContainer,
} from './styles';

const HistoricoHabilidades = ({ itemIndex, remove, data, setHistorico, dadosItem, refresh, handleSubmit, cid }) => {
  const [nome, setNome] = useState('');
  const [nivel, setNivel] = useState({ value: 1, label: 'Novato', cor: '255, 89, 89' });
  const [showBody, setShowBody] = useState(false);

  const nvl = niveis.filter(n => n.value === (dadosItem.nivel || 1)).shift();

  useEffect(() => {
    setNivel(nvl);
    setNome(dadosItem.nome);
    if (!dadosItem.nome) {
      setShowBody(true);
    }
  }, []);

  const innerRef = useRef(null);
  return (
    <Container ref={innerRef}>
      <Header>
        <SubHeaderContainer onClick={() => setShowBody(!showBody)}>
          <DescriptionContainer className="hover-title">{nome}</DescriptionContainer>
          <PeriodoContainer>{nivel.label}</PeriodoContainer>
        </SubHeaderContainer>
        <ToolsContainer className="tools-container">
          {showBody ? (
            <MdKeyboardArrowUp size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          ) : (
            <MdKeyboardArrowDown size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          )}
        </ToolsContainer>
        <DeleteContainer className="tools-container">
          <MdDelete
            size={24}
            className="tools-button"
            onClick={() =>
              alertaExclusao(`Confirma a exclusão da habilidade "${nome}"?`, () =>
                remove(data, setHistorico, itemIndex, 'historicoHabilidades', dadosItem.id, refresh, cid),
              )
            }
          />
        </DeleteContainer>
      </Header>
      <BodyForm show={showBody}>
        <InputResume id="id" name="id" type="hidden" />
        <InputResume id="cid" name="cid" type="hidden" />
        <InputResume id="sequencia" name="sequencia" type="hidden" />
        <div className="row-fields">
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="nome">Habilidade *</label>
              <InputResume id="nome" name="nome" onChange={e => setNome(e.target.value)} maxLength={100} />
            </div>
          </div>
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="nivel">Nível - {nivel.label}</label>
              <RatingSlide name="nivel" onChange={setNivel} nivel={nivel} data={niveis} handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </BodyForm>
    </Container>
  );
};

HistoricoHabilidades.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  setHistorico: PropTypes.func.isRequired,
  dadosItem: PropTypes.instanceOf(Object),
  cid: PropTypes.string,
};

HistoricoHabilidades.defaultProps = {
  dadosItem: null,
  cid: null,
};

export default memo(HistoricoHabilidades);
