import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';

import { format } from 'date-fns';

import { toggleLoading, setCurrentPage } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import SearchBox from '../../components/SearchBox';
import api from '../../services/api';
import FormSetores from './form';
import { Container, FilterContainer } from './styles';

function Setores() {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = parseInt(global.window.innerHeight - 208) / 75;
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [setores, setSetores] = useState([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const iconStyles = {
    marginLeft: 7,
    cursor: 'pointer',
  };

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    dispatch(setCurrentPage('Setores'));
  }, []); // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      dispatch(toggleLoading(true));
      const { data } = await api.get(`setores/${itemsPerPage}/${parseInt((activePage - 1) * itemsPerPage)}/${searchTerm}`);
      const { data: dados, total: qte } = data;
      if (data) {
        setTotal(qte);
        setSetores(dados);
      }
      dispatch(toggleLoading(false));
    };
    fetchData();
  }, [activePage, refresh, searchTerm]); // eslint-disable-line

  const deleteItem = async item => {
    const processo = {
      id: item,
      dataExclusao: new Date(),
      usuarioExclusao: null,
    };

    const response = await api.delete('setores', { data: processo });
    if (response.data.status) {
      setRefresh(ref => !ref);
    }
  };

  const showForm = dataLoad => {
    dispatch(toggleModal(true, <FormSetores close={toggleModal} data={{ dataLoad }} setRefresh={setRefresh} />, 'setores', ''));
  };

  const search = value => {
    if (value.trim() !== '') {
      setSetores([]);
      setActivePage(1);
      setSearchTerm(value.toLowerCase());
    } else {
      setSearchTerm('');
    }
  };

  return (
    <Container>
      <FilterContainer>
        <SearchBox onSearch={search} />
        <button type="button" onClick={() => showForm({})}>
          <MdAdd size={24} color="#fff" />
        </button>
      </FilterContainer>
      <div className="table-responsive">
        <table className="table-outline mb-0 d-sm-table table table-hover">
          <thead className="thead-light">
            <tr>
              <th width={isMobile ? '90%' : '33%'}>Nome</th>
              <th className="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            {setores &&
              setores.map(item => (
                <tr key={item.id}>
                  <td>
                    <div>{item.nome}</div>
                    <div className="small text-muted">
                      <small>Id: {item.id} |</small>
                      <small> Data Cadastro: {format(new Date(item.dataCadastro), 'dd/MM/yyyy')}</small>
                    </div>
                  </td>
                  <td>
                    <div className="btn-group" style={{ float: 'right' }}>
                      <MdEdit size={24} color="#5b5c65" style={iconStyles} onClick={() => showForm(item)} />
                      <MdDelete
                        size={24}
                        color="#e53935"
                        style={iconStyles}
                        onClick={() =>
                          global.window.confirm(`Tem certeza que deseja excluir este item: ( ${item.nome} )?`) &&
                          deleteItem(item.id)
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={total}
          pageRangeDisplayed={isMobile ? 3 : 10}
          onChange={handlePageChange}
          prevPageText="Anterior"
          nextPageText="Próxima"
          firstPageText="Primeira"
          lastPageText="Última"
        />
      </div>
    </Container>
  );
}

export default Setores;
