import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { MdBugReport } from 'react-icons/md';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';

import { format } from 'date-fns';

import { toggleLoading, setCurrentPage } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import SearchBox from '../../components/SearchBox';
import api from '../../services/api';
import FormErrosReportados from './form';
import { Container, FilterContainer } from './styles';

function ErrosSugestao() {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = parseInt(global.window.innerHeight - 208) / 75;
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [erros, setErros] = useState([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const iconStyles = {
    marginLeft: 7,
    cursor: 'pointer',
  };

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    dispatch(setCurrentPage('Erros / Sugestão'));
  }, []); // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      dispatch(toggleLoading(true));
      const { data } = await api.get(
        `errosreportados/${itemsPerPage}/${parseInt((activePage - 1) * itemsPerPage)}/${searchTerm}`,
      );
      const { data: dados, total: qte } = data;
      if (data) {
        setTotal(qte);
        setErros(dados);
      }
      dispatch(toggleLoading(false));
    };
    fetchData();
  }, [activePage, refresh, searchTerm]); // eslint-disable-line

  const search = value => {
    if (value.trim() !== '') {
      setErros([]);
      setActivePage(1);
      setSearchTerm(value.toLowerCase());
    } else {
      setSearchTerm('');
    }
  };

  const showForm = dataLoad => {
    dispatch(
      toggleModal(true, <FormErrosReportados close={toggleModal} data={{ dataLoad }} setRefresh={setRefresh} />, 'erros', ''),
    );
  };

  return (
    <Container>
      <FilterContainer>
        <SearchBox onSearch={search} />
      </FilterContainer>
      <div className="table-responsive">
        <table className="table-outline mb-0 d-sm-table table table-hover">
          <thead className="thead-light">
            <tr>
              <th width="86%">Nome</th>
              {!isMobile && <th width="5%">Resolvido</th>}
              <th className="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            {erros &&
              erros.map(item => (
                <tr key={item.id}>
                  <td>
                    <div>
                      {item?.usuario?.nome} - {item.titulo}
                    </div>
                    <div className="small text-muted">
                      <small>Id: {item.id} |</small>
                      <small> Data Cadastro: {format(new Date(item.dataCadastro), 'dd/MM/yyyy')}</small>
                      {!isMobile && <small> Descrição: {item.descricao.slice(0, 100)}</small>}
                    </div>
                  </td>
                  {!isMobile && <td>{item.erroResolvido}</td>}
                  <td>
                    <div className="btn-group" style={{ float: 'right' }}>
                      <MdBugReport size={24} color="#5b5c65" style={iconStyles} onClick={() => showForm(item)} />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={total}
          pageRangeDisplayed={isMobile ? 3 : 10}
          onChange={handlePageChange}
          prevPageText="Anterior"
          nextPageText="Próxima"
          firstPageText="Primeira"
          lastPageText="Última"
        />
      </div>
    </Container>
  );
}
export default ErrosSugestao;
