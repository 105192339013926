import React, { useRef, useContext, memo, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import PropTypes from 'prop-types';

import { alertaExclusao } from '../../functions/curriculos';
import BoardContext from '../../pages/Vagas/context';
import api from '../../services/api';
import { Container, Label, SetorContainer, EditButton, DeleteButton } from './styles';

const BoardCard = ({ data, index, listIndex, edit, listId, cardId, setRefresh }) => {
  const ref = useRef();
  const { move } = useContext(BoardContext);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CARD', index, listIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {
      const draggedListIndex = item.listIndex;
      const targetListIndex = listIndex;

      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex && draggedListIndex === targetListIndex) return;

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;

      if (draggedIndex < targetIndex && draggedTop < targetCenter) return;
      if (draggedIndex > targetIndex && draggedTop > targetCenter) return;

      move(draggedListIndex, targetListIndex, draggedIndex, targetIndex, listId, cardId);

      item.index = targetIndex;
      item.listIndex = targetListIndex;
    },
  });

  dragRef(dropRef(ref));

  const deleteItem = useCallback(
    async item => {
      const processo = {
        id: item,
        dataExclusao: new Date(),
        usuarioExclusao: null,
      };

      const response = await api.delete('processoseletivo', { data: processo });
      if (response.data.status) {
        setRefresh(refresh => !refresh);
      }
    },
    [setRefresh],
  );

  return (
    <Container ref={ref} isDragging={isDragging} color={data.divulgarVaga ? '#00995d' : '#e53935'}>
      <header>
        <Label key={1}>{data.divulgarVaga ? 'Vaga Divulgada' : 'Vaga Não Divulgada'}</Label>
        {/* Verificando se faz parte da primeira lista */}
        {listIndex === 0 && (
          <div>
            <EditButton
              size={24}
              color="#fff"
              onClick={() => {
                edit(data);
              }}
            />
            <DeleteButton
              size={24}
              color="#fff"
              onClick={() => alertaExclusao(`Confirma a exclusão da vaga ${data.nome}?`, () => deleteItem(data.id))}
            />
          </div>
        )}
      </header>
      <p>{data.nome}</p>
      <SetorContainer>{data.setor && data.setor.nome}</SetorContainer>

      {/* {data.user && <img src={data.user} alt="Avatar" />} */}
    </Container>
  );
};

BoardCard.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  listIndex: PropTypes.number.isRequired,
  edit: PropTypes.func.isRequired,
  listId: PropTypes.number.isRequired,
  cardId: PropTypes.number.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default memo(BoardCard);
