import About from './About';
import DeleteData from './DeleteData';
import ErrorReport from './ErrorReport';
import LoginHistory from './LoginHistory';
import PasswordConfiguration from './PasswordConfiguration';
import UserData from './UserData';

export const SelectedView = {
  About,
  DeleteData,
  ErrorReport,
  LoginHistory,
  PasswordConfiguration,
  UserData,
};
