import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #333;
  margin-top: -20px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const SubContainer = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 1024px;

  @media (max-width: 768px) {
    padding: 20px;

    div:first-child {
      flex-direction: column;
    }
  }
`;

export const LeftSide = styled.div`
  position: sticky;
  top: 0px;
  width: 300px;
  height: 99vh;
  padding-right: 50px;
  border-right: 2px solid rgb(25, 24, 31);

  @media (max-width: 960px) {
    position: relative;
    height: auto;
    width: 100%;
    margin-bottom: 35px;
    padding-right: 0px;
    padding-bottom: 20px;
    border-right: 0px;
    border-bottom: 2px solid rgb(25, 24, 31);
  }
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

  span {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
  }

  .has-userphoto img {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  width: 114px;
  height: 114px;
  margin-bottom: 15px;
  position: relative;
  border: 5px solid #411564;
  border-image: initial;
  border-radius: 50%;
  cursor: pointer !important;
  background-size: cover;

  &:hover {
    border: 5px solid rgba(10, 10, 10, 0.8);
  }

  input[type='file'] {
    display: block;
    font-size: 9px;
    width: 134px;
    height: 134px;
    opacity: 0;
    position: absolute;
    text-align: right;
    z-index: 555;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    border-radius: 50%;
    cursor: pointer !important;
  }

  svg {
    color: #fff;
    cursor: pointer !important;
  }
`;

export const FieldInput = styled.div`
  cursor: pointer !important;
  right: -11px;
  top: 4px;
  font-size: 14px;
  position: absolute;
  width: 35px;
  height: 35px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  background: rgb(65, 21, 100);
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  @media (max-width: 960px) {
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const CustomButton = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 0px 25px;
  border-radius: 5px;
  background: ${props => (props.ativo ? 'rgb(11, 10, 13)' : 'rgb(25, 24, 31)')};
  border-left: 2px solid ${props => (props.ativo ? props.color : 'rgb(25,24,31)')};
  transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${props => (props.ativo ? 'animation: Shake 0.5s forwards linear' : '')};

  @keyframes Shake {
    0% {
      opacity: 0.8;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    @media (max-width: 540px) {
      display: none;
    }

    @media (max-width: 960px) {
      font-size: 0px;
    }
  }

  @media (max-width: 540px) {
    padding: 15px 0px;
    flex: 1 1 0%;
  }

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    border-bottom: 2px solid ${props => (props.ativo ? '#b1d34b' : '#000')};
    background: ${props => (props.ativo ? 'rgb(11, 10, 13)' : 'rgb(25, 24, 31)')};
    border-left: none;
    margin-left: 5px;
  }
`;

export const RightSide = styled.div`
  padding-left: 50px;

  @media (max-width: 960px) {
    padding: 0px;

    &:first-child {
      max-width: 590px;
      margin: 0px auto;
    }
  }

  div {
    transition: all 0.8s;
  }

  h1 {
    color: #333;
    font-size: 30px;
    margin-bottom: 53px;

    @media (max-width: 540px) {
      font-size: 20px;
      margin-bottom: 25px;
    }

    @media (max-width: 960px) {
      font-size: 26px;
      margin-bottom: 40px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 10px;
      color: rgb(135, 134, 139);
    }

    input {
      margin-bottom: 10px;
      background: rgb(25, 24, 31);
      border-width: 2px;
      border-style: solid;
      border-color: rgb(37, 36, 44);
      border-image: initial;
      color: rgb(255, 255, 255);
      font-size: 14px;
      height: 50px;
      width: 100%;
      outline: 0px;
      border-radius: 5px;
      padding: 15px 21px;

      &:focus {
        border: 2px solid #b1d34b;
      }

      &:disabled {
        cursor: not-allowed;
        color: rgb(68, 67, 75);
        font-size: 14px;
        font-weight: 500;
        background: rgb(25, 25, 25);
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    textarea {
      margin-bottom: 10px;
      background: rgb(25, 24, 31);
      border-width: 2px;
      border-style: solid;
      border-color: rgb(37, 36, 44);
      border-image: initial;
      color: rgb(255, 255, 255);
      font-size: 14px;
      height: auto;
      width: 100%;
      outline: 0px;
      border-radius: 5px;
      padding: 15px 21px;

      &:focus {
        border: 2px solid #b1d34b;
      }

      &:disabled {
        cursor: not-allowed;
        color: rgb(68, 67, 75);
        font-size: 14px;
        font-weight: 500;
        background: rgb(25, 25, 25);
      }
    }
  }

  table {
    width: 614px;
    border-collapse: separate;
    border-spacing: 0px 10px;

    @media (max-width: 768px) {
      width: 100%;
      border-spacing: 0px;
    }

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;

      tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;

        th {
          color: rgb(68, 67, 75);
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          padding-left: 21px;
          padding-bottom: 10px;
        }
      }
    }

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

      tr {
        height: 50px;
        background: rgb(25, 24, 31);
        border-radius: 5px;

        td:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        td:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        td {
          color: rgb(135, 134, 139);
          font-size: 13px;
          margin-bottom: 10px;
          padding: 10px 15px;
        }
      }
    }
  }
`;

export const RowContainer = styled.div`
  display: flex;

  div:first-child {
    margin-right: 20px;
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

export const FieldContainer = styled.div`
  width: ${props => `${props.width}px`};
  position: relative;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  width: 570px;
  text-align: justify;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const CropperContainer = styled.div`
  position: sticky;
  width: 300px;
  height: 300px;
  top: 10%;
  left: 40%;
  background: #fff;
  z-index: 0 !important;
  margin: 15px 0;
`;

export const DeleteButton = styled.button`
  background: rgba(0, 0, 0, 0.8);
  color: #ed1651;
  left: 0;
  font-weight: 700;
  border: none;
  position: absolute;
  bottom: -10px;
  padding: 2px 0;
  width: 100%;
  border-radius: 55px;
  z-index: 999;

  &:hover {
    opacity: 0.9;
  }
`;
