import { all } from 'redux-saga/effects';

import alert from './alert/sagas';
import auth from './auth/sagas';
import main from './main/sagas';
import modal from './modal/sagas';
import snack from './snack/sagas';
import user from './user/sagas';

export default function* rootSaga() {
  return yield all([alert, auth, main, modal, snack, user]);
}
