export function setCurrentPage(paginaAtual) {
  return {
    type: '@main/SET_CURRENT_PAGE',
    payload: { paginaAtual },
  };
}

export function setPageDescription(pageDescription) {
  return {
    type: '@main/SET_PAGE_DESCRIPTION',
    payload: { pageDescription },
  };
}

export function toggleSideDrawer(sideDrawerOpen) {
  return {
    type: '@main/TOGGLE_SIDE_DRAWER',
    payload: { sideDrawerOpen },
  };
}

export function toggleLoading(loading) {
  return {
    type: '@main/TOGGLE_LOADING',
    payload: { loading },
  };
}

export function toggleAlert(alert) {
  return {
    type: '@main/TOGGLE_ALERT',
    payload: { alert },
  };
}

export function setServer(server) {
  return {
    type: '@main/SET_SERVER',
    payload: { server },
  };
}

export function setRequiredFieldsNotification(requiredFieldsNotification) {
  return {
    type: '@main/SET_REQUIRED_FIELDS_NOTIFICATIONS',
    payload: { requiredFieldsNotification },
  };
}

export function setUserPhotoNotification(userPhotoNotification) {
  return {
    type: '@main/SET_USER_PHOTO_NOTIFICATIONS',
    payload: { userPhotoNotification },
  };
}

export function setAutoSaveNotification(autoSaveNotification) {
  return {
    type: '@main/SET_AUTO_SAVE_NOTIFICATIONS',
    payload: { autoSaveNotification },
  };
}

export function setDownloadNotification(downloadNotification) {
  return {
    type: '@main/SET_DOWNLOAD_NOTIFICATION',
    payload: { downloadNotification },
  };
}
