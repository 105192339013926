/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, memo } from 'react';

import { Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Container } from './styles';

const ReactCheckbox = ({ name, label, id }) => {
  const [value, setValue] = useState(true);
  return (
    <Container onClick={() => setValue(!value)}>
      <Input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={value}
        // onChange={() => {}}
        className="checkbox-input-unimed"
      />
      <label htmlFor={label} className="label-container">
        {label}
      </label>
    </Container>
  );
};

ReactCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default memo(ReactCheckbox);
