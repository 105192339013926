import styled from 'styled-components';
import { Input, Select } from '@rocketseat/unform';

export const Container = styled.div`
	padding: 0 30px;
	small {
		font-size: 11.5px;
	}

	@media (max-width: 768px) {
		padding: 20px 10px;
	}
`;

export const FilterContainer = styled.div`
	display: flex;
	margin-bottom: 15px;
	height: 35px;

	button {
		width: 42px;
		height: 42px;
		border-radius: 50px;
		background: #0a5f55;
		border: 0;
		cursor: pointer;
		margin-left: 15px;

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const ContainerForm = styled.div`
	background: #fff;
	display: flex;
	flex-direction: column;
	height: 100%;
	form {
		padding: 20px;
	}
`;

export const Header = styled.div`
	background: #411564;
	padding: 20px;
	margin-bottom: 20px;
	color: #b1d34b;

	span {
		color: #fff;
	}
`;

export const CustomDiv = styled.div`
	margin: 0;

	& + div {
		margin-bottom: 15px;
	}
`;

export const InputResume = styled(Input)`
	width: 100%;
	display: block;
	caret-color: rgb(33, 150, 243);
	background-color: #fff;
	color: rgb(38, 43, 51);
	pointer-events: unset;
	border-radius: 3px;
	padding: 11px 16px;
	outline: none;
	border-width: 0px;
	border: 1px solid #aaa;
	transition: color 0.1s ease 0s;
	margin-bottom: 15px;

	&:focus {
		border: 2px dashed #f47920;
	}
`;

export const SelectResume = styled(Select)`
	width: 100%;
	display: block;
	caret-color: rgb(33, 150, 243);
	background-color: rgb(242, 245, 250);
	color: rgb(38, 43, 51);
	pointer-events: unset;
	border-radius: 3px;
	padding: 11px 16px;
	outline: none;
	border-width: 0px;
	border-style: initial;
	border-color: initial;
	border-image: initial;
	transition: color 0.1s ease 0s;
`;
