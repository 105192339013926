import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: 80px;
  z-index: 9999;
  background: rgba(10, 95, 85, 1);
  padding: 0px 30px;
  position: fixed;
  width: 100%;
  top: 0;
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out 0s;

  @media (max-width: 768px) {
    height: 60px;
    padding: 0 20px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1366px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: rgb(255, 255, 255);
  padding: 12px 0px;

  @media (max-width: 768px) {
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
  }
`;

export const Dashboard = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 18px;
  color: #fff;

  h2 {
    margin-top: 10px;
  }

  .description {
    display: flex;
    flex-direction: column;
    h3 {
      margin-left: 15px;
      color: #b1d34b;
      @media (max-width: 768px) {
        display: none;
      }
    }
    small {
      margin-top: -10px;
      margin-left: 15px;
      color: rgba(255, 203, 8, 0.8);
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const UserButton = styled.div`
  display: block;
  background: rgba(10, 95, 85, 0.01);
  font-family: Roboto, sans-serif !important;

  a {
    font-family: Roboto, sans-serif !important;
    display: flex;
    text-align: right;
    margin-top: -12px;
    padding: 12px 10px;
    text-decoration: none;
    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 18px;
    align-self: center;
    span {
      color: rgb(220, 220, 220);
      font-size: 14px;
      font-weight: 200;
    }
    span:first-child {
      color: #fff;
      font-weight: 500;
      margin-bottom: 6px;
      font-size: 16px;
      text-transform: uppercase;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  img {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    border: 3px solid #411564;
    border-radius: 50%;
    border-image: initial;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
      border-width: 2px;
      margin-top: 10px;
    }
  }
`;
