import { auth } from './firebase';

// Sign Up
export const doCreateUserWithWmailAndPassword = (email, password) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then(okReturn => okReturn)
    .catch(err => err);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);

// Send Email Verification
export const doSendEmailVerification = email =>
  auth.currentUser.sendEmailVerification({
    url: `unijob-developer.firebaseapp.com/?email=${email}`, // process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT ||
    handleCodeInApp: false,
  });

// Sign Out

export const doSignOut = () => {
  auth.signOut();
};

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = password => auth.currentUser.updatePassword(password);

// Email Change
export const doEmailUpdate = email => auth.currentUser.updateEmail(email);

// Verifying if email exists
export const doVerifyEmailExists = email => auth.fetchSignInMethodsForEmail(email);
