/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, memo,useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { MdRemoveRedEye, MdHistory } from 'react-icons/md';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Form } from '@rocketseat/unform';
import { differenceInYears, format } from 'date-fns';
import { format as formatCPF } from 'gerador-validador-cpf';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { Button, ReactSelect, SearchBox, UserPhoto } from '~/components/';
import api from '~/services/api';
import { store } from '~/store';
import { setCurrentPage, toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import FormObservacoesCandidato from './form';
import Resume from './Resume';
import { Container, ModalContainer, ListContainer, FilterContainer, SearchContainer, ButtonShowFilter } from './styles';

const BancoCurriculos = ({ location }) => {
  const [activePage, setActivePage] = useState(1);
  const [cidades, setCidades] = useState([]);
  const [curriculos, setCurriculos] = useState([]);
  const [escolaridades, setEscolaridades] = useState([]);
  const [initialData, setInitialData] = useState({});
  const itemsPerPage = parseInt((global.window.innerHeight - 160) / 71);
  const [regimesContratacoes, setRegimesContratacoes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [setores, setSetores] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [total, setTotal] = useState(2);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isMountedRef = useRef(true);

  const { profile: userData } = store.getState().user;

  useEffect(() => {
    return () => {
      isMountedRef.current = false; // Define como false quando o componente é desmontado
    };
  }, []);

  const handlePageChange = useCallback(pageNumber => {
    setActivePage(pageNumber);
  }, []);

  const mountOptionList = useCallback(async (entity, setFunction) => {
    const { data } = await api.get(`${entity}`);
    if (data && data.length > 0) {
      data.forEach(item => setFunction(entities => [...entities, { id: item.id, title: item.nome ?? item.descricao }]));
    }
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage('Banco de currículos'));

    mountOptionList('escolaridade', setEscolaridades);
    mountOptionList('regimecontratacao', setRegimesContratacoes);
    mountOptionList('setores', setSetores);
    mountOptionList('sexo', setSexos);
    mountOptionList('cities', setCidades);
  }, [dispatch, mountOptionList]);

  const orderItems = [
    { id: 'nome-asc', title: 'Nome' },
    { id: 'nome-desc', title: 'Nome descendente' },
    { id: 'alteracao-asc', title: 'Data Atualização' },
    { id: 'alteracao-desc', title: 'Data Atualização descendente' },
  ];

  const necessidades = [
    { id: true, title: 'Sim' },
    { id: false, title: 'Não' },
    { id: undefined, title: 'Não Considerar' },
  ];

  useEffect(() => {
    if (!location.state) return;
    const {
      state: { initialData: dadosIniciais, activePage: paginaAtiva },
    } = location;

    setInitialData(dadosIniciais);
    setActivePage(paginaAtiva);
    setSubmited(true);
  }, [location.state, location]); // eslint-disable-line

  useEffect(() => {
    if(isMountedRef.current){ // Lógica
    setCurriculos([]);
    if (submited) return;
    dispatch(toggleLoading(true));
    const { Pesquisar, setor, sexo, regimeContratacao, cidade, escolaridade, order, necessidadesEspeciais } = initialData;
    const fetchData = async () => {
      try{ //
      const response = await api.get(`bancocurriculos/${userData?.id}`, {

        headers: {
          setor: setor !== undefined && setor !== '' ? setor : '',
          sexo: sexo !== undefined && sexo !== '' ? sexo : '',
          regimeContratacao: regimeContratacao !== undefined && regimeContratacao !== '' ? regimeContratacao : '',
          cidade: cidade !== undefined && cidade !== '' ? cidade : '',
          escolaridade: escolaridade !== undefined && escolaridade !== '' ? escolaridade : '',
          necessidadesEspeciais:
            necessidadesEspeciais !== undefined && necessidadesEspeciais !== '' ? +necessidadesEspeciais : '',
          itemsPerPage,
          offset: parseInt((activePage - 1) * itemsPerPage),
          order: order !== undefined && order !== '' ? order : '',
          texto: Pesquisar,

        },
      });

      const { data, total: qte, status, message } = response.data;
      if ((data && data.length === 0) || !status) {
        enqueueSnackbar(status ? 'Nenhum currículo encontrado com o filtro selecionado' : message, {
          variant: status ? 'warning' : 'error',
        });
        if(isMountedRef.current){
          setCurriculos([]);
          setTotal(qte);
        }

      } else {
        const modifiedData = data.map(resume => ({ ...resume, imageUrl: null }));
        if(isMountedRef.current){
        setCurriculos(modifiedData);
        setTotal(qte);
      }
    }

  } catch (error){    //
    enqueueSnackbar(error.message, { variant: 'error' });
  };
      if(isMountedRef.current){  //
      dispatch(toggleLoading(false));
    }
    };
    fetchData();
  }//
  }, [activePage]); // eslint-disable-line
/////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location.state && isMountedRef.current) {
      const { state: { initialData: dadosIniciais, activePage: paginaAtiva } } = location;
      setInitialData(dadosIniciais);
      setActivePage(paginaAtiva);
      setSubmited(true);
    }
  }, [location.state, location]); // eslint-disable-line
//////////////////////////////////////////////////////////////////////////////////////////
  const handleSubmit = useCallback(
    async dados => {
      setActivePage(1);
      const { Pesquisar, cidade, escolaridade, regimeContratacao, setor, sexo, order, necessidadesEspeciais } = dados;
      dispatch(toggleLoading(true));
      if (!Pesquisar && !cidade && !escolaridade && !regimeContratacao && !setor && !sexo && parseInt(necessidadesEspeciais)) {
        enqueueSnackbar('Selecione pelo menos um dos combobox ou preencha o nome da pessoa para pesquisar!', {
          variant: 'warning',
        });
        dispatch(toggleLoading(false));
        return;
      }

      setCurriculos([]);
      setInitialData({ Pesquisar, cidade, escolaridade, regimeContratacao, setor, sexo, order, necessidadesEspeciais });
      try {
        const response = await api.get(`bancocurriculos/${userData?.id}`, {
          headers: {
            setor: setor !== undefined && setor !== '' ? setor : '',
            sexo: sexo !== undefined && sexo !== '' ? sexo : '',
            regimeContratacao: regimeContratacao !== undefined && regimeContratacao !== '' ? regimeContratacao : '',
            cidade: cidade !== undefined && cidade !== '' ? cidade : '',
            escolaridade: escolaridade !== undefined && escolaridade !== '' ? escolaridade : '',
            necessidadesEspeciais:
              necessidadesEspeciais !== undefined && necessidadesEspeciais !== '' ? +necessidadesEspeciais : '',
            itemsPerPage,
            offset: parseInt((activePage - 1) * itemsPerPage),
            order: order !== undefined && order !== '' ? order : '',
            texto: Pesquisar,
          },
        });
        const { data, total: qte } = response.data;
        if (data.length === 0) {
          enqueueSnackbar('Nenhum currículo encontrado com o filtro selecionado', { variant: 'warning' });
          setCurriculos([]);
        } else {
          setCurriculos(data);
          setTotal(qte);
          setShowFilters(!showFilters);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      dispatch(toggleLoading(false));
    },
    [activePage, dispatch, enqueueSnackbar, itemsPerPage, showFilters, userData],// eslint-disable-line
  );

  const showForm = useCallback(
    dataLoad => {
      dispatch(toggleModal(true, <FormObservacoesCandidato close={toggleModal} data={{ dataLoad }} />, 'observacoes', ''));
    },
    [dispatch],
  );

  const handleShowFilter = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const handleShowResume = useCallback(
    usuarioId => {
      dispatch(toggleModal(true, <Resume usuarioId={usuarioId} />, 'resume', ''));
    },
    [dispatch],
  );

  return (
    <Container>
      <ButtonShowFilter onClick={() => handleShowFilter()}>{!showFilters ? <SearchIcon /> : <CloseIcon />}</ButtonShowFilter>
      <ModalContainer showFilter={showFilters}>
        <Form onSubmit={handleSubmit} initialData={initialData}>
          <SearchContainer>
            <SearchBox onSearch={() => {}} placeholder="Pesquisar por nome ou cargo..." placeholderColor="#fff" />
            <ReactSelect
              closeOnSelect
              options={orderItems}
              multiple={false}
              id="order"
              name="order"
              placeholder="Selecione uma ordem..."
              label="Ordenar Por"
              className="item"
              fieldPointer="order"
              isClearable
            />
          </SearchContainer>
          <FilterContainer>
            <div className="separator-div">
              <ReactSelect
                closeOnSelect
                options={setores}
                multiple={false}
                id="setor"
                name="setor"
                label="Setor"
                className="item"
                fieldPointer="setor"
                isClearable
              />
              <ReactSelect
                closeOnSelect
                options={sexos}
                multiple={false}
                id="sexo"
                name="sexo"
                label="Sexo"
                className="item"
                fieldPointer="sexo"
                isClearable
              />
              <ReactSelect
                closeOnSelect
                options={regimesContratacoes}
                multiple={false}
                id="regimeContratacao"
                name="regimeContratacao"
                // defaultValue={1}
                label="Regime de Contratacao"
                className="item"
                fieldPointer="regimeContratacao"
                isClearable
              />
            </div>
            <div className="separator-div">
              <ReactSelect
                closeOnSelect
                options={cidades}
                multiple={false}
                id="cidade"
                name="cidade"
                // defaultValue={1}
                label="Cidade"
                className="item"
                fieldPointer="cidade"
                isClearable
              />
              <ReactSelect
                closeOnSelect
                options={escolaridades}
                multiple={false}
                id="escolaridade"
                name="escolaridade"
                // defaultValue={1}
                label="Escolaridade"
                className="item"
                fieldPointer="escolaridade"
                isClearable
              />
              <ReactSelect
                closeOnSelect
                options={necessidades}
                multiple={false}
                id="necessidadesEspeciais"
                name="necessidadesEspeciais"
                // defaultValue={1}
                label="Pessoa Com Deficiência"
                className="item"
                fieldPointer="necessidadesEspeciais"
                isClearable
              />
            </div>
            <div className="item-button">
              <Button
                text="Cancelar"
                type="button"
                color="#FFF"
                backColor="#ed1651"
                handleClick={e => {
                  e.preventDefault();
                  handleShowFilter();
                }}
              />
              <Button text="Pesquisar" color="#411564" backColor="#ffcb08" handleClick={() => {}} />
            </div>
          </FilterContainer>
        </Form>
      </ModalContainer>
      <ListContainer>
        <div className="table-responsive">
          <table className="table-outline mb-0 d-sm-table table table-hover">
            <thead className="thead-light">
              <tr>
                <th width="36%" className="mobile-resize">
                  Nome
                </th>
                <th width="25%" className="text-left mobile-hidden">
                  Setores de Interesse
                </th>
                <th className="text-center mobile-hidden tiny-screen">T/D</th>
                <th className="text-center mobile-hidden tiny-screen">Ind.</th>
                <th className="text-center mobile-hidden tiny-screen">Proc.</th>
                <th className="text-center mobile-hidden">Atual./Cad.</th>
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {curriculos &&
                curriculos.map(item => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-data-container">
                        <div className="candidate-image">
                          <UserPhoto userId={item.usuario.id} />
                        </div>
                        <div className="user-data-region">
                          <div className="bank-username">{item.usuario.nome.toUpperCase()}</div>
                          <div className="text-muted">
                            <span>
                              <b>Id:</b> {item.id} |{' '}
                            </span>
                            <span>
                              <b>CPF:</b>{' '}
                            </span>
                            {formatCPF(item.usuario.cpf)}
                            <span>
                              {' '}
                              | <b>Idade:</b>{' '}
                            </span>
                            {differenceInYears(new Date(), new Date(item.dataNascimento))} anos
                            {/* <span className={item.estaTrabalhandoAtualmente ? ' trabalhando' : ' desempregado'}>
                          {item.estaTrabalhandoAtualmente ? ' Trabalhando' : ' Desempregado'}
                        </span> */}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-left mobile-hidden">
                      {item.setoresCurriculo &&
                        item.setoresCurriculo.map(set => (
                          <span key={set.id} className="user-sector">
                            {set.setores.nome}{' '}
                          </span>
                        ))}
                    </td>
                    <td className="mobile-hidden tiny-screen text-center">
                      <span className={item.estaTrabalhandoAtualmente ? ' trabalhando' : ' desempregado'}>
                        {item.estaTrabalhandoAtualmente ? ' T' : ' D'}
                      </span>
                    </td>
                    <td className="mobile-hidden tiny-screen">
                      {item.preAnaliseCurriculos?.filter(preAnalise =>
                        preAnalise.tipoClassificacaoCurriculo?.nome?.includes('Indicação'),
                      ).length > 0 ? (
                        item.preAnaliseCurriculos?.filter(preAnalise =>
                          preAnalise.tipoClassificacaoCurriculo?.nome?.includes('Funcionário'),
                        ).length > 0 ? (
                          <span className="indicacao-trabalhador">IF</span>
                        ) : (
                          <span className="indicacao-cooperado">IC</span>
                        )
                      ) : (
                        <span />
                      )}
                    </td>
                    <td className="text-center mobile-hidden tiny-screen">
                      <span className={item.curriculoEtapaProcessoSeletivos.length === 0 ? 'nao-participou' : 'participou'}>
                        {item.curriculoEtapaProcessoSeletivos && item.curriculoEtapaProcessoSeletivos.length}
                      </span>
                    </td>
                    <td className="text-center mobile-hidden">
                      <div>{item.dataAlteracao && format(new Date(item.dataAlteracao), 'dd/MM/yyyy')}</div>
                      <div className="small text-muted">{format(new Date(item.dataCadastro), 'dd/MM/yyyy')}</div>
                    </td>
                    <td>
                      <div className="btn-group" style={{ float: 'right' }}>
                        {/* <Tooltip title="Possui foto cadastrada">
                          <MdPhoto size={24} />
                        </Tooltip> */}
                        <Tooltip title="Pré análise curricular">
                          <MdHistory
                            size={24}
                            style={{
                              color: item.preAnaliseCurriculos.length > 0 ? '#00995d' : '#c4cbcf',
                            }}
                            onClick={() => showForm(item.id)}
                          />
                        </Tooltip>
                        <Tooltip title="Imprimir">
                          <MdRemoveRedEye size={24} color="411564" onClick={() => handleShowResume(item.usuario.id)} />
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </ListContainer>
      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={total}
          pageRangeDisplayed={isMobile ? 3 : 10}
          onChange={handlePageChange}
          prevPageText={isMobile ? '<' : 'Anterior'}
          nextPageText={isMobile ? '>' : 'Próxima'}
          firstPageText={isMobile ? '<<' : 'Primeira'}
          lastPageText={isMobile ? '>>' : 'Última'}
        />
      </div>
    </Container>
  );
};

BancoCurriculos.propTypes = {
  location: PropTypes.instanceOf(Object),
};

BancoCurriculos.defaultProps = {
  location: {},
};

export default memo(BancoCurriculos);
