import React, { useState, useCallback, memo } from 'react';
import { MdReplay, MdExpandLess, MdExpandMore, MdFolderShared } from 'react-icons/md';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import api from '../../../services/api';
import { Container, Header, List, ListContainer } from './styles';

function RemovedResumeList({ curriculos, refresh }) {
  const [opened, setOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const recicleResume = useCallback(
    async id => {
      const { data: retorno } = await api.put('curriculoetapaprocessoseletivo', { id, excluirDoProcesso: null });
      if (retorno.status) {
        refresh(curriculo =>
          curriculo.map(currentResume => {
            if (currentResume.id === id) {
              return { ...currentResume, excluirDoProcesso: 0 };
            } else {
              return { ...currentResume };
            }
          }),
        );
      } else {
        enqueueSnackbar('Não foi possível processar sua solicitação. Tente novamente ou contacte o administrador.', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, refresh],
  );
  return (
    <Container>
      <Header onClick={() => setOpened(!opened)}>
        <MdFolderShared size={24} />
        Descartados
        {opened ? (
          <button type="button">
            <MdExpandMore size={24} />
          </button>
        ) : (
          <button type="button">
            <MdExpandLess size={24} />
          </button>
        )}
      </Header>
      <ListContainer open={opened} qte={curriculos.length}>
        <ul>
          {curriculos &&
            curriculos.map(item => (
              <List key={item.id}>
                <span>{item.curriculo.usuario.nome}</span>
                <MdReplay size={24} onClick={() => recicleResume(item.id)} />
              </List>
            ))}
        </ul>
      </ListContainer>
    </Container>
  );
}

RemovedResumeList.propTypes = {
  curriculos: PropTypes.instanceOf(Object).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default memo(RemovedResumeList);
