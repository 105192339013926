import styled from 'styled-components';

export const SearchBoxContainer = styled.div`
  border-radius: 50px;
  min-width: 60%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  background: rgba(91, 92, 101, 0.3);
  box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.08);
  align-items: center;
  max-height: 36px;
  width: 100%;

  #button-search {
    display: inline-block;
    height: 36px;
    position: relative;
    width: 72px;
  }

  #button-search svg {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    height: 24px;
    left: 24px;
    position: absolute;
    top: 6px;
    width: 24px;
    -webkit-transition: -webkit-transform 0.15s linear;
    transition: transform 0.15s linear;
  }

  input {
    pointer-events: auto;
    background: none;
    border: none;
    box-sizing: border-box;
    color: rgba(31, 31, 31, 1) !important;
    display: block;
    font-size: 1.2em;
    height: 36px;
    max-height: 36px;
    padding-right: 10px;
    padding-bottom: 2px;
    width: 100%;
    background: transparent !important;
    border-radius: 50px;
    padding-left: 5px;
  }

  input::placeholder {
    color: ${props => props.placeholderColor} !important;
    font-size: 0.95em;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  input:-webkit-autofill {
    transition: background-color 50000s ease-in-out 0s;
  }
`;
