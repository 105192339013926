export const ADD_JOBS = '/add-jobs';
export const ADMIN_RESUME_VIEWER = '/admin-resume-viewer';
export const CATEGORY_SKILLS = '/category-skills';
export const DASHBOARD = '/dashboard';
export const DEPARTMENT = '/department';
export const BUG_REPORT = '/bug-report';
export const HOME = '/main';
export const JOBS = '/jobs';
export const LOGIN = '/';
export const MY_PROCESS = '/my-process';
export const PASSWORD_FORGET = '/pass-forget';
export const PREVIEW_BOX = '/preview-box';
export const PROFILE = '/profile';
export const RECOVERY = '/recovery';
export const RESUME = '/resume';
export const RESUME_STORE = '/resume-store';
export const RESUME_VIEWER = '/resume-viewer';
export const SELECTIVE_PROCESS = '/selective-process';
export const SETTINGS = '/settings';
export const STEPS = '/steps';
export const SIGNUP = '/signup';
export const TYPE_RESUME_CLASSIFICATION = '/type-resume-classification';
export const USERS = '/users';
export const USERS_GROUP = '/users-group';
export const USER_CLAIM = '/user-claim';
export const USER_VIDEO = '/video-process';
export const VERIFY_EMAIL = '/email-verification';
