import styled from 'styled-components';

export const Container = styled.div``;

export const Button = styled.button`
  background-color: ${props => (props.backColor ? props.backColor : '#00995d')};
  color: ${props => (props.color ? props.color : '#fff')};
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  min-width: 150px;
  cursor: pointer;
  line-height: 24px;

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 10px;
  }
`;
