import React from 'react';

import { Form, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import { RowContainer, FieldContainer } from '../styles';

function PasswordConfiguration({ submitPasswordHandler }) {
  return (
    <div>
      <h1>Senha Secreta</h1>
      <Form onSubmit={submitPasswordHandler} initialData={{}}>
        <Input style={{ display: 'none' }} name="teste1" type="text" />
        <Input style={{ display: 'none' }} name="teste2" type="text" />
        <Input style={{ display: 'none' }} name="teste3" type="text" />
        <RowContainer>
          <FieldContainer width={570}>
            <label htmlFor="senhaAntiga">Senha Antiga</label>
            <Input name="senhaAntiga" type="password" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer width={570}>
            <label htmlFor="novaSenha">Nova Senha</label>
            <Input name="novaSenha" type="password" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer width={570}>
            <label htmlFor="confirmarSenha">Confirmar Senha</label>
            <Input name="confirmarSenha" type="password" />
          </FieldContainer>
        </RowContainer>
        <Button color="#fff" backColor="#00995d" handleClick={() => {}} text="Salvar" />
      </Form>
    </div>
  );
}

PasswordConfiguration.propTypes = {
  submitPasswordHandler: PropTypes.func.isRequired,
};

export default PasswordConfiguration;
