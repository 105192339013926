import React from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { store } from '~/store';
import { toggleModal } from '~/store/modules/modal/actions';

import { Container, ButtonClose } from './styles';

const ReactModal = () => {
  const dispatch = useDispatch();
  const modalOpen = useSelector(state => state.modal.modalOpen);
  const { children } = store.getState().modal;

  return (
    <Container>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => dispatch(toggleModal(false, null, null, null))}
        style={{ zIndex: 999999 }}
        ariaHideApp={false}
      >
        <ButtonClose type="button" onClick={() => dispatch(toggleModal(false, null, null, null))}>
          <MdClose size={24} />
        </ButtonClose>
        {children}
      </Modal>
    </Container>
  );
};

export default ReactModal;
