import produce from 'immer';

const INITIAL_STATE = {
  id: 1,
  snackOpen: false,
  message: null,
  chave: null,
  icon: 'success',
};

export default function snack(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@snack/TOGGLE': {
        draft.snackOpen = action.payload.snackOpen;
        draft.message = action.payload.message;
        draft.chave = action.payload.chave;
        draft.icon = action.payload.icon;
        break;
      }
      default:
    }
  });
}
