import styled from 'styled-components';

export const Container = styled.div`
  /* @import url('https://fonts.googleapis.com/css?family=Abel');
	font-family: Abel, Arial, Verdana, sans-serif !important; */
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card {
    width: 100%;
    /* max-width: 597.64px; */
    height: 250px;
    background-color: #fff;
    background: linear-gradient(#f8f8f8, #fff);
    box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    &:hover .additional {
      width: 100%;
      border-radius: 0 5px 5px 0;
    }
  }

  .card h1 {
    text-align: center;
  }

  .card .additional {
    position: relative;
    width: 150px;
    height: 100%;
    background: linear-gradient(#0a5f55, #00995d);
    transition: width 0.4s;
    overflow: hidden;
    z-index: 2;
  }

  .card.green .additional {
    background: linear-gradient(#92bca6, #a2ccb6);
  }

  .card .additional .user-card {
    width: 150px;
    height: 100%;
    position: relative;
    float: left;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 10%;
      right: -2px;
      height: 80%;
      border-left: 2px solid rgba(0, 0, 0, 0.085);
    }
  }

  .card .additional .user-card .level,
  .card .additional .user-card .points {
    top: 15%;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.85em;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.15);
    padding: 0.125rem 0.75rem;
    border-radius: 100px;
    white-space: nowrap;
  }

  .card .additional .user-card .points {
    top: 85%;
  }

  .card .additional .user-card svg {
    top: 50%;
  }

  .card .additional .more-info {
    /* width: calc(90% - 100px); */
    /* width: 300px; */
    float: left;
    position: absolute;
    left: 150px;
    height: 100%;
    padding: 10px;
  }

  .card .additional .more-info h1 {
    color: #fff;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 20px;
  }

  .card.green .additional .more-info h1 {
    color: #224c36;
  }

  .card .additional .coords {
    margin: 0 1rem;
    color: #fff;
    font-size: 0.7rem;
    text-transform: uppercase;

    span + span {
      float: right;
      text-transform: uppercase;
    }
  }

  .card.green .additional .coords {
    color: #325c46;
  }

  .card .additional .stats {
    font-size: 2rem;
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    top: auto;
    color: #fff;
  }

  .card.green .additional .stats {
    color: #325c46;
  }

  .card .additional .stats > div {
    flex: 1;
    text-align: center;
  }

  .card .additional .stats i {
    display: block;
  }

  .card .additional .stats div.title {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .card .additional .stats div.value {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
  }

  .card .additional .stats div.value.infinity {
    font-size: 2.5rem;
  }

  .card .general {
    /* width: calc(90% - 100px); */
    /* width: 300px; */
    height: 100%;
    position: relative;
    /* top: 0; */
    right: 0;
    left: 375px;
    z-index: 1;
    box-sizing: border-box;
    padding: 1rem;
    color: #333;
    /* text-align: justify; */
    padding-top: 10px;

    h1 {
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  .card .general .more {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 0.9em;
  }
`;

export const UserImageContainer = styled.div`
  img {
    width: 96px;
    height: 96px;
    border: 4px solid #0a5f55;
    border-radius: 50%;
    border-image: initial;
    display: block;
    object-fit: cover;
  }
`;
