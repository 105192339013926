import produce from 'immer';

const INITIAL_STATE = {
  id: 1,
  modalOpen: false,
  children: {},
  chave: null,
  icon: 'success',
};

export default function modal(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@modal/TOGGLE': {
        draft.modalOpen = action.payload.modalOpen;
        draft.children = action.payload.children;
        draft.chave = action.payload.chave;
        draft.icon = action.payload.icon;
        break;
      }
      default:
    }
  });
}
