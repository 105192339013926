/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useRef, useEffect, memo } from 'react';
import { MdDelete, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import DatePicker from '../../../components/DatePickerMaterial';
import ReactSelect from '../../../components/ReactSelect';
import { alertaExclusao, getUTCDate } from '../../../functions/curriculos';
import { InputResume } from '../styles';
import {
  Container,
  Header,
  SubHeaderContainer,
  ToolsContainer,
  DeleteContainer,
  BodyForm,
  DescriptionContainer,
  PeriodoContainer,
} from './styles';

const HistoricoEducacional = ({
  itemIndex,
  remove,
  dados,
  setHistorico,
  dadosItem,
  escolaridades,
  refresh,
  handleSubmit,
  cid,
}) => {
  const [, setCidade] = useState('');
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [graduacao, setGraduacao] = useState();
  const [, setDescricaoAtividades] = useState('');
  const [nomeInstituicao, setNomeInstituicao] = useState('(não especifiado)');
  const [showBody, setShowBody] = useState(false);

  useEffect(() => {
    setDataInicial(dadosItem.inicioGraduacao);
    setDataFinal(dadosItem.fimGraduacao);
    setGraduacao(dadosItem.graduacao && dadosItem.graduacao.nome);
    setNomeInstituicao(dadosItem.nomeInstituicao);
    setDescricaoAtividades(dadosItem.descricaoAtividades);
    setCidade(dadosItem.cidade);
    if (!dadosItem.nomeInstituicao) {
      setShowBody(true);
    }
  }, []);

  const innerRef = useRef(null);

  return (
    <Container ref={innerRef}>
      <Header>
        <SubHeaderContainer onClick={() => setShowBody(!showBody)}>
          <DescriptionContainer className="hover-title">
            {graduacao} cursado no(a) {nomeInstituicao}
          </DescriptionContainer>
          <PeriodoContainer>
            {dataInicial && format(getUTCDate(dataInicial), 'yyyy')} {dataInicial && dataFinal && ' - '}
            {dataFinal && format(getUTCDate(dataFinal), 'yyyy')}
          </PeriodoContainer>
        </SubHeaderContainer>
        <ToolsContainer className="tools-container">
          {showBody ? (
            <MdKeyboardArrowUp size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          ) : (
            <MdKeyboardArrowDown size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          )}
        </ToolsContainer>
        <DeleteContainer className="tools-container">
          <MdDelete
            size={24}
            className="tools-button"
            onClick={() =>
              alertaExclusao(`Confirma a exclusão do historico "${graduacao}"?`, () =>
                remove(dados, setHistorico, itemIndex, 'historicoEducacional', dadosItem.id, refresh, cid),
              )
            }
          />
        </DeleteContainer>
      </Header>
      <BodyForm show={showBody}>
        <InputResume id="id" name="id" type="hidden" />
        <InputResume id="cid" name="cid" type="hidden" />
        <InputResume id="sequencia" name="sequencia" type="hidden" />
        <div className="row-fields">
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="nomeInstituicao">Nome Instituição *</label>
              <InputResume
                id="nomeInstituicao"
                name="nomeInstituicao"
                onChange={e => setNomeInstituicao(e.target.value)}
                maxLength={100}
              />
            </div>
          </div>
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="graduacaoId">Graduação *</label>
              <div className="input-container">
                <ReactSelect
                  options={escolaridades}
                  multiple={false}
                  name="graduacaoId"
                  label={null}
                  closeOnSelect
                  fieldPointer="escolaridadeId"
                  onBlur={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row-fields">
          <div className="column-field-double">
            <div className="sub-column-field">
              <div className="field-sub-container">
                <label htmlFor="inicioGraduacao">Período Inicial *</label>
                <DatePicker
                  id="inicioGraduacao"
                  name="inicioGraduacao"
                  dateFormat="yyyy"
                  placeholder="2001"
                  views={['year']}
                  type="date"
                  onClose={handleSubmit}
                />
              </div>
            </div>
            <div className="sub-column-field">
              <div className="field-sub-container">
                <label htmlFor="fimGraduacao">Período Final *</label>
                <DatePicker
                  id="fimGraduacao"
                  name="fimGraduacao"
                  dateFormat="yyyy"
                  placeholder="2004"
                  views={['year']}
                  type="date"
                  onClose={handleSubmit}
                />
              </div>
            </div>
          </div>
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="cidade">Cidade</label>
              <InputResume id="cidade" name="cidade" onChange={e => setCidade(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="row-fields">
          <label htmlFor="descricao">Descricao (Nome do curso, especializações...)</label>
          <InputResume
            id="descricao"
            name="descricao"
            onChange={e => setDescricaoAtividades(e.target.value)}
            multiline
            rows="7"
          />
        </div>
      </BodyForm>
    </Container>
  );
};

HistoricoEducacional.propTypes = {
  dados: PropTypes.instanceOf(Object).isRequired,
  dadosItem: PropTypes.instanceOf(Object).isRequired,
  escolaridades: PropTypes.instanceOf(Object).isRequired,
  itemIndex: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  setHistorico: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cid: PropTypes.string,
};

HistoricoEducacional.defaultProps = {
  cid: null,
};

export default memo(HistoricoEducacional);
