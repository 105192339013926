import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const prodConfig = {
  apiKey: 'AIzaSyBhD230I_8jTHQWNDPDYoEbfyhm2ff0j3o',
  authDomain: 'unijob-developer.firebaseapp.com',
  databaseURL: 'https://unijob-developer.firebaseio.com',
  projectId: 'unijob-developer',
  storageBucket: 'unijob-developer.appspot.com',
  messagingSenderId: '727298852829',
  appId: '1:727298852829:web:189b5f69fb80bea0',
};

const devConfig = {
  apiKey: 'AIzaSyBhD230I_8jTHQWNDPDYoEbfyhm2ff0j3o',
  authDomain: 'unijob-developer.firebaseapp.com',
  databaseURL: 'https://unijob-developer.firebaseio.com',
  projectId: 'unijob-developer',
  storageBucket: 'unijob-developer.appspot.com',
  messagingSenderId: '727298852829',
  appId: '1:727298852829:web:189b5f69fb80bea0',
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
export { auth, db, storage };
