import { Link } from 'react-router-dom';

import styled from 'styled-components';

import DashLitleBackground from '../../assets/background-card-style.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-content: center;
  overflow: hidden;

  a {
    text-decoration: none;
    color: #333;
  }
`;

export const UpContainer = styled.div`
  width: 100%;
  max-width: 1366px;
  overflow: hidden;
  padding: 10px 0;

  @media (max-width: 768px) {
    padding: 15px 10px;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
    grid-auto-rows: 200px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1399px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 5px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-auto-rows: 200px !important;
      grid-row-gap: 20px;
    }

    @media (max-height: 825px) {
      grid-auto-rows: 200px;
    }
  }
`;

export const CardContainer = styled(Link)`
  text-decoration: none;

  a {
    text-decoration: none;
  }

  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  opacity: 1;
  background: url(${DashLitleBackground}) repeat scroll 90% 0% / cover;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 20px 30px;
  /* border: 2px solid rgb(0, 0, 0, 0) !important; */
  border-image: initial;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  img {
    margin-bottom: 25px;
    width: 76px;
    height: 76px;
  }

  &:hover {
    /* border: 1px solid #b1d34b !important; */
    cursor: pointer;
    transform: translate3d(0, -1%, 0);
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  span {
    display: block;
    width: 363px;
    font-size: 18px;
    line-height: 1.5;
    color: rgb(135, 134, 139);
  }

  b {
    color: #b1d34b;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    padding: 30px !important;
  }

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      font-size: 1.2rem;
    }
  }
`;
