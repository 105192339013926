import styled from 'styled-components';

export const Container = styled.div`
  transition: all 2s ease-in;

  @media (min-width: 1280px) {
    max-width: 960px !important;
  }

  div.normal {
    position: inline-block;
    width: 100%;
    max-width: 960px;
    margin-bottom: 24px;
  }

  div.fixed {
    display: block;
    background: rgba(255, 255, 255, 0.85);
    /* background: linear-gradient(90deg, rgba(196, 203, 207, 0.9) 0%, rgba(255, 255, 255, 0.9) 97%, rgba(196, 207, 203, 0.7) 100%); */
    margin-top: 80px !important;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: fixed;
    width: 50%;
    padding: 10px 0 0 0;
    right: auto;
    transition: all 0.5s ease-in;

    @media (min-width: 1280px) {
      max-width: 960px !important;
    }

    @media (max-width: 768px) {
      margin-top: 60px !important;
      padding-top: 0;
      left: 0;
      padding: 10px 0 0 0;
      width: 100%;
      max-width: 100% !important;

      .percent-info {
        padding: 0 0 0 15px;
      }
    }
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  color: #333;
  margin-bottom: 2px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const PercentageContainer = styled.div`
  color: ${props => props.color};
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 6px;

  &:after {
    margin-left: 2px;
    content: '%';
  }
`;
export const ProfileDescription = styled.div`
  margin-right: 15px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  color: rgb(30, 30, 30);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  svg {
    color: #411564;
  }

  @media (max-width: 768px) {
    svg {
      display: none;
    }
  }
`;

export const ProgressContainer = styled.div`
  background-color: rgb(242, 245, 250);
  width: 100%;
  height: 3px;
`;

export const ProgressEvolution = styled.div`
  width: ${props => `${props.percentage}%`};
  background-color: ${props => props.color};
  height: 3px;
`;
