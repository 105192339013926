import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const MenuButton = ({ nome, children, onClick, color, active }) => (
  <Container onClick={onClick} color={color} active={active}>
    {children}
    {/* <br /> */}
    <small>{nome}</small>
  </Container>
);

MenuButton.propTypes = {
  nome: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default memo(MenuButton);
