import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { LoaderDevices, ReactModal, Snack, MainBar, MenuLateral } from '~/components';

import { Container, SubContainer } from './styles';

const MainContainer = ({ children }) => {
  const { loading } = useSelector(state => state.main);
  const { modalOpen } = useSelector(state => state.modal);
  const { snackOpen } = useSelector(state => state.snack);

  return (
    <Container>
      <MainBar />
      <MenuLateral />
      <SubContainer>{children}</SubContainer>
      {loading && <LoaderDevices />}
      {modalOpen && <ReactModal />}
      {snackOpen && <Snack />}
    </Container>
  );
};

MainContainer.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default memo(MainContainer);
