import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin-bottom: 1px;
  background: ${props => (props.active ? 'rgba(10, 95, 85, 1)' : 'none')};
  padding: 0 15px;
  border: none;
  border-top: 0.03em solid #fff;
  color: ${props => (props.active ? '#b1d34b !important' : '#fff')};
  /* transition: all 0.8s; */
  /* color: #fff; */

  &:last-child {
    border-bottom: 0.03em solid #fff;
  }

  &:hover {
    background: #0a5f55;
    color: #b1d34b;

    .icon-menu {
      color: #b1d34b;
    }
  }

  small {
    font-weight: 200 !important;
    font-size: 14px !important;
  }

  .icon-menu {
    font-size: 24px;
    cursor: pointer;
    color: ${props => (props.active ? '#b1d34b !important' : '#fff')};
  }
`;
