import React from 'react';

import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import { TextContainer } from '../styles';

function DeleteData({ submitDeleteProfileHandler }) {
  return (
    <div>
      <h1>Excluir Dados Pessoais</h1>
      <TextContainer>
        <p>
          Nessa opção, é possível você excluir todos os seus dados cadastrados no sistema #Unijob, indicando que você não quer
          mais participar dos nossos processos seletivos e nem ser avisado sobre novas vagas disponíveis.
        </p>
        <p>Seu usuário será excluído e seu acesso ao sistema não será mais possível.</p>
        <p>
          Lembramos que seus dados não são disponibilizados com nenhuma outra plataforma, sendo utilizados somente para os fins
          específicos deste sistema, que é maximizar as suas chances de fazer parte do grupo empresarial Unimed Varginha.
        </p>
        <Button color="#fff" backColor="#e61c1c" text="Excluir Dados" handleClick={submitDeleteProfileHandler} />
      </TextContainer>
    </div>
  );
}

DeleteData.propTypes = {
  submitDeleteProfileHandler: PropTypes.func.isRequired,
};

export default DeleteData;
