import { toast } from 'react-toastify';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { detect } from 'detect-browser';
import publicIp from 'public-ip';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { auth, storage } from '~/firebase';
// import errors from '~/firebase/errorsTranslate';
import createUserResume from '~/functions/createUserResume';
import * as funcoes from '~/functions/curriculos';
import userHasResume from '~/functions/userHasResume';
import api from '~/services/api';
import history from '~/services/history';

import { signInSuccess, signUpSuccess, signFailure } from './actions';

const createFirebaseUser = async ({ email, password }) =>
  auth.doCreateUserWithWmailAndPassword(email, password).catch(async err => err);

export function* signIn({ payload }) {
  const { email, password } = payload;

  try {
    const browser = detect();

    const response = yield auth.doSignInWithEmailAndPassword(email, password);

    const {
      user: { refreshToken: token },
      user,
    } = response;

    const userData = yield call(api.get, `usuarios/email/${user.email}`);
    const { data } = userData.data;

    const hasResume = yield userHasResume(data.id);

    if (!hasResume) {
      yield createUserResume(data.id);
    }

    const loginHistory = {
      ip: yield publicIp.v4(),
      usuarioId: data.id,
      horario: format(new Date(), 'PPPPpppp', { locale: ptBR }),
      local: 'Undefined',
      dataCadastro: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss", { locale: ptBR }),
      dispositivo: `${browser.name} | Other  | ${browser.os}`,
    };

    yield call(api.post, 'loginHistory', loginHistory);

    let imageUrl = null;
    try {
      imageUrl = yield call(storage.doUserPhotoUrlLink, data.id);
    } catch (err) {
      // do nothign
    }

    const completeUser = {
      email: data.email,
      imageUrl: imageUrl || null,
      name: data.nome,
      id: data.id,
      userClaimId: data.tipoUsuarioId,
      cpf: data.cpf,
    };
    yield put(signInSuccess(token, completeUser));

    history.push('/resume');
  } catch (err) {
    if (err.code === 'auth/user-not-found') {
      const {
        data: { status },
      } = yield call(api.get, `usuarios/email/${email}`);

      if (status) {
        yield call(createFirebaseUser, { email, password });
        toast.info(`Clique novamente em ACESSAR, acertamos um detalhe no seu cadastro e está tudo pronto.`);
        yield put(signFailure());
        return;
      }
    }

    toast.error(`Erro na autenticação, verifique seus dados. ${err.message}`);
    yield put(signFailure());
  }
}

async function verificaBancoDadosFuncionando() {
  try {
    await api.get('sexo');
    return true;
  } catch (err) {
    return false;
  }
}

// async function removeUser(id) {
//   return api.delete('singuperror', { data: { id } });
// }

export function* signUp({ payload }) {
  try {
    const { name, email, password, cpf } = payload;
    if (!name || !email) {
      return;
    }

    const usuario = {
      nome: name,
      email,
      cpf: cpf ? cpf.replace(/[^0-9]/g, '') : null,
      googleId: null,
      imageUrl: null,
      emailVerified: true,
      tipoUsuarioId: 1,
      dataCadastro: funcoes.getLocalDate(),
    };

    const verificaBanco = yield call(verificaBancoDadosFuncionando);

    if (!verificaBanco) {
      toast.error(`Erro de conexão com a base de dados. Favor tentar seu cadastro daqui a 10 minutos`);
      return;
    }

    const {
      data: { data: dados },
    } = yield call(api.post, 'usuarios', usuario);

    const hasResume = yield userHasResume(dados.id);

    if (!hasResume) {
      yield createUserResume(dados.id);
    }

    const created = yield call(createFirebaseUser, { email, password });

    if (created.code === 'auth/email-already-in-use' || created.error) {
      toast.error(`Erro ao criar usuário. Usuário já existe. ${created.message}`);

      yield put(signFailure());
    } else {
      yield put(signUpSuccess());
      toast.info(`Cadastro efetuado com sucesso!`);
      history.push('/');
    }
  } catch (err) {
    toast.error(
      `Erro ao realizar o cadastro. Verifique seus dados e tente novamente. Se persistir o erro, tente novamente mais tarde. ${err.message}`,
    );
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  auth.doSignOut();
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
