import React, { memo } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

import { SwitchStyled } from './toggle.style';
import './styles.css';

const ToggleSwitch = props => {
  const { name, toggled, onClick, label } = props;
  return (
    <FormControlLabel
      control={<SwitchStyled checked={toggled} onChange={onClick} label={label} name={name} />}
      label={label}
      labelPlacement="start"
    />
  );
};

ToggleSwitch.propTypes = {
  name: PropTypes.string,
  toggled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  name: '',
  toggled: false,
  label: '',
  onClick: null,
};

export default memo(ToggleSwitch);
