import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { MdClose } from 'react-icons/md';
// import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import PropTypes from 'prop-types';

import getCroppedImg from './cropImage';
import { Container, InputContainer, CloseButton, ImageEditContainer } from './styles';

const ReactCroper = ({ setShowCropper, saveImage }) => {
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);
  const [rotation] = useState(0);
  const [salvando, setSalvando] = useState(false);

  const [showEasyCrop, setShowEasyCrop] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpImg(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      setShowEasyCrop(true);
    }
  };

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(upImg, croppedAreaPixels, rotation);
      setCroppedImage(croppedImg);
      saveImage(croppedImg);
    } catch (e) {
      setSalvando(false);
    }
  }, [croppedAreaPixels, rotation, saveImage, upImg]);

  return (
    <Container>
      <CloseButton onClick={() => setShowCropper(false)}>
        <MdClose />
      </CloseButton>
      <InputContainer>
        <input type="file" name="file" accept="image/*" onChange={onSelectFile} />
      </InputContainer>
      <ImageEditContainer>
        {showEasyCrop && (
          <Cropper
            image={upImg}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        )}
      </ImageEditContainer>
      <button
        type="button"
        className="save-cropped-image-button"
        onClick={() => {
          saveCroppedImage();
          setSalvando(true);
        }}
        disabled={!!salvando}
      >
        {salvando ? 'Salvando...' : 'Salvar'}
      </button>
    </Container>
  );
};

ReactCroper.propTypes = {
  setShowCropper: PropTypes.func.isRequired,
  saveImage: PropTypes.func.isRequired,
};

export default ReactCroper;
