import { MdEdit, MdDelete } from 'react-icons/md';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 96%;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-top: 30px solid ${props => props.color};
  cursor: grab;

  header {
    position: absolute;
    top: -36px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 25px 0 0;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #333;
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    margin-top: 5px;
  }

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      padding-top: 31px;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      cursor: grabbing;

      p,
      img,
      span,
      header {
        opacity: 0;
      }
    `}
`;

export const Label = styled.span`
  height: 20px;
  border-radius: 2px;
  display: inline-block;
  justify-content: center;
  text-align: left;
  padding: 0 10px;
`;

export const SetorContainer = styled.span`
  /* background: #333; */
  padding: 2px 4px 0 4px;
  color: #333;
  font-weight: 400;
  font-size: 13px;
  display: inline-block;
`;

export const EditButton = styled(MdEdit)`
  display: inline-block;
  right: 0;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const DeleteButton = styled(MdDelete)`
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    color: #ed1651;
  }
`;
