import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1 !important;

  .ReactModal__Content {
    max-width: 1160px !important;
    margin: 0 auto !important;
  }
`;

export const ButtonClose = styled.button`
  background: none;
  right: 10px;
  top: 10px;
  position: absolute;
  padding: 5px 5px;
  border: none;
  background: #411564;
  color: #fff;
  border-radius: 50em;

  &:hover {
    opacity: 0.8;
  }
`;
