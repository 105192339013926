import React, { memo } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import AuthLayout from '~/pages/_layouts/auth';
import CleanLayout from '~/pages/_layouts/clean';
import DefaultLayout from '~/pages/_layouts/default';
import { store } from '~/store';

function RouteWrapper({ component: Component, isPrivate, ...rest }) {
  const { signed } = store.getState().auth;
  const location = useLocation();

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/resume" />;
  }

  const cleanLayoutPaths = ['/jobs', '/resume-viewer', '/resume-viewer/', '/admin-resume-viewer', '/admin-resume-viewer/'];

  let Layout = signed ? DefaultLayout : AuthLayout;
  Layout = cleanLayoutPaths.includes(location.pathname) ? CleanLayout : Layout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

export default memo(RouteWrapper);
