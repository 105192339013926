import styled from 'styled-components';

export const DashContainer = styled.div`
  display: grid !important;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 100vh;
  width: 100%;
  height: 100%;

  @media print {
    background-color: #fff;
    display: block !important;
    grid-template-columns: 1fr;
  }
`;

export const ConfigContainer = styled.div`
  background: #555;
  color: #000;
  margin: 5px;
  border-radius: 5px;
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100vw - 850px);
  border-bottom: 2px solid #b1d34b;
  height: ${props => (props.showInserirProcesso ? '275px' : '55px')};
  /* overflow: hidden; */

  h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  form {
    opacity: ${props => (props.showInserirProcesso ? 1 : 0)};
    height: ${props => (props.showInserirProcesso ? '100%' : '0')};
    transition: all 0.4s;
  }

  @media print {
    display: none;
  }
`;

export const ProcessListContainer = styled.div`
  background: #555;
  color: #000;
  margin: 5px;
  border-radius: 5px;
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100vw - 850px);
  border-bottom: 2px solid #b1d34b;
  height: ${props => (props.showPreAnalise ? '310px' : '55px')};

  div[class^='-menu'] {
    z-index: 9999 !important;
  }

  @media print {
    display: none;
  }

  h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  textarea {
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 10px;
    border: none;
    width: 100%;
    padding: 10px;
  }

  form {
    opacity: ${props => (props.showPreAnalise ? 1 : 0)};
    height: ${props => (props.showPreAnalise ? '100%' : '0')};
    transition: all 0.4s;
  }
`;

export const Container = styled.div`
  display: inline-table;
  max-width: 800px;
  min-width: 800px;
  min-height: 100vh;
  background: #fff;
  margin: 0;
  padding: 40px 60px 120px 60px;
  page-break-after: always;

  @media print {
    min-width: 100% !important;
    min-height: 100%;
    padding: 0 20mm !important;
    box-shadow: none;
    border: none;
    font-size: 18px;
    background: #fff;
    overflow: initial !important;
    page-break-after: always;

    @page {
      margin: 12mm 0 15mm 0;
      size: 210mm 297mm;
      marks: none;
      running-head: none;
    }

    @page :top-left {
      display: none;
    }

    /* body {
			margin: 1.6cm;
		} */

    .full-name {
      font-size: 32px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }

  .header {
    margin-bottom: 30px;

    .full-name {
      font-size: 38px;
      text-transform: uppercase;
      margin: 6px 0 20px 0;
      position: relative;

      img {
        position: absolute;
        width: 110px;
        height: 110px;
        border-radius: 15em;
        top: 0px;
        right: -30px;
        border: 3px solid #411564;
        object-fit: cover;
      }
    }

    .first-name {
      font-weight: 700;
    }

    .last-name {
      font-weight: 300;
      width: 595px;
      font-size: 27px;
    }

    .contact-info {
      margin-bottom: 2px;
    }

    .email,
    .phone {
      color: #999;
      font-weight: 300;
    }

    .separator {
      height: 10px;
      display: inline-block;
      border-left: 2px solid #999;
      margin: 0px 10px;
    }

    .position {
      font-weight: bold;
      display: inline-block;
      margin-right: 10px;
      text-decoration: underline;
    }
  }

  .details {
    line-height: 20px;

    .section {
      margin-top: 25px;
    }

    .section:last-of-type {
      margin-bottom: 0px;
    }

    .section__title {
      letter-spacing: 2px;
      color: #411564;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .section__list-item {
      margin-bottom: 10px;
    }

    .section__list-item:last-of-type {
      margin-bottom: 0;
    }

    .left,
    .right {
      vertical-align: top;
      display: inline-block;
    }

    .left {
      width: 60%;
    }

    .right {
      text-align: right;
      width: 39%;
    }

    .name {
      font-weight: bold;
    }

    .addr {
      text-align: justify;
    }

    a {
      text-decoration: none;
      color: #000;
      font-style: italic;
    }

    a:hover {
      text-decoration: underline;
      color: #000;
    }

    .skills {
    }

    .skills__item {
      margin-bottom: 1px;
    }

    .skills__item .right {
      input {
        display: none;
      }

      label {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #c3def3;
        border-radius: 20px;
        margin-right: 3px;
      }

      input:checked + label {
        background: #0a5f55;
      }
    }
  }
`;

export const DownloadButton = styled.button`
  border: none;
  padding: 5px 20px;
  background-color: #411564;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  width: 180px;
  height: 40px;
  svg {
    margin-right: 5px;
  }

  @media print {
    display: none;
  }
`;

export const BackButton = styled.button`
  border: none;
  padding: 5px 37px;
  background-color: #0a5f55;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  width: 180px;
  height: 40px;

  svg {
    margin-right: 5px;
  }

  span {
    top: 2px !important;
    position: relative;
  }

  @media print {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100vw - 850px);
  background: #555;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 2px solid #b1d34b;
  margin: 5px;
  position: fixed;
  right: 5px;
`;

export const RightItemsContainer = styled.div``;
