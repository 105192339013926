import React from 'react';

import { Form, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import { RowContainer, FieldContainer, TextContainer } from '../styles';

function UserData({ formUserData, submitUpdateEmailHandler }) {
  return (
    <div>
      <h1>Meu Perfil</h1>
      <Form onSubmit={submitUpdateEmailHandler} initialData={formUserData}>
        <RowContainer>
          <FieldContainer width={380}>
            <label htmlFor="nome">Nome</label>
            <Input name="nome" disabled />
          </FieldContainer>
          <FieldContainer width={190}>
            <label htmlFor="cpf">CPF</label>
            <Input name="cpf" disabled />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer width={590}>
            <label htmlFor="email">E-mail</label>
            <Input name="email" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <Button color="#fff" backColor="#00995d" text="Atualizar e-mail" handleClick={() => {}} />
        </RowContainer>
      </Form>
      <br />
      <TextContainer>
        <p>
          Realizando o seu cadastro no nosso sistema, você concorda em autorizar a Unimed Varginha em entrar em contato nos
          números e e-mail disponibilizados.
        </p>
        <p>
          O nosso sistema se utiliza de cookies para tornar sua navegação mais fluída e oferecer uma boa experiência de
          utilização.
        </p>
      </TextContainer>
    </div>
  );
}

UserData.propTypes = {
  formUserData: PropTypes.instanceOf(Object).isRequired,
  submitUpdateEmailHandler: PropTypes.func.isRequired,
};

export default UserData;
