import styled from 'styled-components';

export const DashContainer = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100vh;
  width: 100%;
  height: 100%;
  padding: 10px 20px;

  h3 {
    color: #411564;
  }

  label {
    margin-top: 5px;
  }

  @media print {
    background-color: #fff;
    display: block !important;
    grid-template-columns: 1fr;
  }

  a.action-buttons {
    margin-left: 15px;
    color: #666;
    cursor: pointer;
    right: 55px;
    position: absolute;
    top: 16px;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  margin-top: 15px;
  bottom: 20px;
`;

export const RejectButtonContainer = styled.div`
  position: absolute;
  margin-top: 15px;
  bottom: 20px;
  right: 20px;
`;

export const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #00995d;
  padding: 15px 25px 18px;
  border-radius: 3px;
  color: #fff;
  margin-top: 18px;
  margin-bottom: 10px;
  border-left: 6px solid #0a5f55;

  h4 {
    color: #fff;
  }
`;

export const FieldContainer = styled.div``;

export const SchedulerContainer = styled.div`
  margin-top: 40px;
  padding: 10px 0 0 20px;
`;
