import React, { useState, useEffect, memo, useCallback } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

import {
  Container,
  DescriptionContainer,
  PercentageContainer,
  ProfileDescription,
  ProgressContainer,
  ProgressEvolution,
} from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const InputProgress = ({ percentage }) => {
  const [variant, setVariant] = useState('danger');

  const verifyScroll = useCallback(() => {
    const header = document.getElementById('container-geral');
    if (!header) return;
    const sticky = header.offsetTop;
    if (window.pageYOffset > sticky - 460) header.classList.add('fixed');
    if (window.pageYOffset < sticky - 10) header.classList.remove('fixed');
  }, []);

  window.onscroll = () => {
    verifyScroll();
  };

  useEffect(() => {
    switch (true) {
      case percentage <= 60:
        setVariant('#de3a11');
        break;
      case percentage > 60 && percentage <= 70:
        setVariant('#ffcb08');
        break;
      case percentage > 70 && percentage <= 84:
        setVariant('#168AE6');
        break;
      case percentage >= 85:
        setVariant('#00995d');
        break;
      default:
        break;
    }
  }, [percentage]);

  return (
    <Container>
      <div className="normal" id="container-geral">
        <DescriptionContainer>
          <PercentageContainer className="percent-info" color={variant}>
            {percentage}
          </PercentageContainer>
          <ProfileDescription>
            <span className="profile-info">dos dados interessantes preenchidos</span>
            <HtmlTooltip
              aria-label="info"
              title={
                <>
                  Não é necessário atingir 100%, porém esta porcentagem nos auxilia a selecionar os currículos que estão mais
                  completos, pois alguns campos são de preenchimento obrigatório, então fique atento(a) ao preencher. Caso você
                  não possua outros idiomas, habilidades, cursos, histórico profissional ou histórico educacional, não se
                  preocupe, você terá a mesma chance de participar de nossos processos, e que isso lhe inspire a buscar melhorias
                  na sua vida. Os campos marcardos com * são de preenchimento obritatório. Se o botão salvar não estiver
                  funcionando, será devido a algum campo que não foi preenchido, verifique todos campos, inclusive, os campos dos
                  históricos.
                </>
              }
            >
              <InfoIcon size={24} />
            </HtmlTooltip>
          </ProfileDescription>
        </DescriptionContainer>
        <ProgressContainer>
          <ProgressEvolution percentage={percentage} color={variant} />
        </ProgressContainer>
      </div>
    </Container>
  );
};

InputProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default memo(InputProgress);
