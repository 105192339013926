import styled from 'styled-components';

export const Container = styled.div`
  form.login {
    margin: 0 !important;
    margin-top: -25px !important;
  }

  img.logo {
    width: 230px;
  }

  label {
    color: #fff;
    font-size: 18px;
  }

  span {
    color: #ffcb08;
    margin-top: -10px;
    display: block;
    margin-bottom: 10px;
  }

  .link {
    color: #c4cbcf;
    display: block;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-color: rgba(196, 203, 207, 0.2);
    text-underline-position: under;

    &:hover {
      color: #c4cbcf;
      text-decoration: underline;
      text-decoration-color: rgba(196, 203, 207, 0.8);
      text-underline-position: under;
    }
  }

  .show-job {
    color: #ffcb08;
    display: block;
    margin-top: 10px;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-color: rgba(255, 203, 8, 0.2);
    text-underline-position: under;

    &:hover {
      color: #ffcb08;
      text-decoration: underline;
      text-decoration-color: rgba(255, 203, 8, 0.8);
      text-underline-position: under;
    }
  }
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  margin: 30px auto;
  width: 500px;
  min-height: 250px;
  /* padding: 20px; */
  outline: none;
  border-radius: 15px;

  .header {
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: space-between;
    background: #411564;
    color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    button {
      border: none;
      background: #411564;
      color: #fff;
      width: 40px;
      height: 30px;
      border-radius: 5em;
      margin-top: 5px;
    }

    div {
      display: flex;
      flex-direction: column;
      padding: 10px 20px 20px 20px;
    }
  }

  .title {
    font-size: 25px;
    color: #fff;
  }

  .subtitle {
    font-size: 13px;
    color: #fff;
  }

  .containerFields {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 35px;

    .email-recuperacao {
      label {
        position: relative;
        top: -22px;
        font-size: 14px;
        white-space: nowrap;
        background: #fff;
        text-align: left;
        left: 76px;
        padding: 2px 8px;
        color: #333;
        pointer-events: none;
        border-radius: 0.5em;
      }

      input {
        padding: 10px;
        width: 400px;
        border-radius: 5em;
        border: 1px solid #aaa !important;
        margin-left: -42px;
      }
    }

    button {
      cursor: pointer;
      background: transparent;
      background-image: linear-gradient(90deg, #0a5f55, #00995d);
      display: inline-block;
      padding: 10px 30px;
      border: 2px solid #fff;
      border-radius: 10px;
      border-bottom-right-radius: 0;
      background-clip: padding-box;
      position: relative;
      color: #fff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
      transition: all 0.25s;
      margin-top: 15px;
    }

    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const FooterDisclaimer = styled.div`
  max-width: 768px;
  color: #fff;
  font-weight: 400;
  position: fixed;
  bottom: 10px;

  .nao-responda {
    margin-left: 10px;
    color: #ffcb08;
    align-self: center;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-color: rgba(255, 203, 8, 0.2);
    text-underline-position: under;

    &:hover {
      color: #ffcb08;
      text-decoration: underline;
      text-decoration-color: rgba(255, 203, 8, 0.8);
      text-underline-position: under;
    }
  }
`;
