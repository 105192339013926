import styled from 'styled-components';

export const DashContainer = styled.div`
  background-color: #fff;
  /* height: 41.71429rem; */
  overflow: hidden !important;
  max-width: 45rem;
  min-height: 120rem;

  @media print {
    max-width: 100%;
    background-color: #fff;
  }
`;

export const Container = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 6rem;
  /* height: 41.71429rem; */
  overflow: hidden !important;

  /* display: inline-table; */
  /* max-width: 800px;
	min-width: 800px; */
  /* min-height: 100vh; */
  background: #fff;
  /* margin: 0; */
  /* padding: 0px 60px 120px 60px; */
  /* page-break-after: always; */
  position: relative;
  display: block;
  max-width: 45rem;
  padding: 0rem 3rem;

  /* page {
		background: white;
		display: block;
		margin: 0 auto;
		margin-bottom: 0.5cm;
		border-bottom: 1px solid #999;
		padding: 5px 10px;
	}
	page[size='A4'] {
		width: 21cm;
		height: 29.7cm;
	}
	page[size='A4'][layout='portrait'] {
		width: 29.7cm;
		height: 21cm;
	} */

  @media print {
    line-height: 24px !important;
    min-width: 100% !important;
    min-height: 100%;
    padding: 0 20mm !important;
    box-shadow: none;
    border: none;
    font-size: 18px;
    background: #fff;
    overflow: initial !important;
    page-break-after: always;

    @page {
      margin: 12mm 0 15mm 0;
      size: 210mm 297mm;
      marks: none;
      running-head: none;
    }

    @page :top-left {
      display: none;
    }

    .full-name {
      font-size: 32px;
      text-transform: uppercase;
      margin: 5px;
    }
  }

  .header {
    margin-bottom: 30px;

    .full-name {
      font-size: 38px;
      text-transform: uppercase;
      margin: 6px 0 20px 0;
    }

    .first-name {
      font-weight: 700;
    }

    .last-name {
      font-weight: 300;
    }

    .contact-info {
      margin-bottom: 2px;
    }

    .email,
    .phone {
      color: #999;
      font-weight: 300;
    }

    .separator {
      height: 10px;
      display: inline-block;
      border-left: 2px solid #999;
      margin: 0px 10px;
    }

    .position {
      font-weight: bold;
      display: inline-block;
      margin-right: 10px;
      text-decoration: underline;
    }
  }

  .details {
    line-height: 20px;

    .section {
      margin-top: 25px;
    }

    .section:last-of-type {
      margin-bottom: 0px;
    }

    .section__title {
      letter-spacing: 2px;
      color: #411564;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .section__list-item {
      margin-bottom: 10px;
    }

    .section__list-item:last-of-type {
      margin-bottom: 0;
    }

    .left,
    .right {
      vertical-align: top;
      display: inline-block;
    }

    .left {
      width: 60%;
    }

    .right {
      text-align: right;
      width: 39%;
    }

    .name {
      font-weight: bold;
    }

    .addr {
      text-align: justify;
    }

    a {
      text-decoration: none;
      color: #000;
      font-style: italic;
    }

    a:hover {
      text-decoration: underline;
      color: #000;
    }

    .skills {
    }

    .skills__item {
      margin-bottom: 1px;
    }

    .skills__item .right {
      input {
        display: none;
      }

      label {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #c3def3;
        border-radius: 20px;
        margin-right: 3px;
      }

      input:checked + label {
        background: #0a5f55;
      }
    }
  }
`;

export const DownloadButton = styled.button`
  border: none;
  padding: 5px 4px;
  position: fixed;
  right: 1%;
  top: 2%;
  background-color: #411564;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  svg {
    margin-right: 5px;
  }

  @media print {
    display: none;
  }
`;

export const BackButton = styled.button`
  border: none;
  padding: 5px 37px;
  position: fixed;
  right: 1%;
  top: 10%;
  background-color: #0a5f55;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  svg {
    margin-right: 5px;
  }

  span {
    top: 2px !important;
    position: relative;
  }

  @media print {
    display: none;
  }
`;

export const LoadContainer = styled.div`
  position: absolute;
  padding: 20px;
  min-height: 100vh;
  width: 100vw;
  font-size: 32px;
  background: #fff;
`;
