import styled from 'styled-components';

export const Container = styled.div`
  label {
    color: #fff;
    font-size: 16px;
  }

  form.login {
    margin: 0 !important;
    margin-top: -25px !important;
  }

  p {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
  }

  img.logo {
    width: 230px;
  }

  span {
    color: #ffcb08;
    margin-top: -10px;
    display: block;
    margin-bottom: 10px;
  }

  .link {
    color: #c4cbcf;
    display: block;
    margin-top: 10px;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-color: rgba(196, 203, 207, 0.2);
    text-underline-position: under;

    &:hover {
      color: #c4cbcf;
      text-decoration: underline;
      text-decoration-color: rgba(196, 203, 207, 0.8);
      text-underline-position: under;
    }
  }
`;
