import styled from 'styled-components';

import BodyBackground from '../../../assets/body-background.png';

export const Container = styled.div`
	min-height: 100vh;
	color: #fff;
	/* background: url(${BodyBackground}) 0% 0% / cover fixed, rgb(255, 255, 255); */
  /* background: rgb(35,35,35); */
  background-color: #DEDFE6;


	@media (max-width: 768px) {
    /* background: rgb(35, 35, 35); */
    background-color: #DEDFE6;
	}
`;

export const SubContainer = styled.div`
  padding: 100px 0 20px 0;

  @media (max-width: 768px) {
    padding: 80px 0 0 0;
  }
`;
