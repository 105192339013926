import styled from 'styled-components';

export const Container = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  padding: 5px 10px 0 10px;
  /* max-width: 1366px; */
  display: flex;
  justify-content: center;
  /* margin: auto 50%; */

  @media (max-width: 768px) {
    display: grid; */
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 10px 0 10px;
    grid-template-columns: 1fr;
  }
`;
