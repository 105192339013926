import api from '~/services/api';

const checkUserByCpf = async cpf => {
  if (!cpf) return true;
  const newCpf = cpf.replace(/[^0-9]/g, '');
  try {
    const response = await api.get(`usuarios/cpf/${newCpf}`);
    const { data } = response.data;
    return data;
  } catch (error) {
    return true;
  }
};

export default checkUserByCpf;
