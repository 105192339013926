import { confirmAlert } from 'react-confirm-alert';

import arrayMove from 'array-move';


import 'react-confirm-alert/src/react-confirm-alert.css';
import { store } from '~/store';
import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import api from '../services/api';

export const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

export const rearrange = (data, setHistorico, moveFromIndex, moveToIndex) => {
  const list = arrayMove(data, moveFromIndex, moveToIndex);
  setHistorico(list);
};

export const remove = async (data, setHistorico, index, table, id, refresh, cid) => {
  const { profile: userData } = store.getState().user;

  const list = [...data];
  list.splice(index, 1);
  setHistorico(dt => ({
    ...dt,
    [table]: [...list],
  }));

  const item = {
    id,
    dataExclusao: new Date(),
    usuarioExclusao: userData.id,
    cid,
  };

  await api.delete(table, { data: item });
  refresh(getRandomInt(1000));
};

export const addHistorico = (data, setHistorico, handleChangeSubmit, historyName) => {
  setHistorico(dt => ({
    ...dt,
    [historyName]: [
      ...data,
      {
        cid: `_${getRandomInt(100000)}.${getRandomInt(100000)}`,
        nome: null,
        sequencia: data.length + 1,
        cargo: null,
        nomeInstituicao: null,
      },
    ],
  }));
  handleChangeSubmit(null, 1);
};

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};


export const getLocalDate = (dateString = Date.now()) => {
  const date = new Date(dateString);
  const timeZone = 'America/Sao_Paulo';
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss");
};

// export const getLocalDate = (dateString = Date.now()) => {
//   const date = new Date(dateString);
//   const dataLocal = date.toLocaleString('pt-BR', {
//     timeZone: 'America/Sao_Paulo',
//   });
//   return format(parse(dataLocal, 'dd/MM/yyyy HH:mm:ss', new Date()), "yyyy-MM-dd'T'HH:mm:ss");
// };

// export const getLocalDate = (dateString = Date.now()) => {
//   const date = new Date();
//   const year = date.getFullYear();
//   const month = ("0" + (date.getMonth() + 1)).slice(-2);
//   const day = ("0" + date.getDate()).slice(-2);
//   const hours = ("0" + date.getHours()).slice(-2);
//   const minutes = ("0" + date.getMinutes()).slice(-2);
//   const seconds = ("0" + date.getSeconds()).slice(-2);
//   const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;};

export const alertaExclusao = (message, onConfirm) => {
  confirmAlert({
    title: 'Confirmar Exclusão', // Title dialog
    message, // Message dialog
    buttons: [{ label: 'Sim', onClick: () => onConfirm() }, { label: 'Cancelar' }],
    onConfirm: () => onConfirm(),
  });
};

export const capitalize = s => {
  if (typeof s !== 'string') return s;
  const splitStr = s.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const formatPhoneNumber = number => {
  if (number) {
    const justNumbers = number.replace(/\D/g, '');
    switch (justNumbers.length) {
      case 11:
        return `(${justNumbers.substring(0, 2)}) ${justNumbers.substring(2, 7)}-${justNumbers.substring(7, 11)}`;
      case 10:
        return `(${justNumbers.substring(0, 2)}) ${justNumbers.substring(2, 6)}-${justNumbers.substring(6, 10)}`;
      case 9:
        return `${justNumbers.substring(0, 5)}-${justNumbers.substring(5, 9)}`;
      case 8:
        return `${justNumbers.substring(0, 4)}-${justNumbers.substring(4, 8)}`;
      default:
        return justNumbers;
    }
  }
  return number;
};
