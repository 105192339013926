import React, { useState, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Form } from '@rocketseat/unform';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import Button from '../../components/Button';
import ReactSwitch from '../../components/ReactSwitch';
import api from '../../services/api';
import { ContainerForm, Header, InputResume, CustomDiv } from './styles';

function FormEtapas({ data: dados, setRefresh }) {
  const [data] = useState({ ...dados.dataLoad, permiteCoordenador: Boolean(dados.dataLoad.permiteCoordenador) });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async formData => {
      dispatch(toggleLoading(true));
      let save = {};
      if (!formData.id) {
        save = await api.post('etapa', {
          ...formData,
          dataCadastro: new Date(),
          permiteCoordenador: +formData.permiteCoordenador,
        });
      } else {
        save = await api.put('etapa', {
          ...formData,
          dataAlteracao: new Date(),
          permiteCoordenador: +formData.permiteCoordenador,
        });
      }

      const { status, message } = save.data;
      if (status) {
        enqueueSnackbar(message, { variant: 'success' });
        setRefresh(refresh => !refresh);
        dispatch(toggleModal(false, null, null, null));
      } else {
        enqueueSnackbar(message, { variant: 'error' });
      }
      dispatch(toggleLoading(false));
    },
    [dispatch, enqueueSnackbar, setRefresh],
  );

  return (
    <ContainerForm>
      <Header>
        <h1>Etapas</h1>
        <span>Administração de Etapas do Processo Seletivo</span>
      </Header>
      <Form onSubmit={handleSubmit} initialData={data}>
        <InputResume id="id" name="id" style={{ display: 'none' }} />
        <CustomDiv>
          <label htmlFor="nome">Nome da Etapa</label>
          <InputResume name="nome" id="nome" maxLength={100} />
        </CustomDiv>
        <CustomDiv>
          <label htmlFor="permiteCoordenador">Permitir Coordenador</label>
          <ReactSwitch name="permiteCoordenador" id="permiteCoordenador" textoLigado="Sim" textoDesligado="Não" />
        </CustomDiv>
        <Button handleClick={() => {}} text="Salvar" backColor="#5b5c65" color="#FFF" style={{ marginTop: '10px', right: '0' }} />
      </Form>
    </ContainerForm>
  );
}

FormEtapas.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default memo(FormEtapas);
