import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './configs/ReactotronConfig';

import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import pt from 'date-fns/locale/pt-BR';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';

import { theme } from './configs/Theme';
import Routes from './routes/index';
import history from './services/history';
import { store, persistor } from './store';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
        <SnackbarProvider maxSnack={4}>
          <DndProvider backend={HTML5Backend}>
            <MuiThemeProvider theme={theme}>
              <Router history={history}>
                <Routes />
              </Router>
              <ToastContainer />
            </MuiThemeProvider>
          </DndProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </PersistGate>
  </Provider>
);
export default App;
