/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@rocketseat/unform';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Container, ReactInputMasks } from './styles';

const ReactInputMask = ({ name, mask = 'DD/MM/YYYY', ...rest }) => {
	const ref = useRef(null);
	const { fieldName, registerField, defaultValue } = useField(name);
	const [value, setValue] = useState(defaultValue);

	const parseInputValue = () => new Date(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
	}, []);

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: ref.current,
			path: 'state.value',
			parseValue: parseInputValue,
		});
	}, [ref.current, fieldName]);

	return (
		<Container>
			<ReactInputMasks name={fieldName} value={value} mask={mask} ref={ref} {...rest} onChange={e => setValue(e.target.value)} />
		</Container>
	);
};

ReactInputMask.propTypes = {
	name: PropTypes.string.isRequired,
	mask: PropTypes.string,
};

ReactInputMask.defaultProps = {
	mask: 'DD/MM/YYYY',
};

export default ReactInputMask;
