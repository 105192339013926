import api from '~/services/api';

const mountOptionList = async (entity, setFunction) => {
  const { data } = await api.get(`${entity}`);

  if (data) {
    setFunction([]);
    if (data.data) {
      data.data.forEach(item =>
        setFunction(entities => [
          ...entities,
          { value: item.id, label: item.name ?? item.action ?? item.description, description: item.description },
        ]),
      );
    } else {
      data.forEach(item => setFunction(entities => [...entities, { id: item.id, title: item.nome ?? item.descricao }]));
    }
  }
};

export default mountOptionList;
