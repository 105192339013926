import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const theme = createMuiTheme({
  palette: {
    list: ['primary', 'secondary', 'error'],
    primary: {
      light: '#b1d34b',
      main: '#00995d',
      dark: '#0a5fdd',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#411564',
      main: '#0a5f55',
      dark: '#00401a',
      contrasText: '#ffffff',
    },
    // error: {
    // 	main: '#bb002f',
    // 	light: '#f9fbe7',
    // },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    useNextVariants: true,
  },
});

export const styles = themer => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [themer.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: '25px',
    backgroundColor: fade(themer.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(themer.palette.common.white, 0.25),
    },
    marginRight: themer.spacing(2),
    marginLeft: 0,
    width: '100%',
    [themer.breakpoints.up('sm')]: {
      marginLeft: themer.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: themer.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: themer.spacing(),
    paddingRight: themer.spacing(),
    paddingBottom: themer.spacing(),
    paddingLeft: themer.spacing(10),
    transition: themer.transitions.create('width'),
    width: '100%',
    [themer.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [themer.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});
