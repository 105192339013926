import { Link } from 'react-router-dom';

import styled from 'styled-components';

import DashLitleBackground from '../../assets/background-card-style.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;

  a {
    text-decoration: none;
    color: #333;
  }
`;

export const UpContainer = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 10px 0;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const SubUpContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(319px, 1fr));
  grid-template-rows: repeat(auto-fill);
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */

  @media (max-width: 1420px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: minmax(259px, 1fr);
    grid-template-rows: minmax(200px, auto) !important;
    grid-row-gap: 20px;
    padding: 0;
  }

  @media (max-height: 825px) {
    grid-template-rows: 250px 250px;
  }
`;

export const CardContainer = styled(Link)`
  text-decoration: none;

  a {
    text-decoration: none;
  }

  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  opacity: 1;
  background: url(${DashLitleBackground}) repeat scroll 90% 0% / cover;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 55px 20px 20px 20px;
  /* border: 2px solid rgb(0, 0, 0) !important; */
  border-image: initial;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  img {
    margin-bottom: 25px;
    width: 76px;
    height: 76px;
  }

  &:hover {
    /* border: 1px solid #b1d34b !important; */
    cursor: pointer;
    transform: translate3d(0, -1%, 0);
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  b {
    color: #b1d34b;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    padding: 30px !important;
  }

  span {
    font-size: 13px;
  }

  h6 {
    background: rgba(255, 255, 255, 0.5);
    padding: 2px 10px 5px 10px;
    border-radius: 4px;
  }

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const SetorNameContainer = styled.small`
  position: relative;
  margin-top: -40px;
  margin-bottom: 35px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  text-decoration: underline;
  text-decoration-color: rgba(255, 203, 8, 0.8);
  text-underline-position: under;
  display: flex;
  align-items: center;

  .badge-dark {
    position: absolute;
    right: 0;
    font-size: 15px;
    border-radius: 50% !important;
    padding: 8px !important;
    background: #411564;
    color: #ffcb08;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    margin-top: -20px;
  }
`;

export const NameContainer = styled.div`
  /* background: rgba(0, 0, 0, 0.4); */
  color: #411564;
  padding: 5px 10px 5px 0;
  border-radius: 5px;
`;

export const SemProcessosCadastradosContainer = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 10px 20px;
  text-align: justify;
`;
