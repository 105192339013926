/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React, { useState, useEffect, useRef, Fragment, memo, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdHistory, MdPrint } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, Input } from '@rocketseat/unform';
import { format, differenceInYears } from 'date-fns';
import { format as formatCPF } from 'gerador-validador-cpf';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { store } from '~/store';
import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import UserProfile from '../../../assets/user.png';
import Button from '../../../components/Button';
import ReactSelect from '../../../components/ReactSelect';
import UniBreakLines from '../../../components/UniBreakLines';
import { niveis as languageLevel } from '../../../configs/helpers/languagelevel';
import { niveis as skilllevel } from '../../../configs/helpers/skilllevel';
import * as routes from '../../../configs/Routes';
import { storage } from '../../../firebase';
import { getLocalDate, getUTCDate, formatPhoneNumber } from '../../../functions/curriculos';
import api from '../../../services/api';
import {
  Container,
  DashContainer,
  ConfigContainer,
  RightItemsContainer,
  ProcessListContainer,
  LoadingDataMessage,
} from './styles';

const AdminResumeViewer = ({ usuarioId }) => {
  const [dados, setDados] = useState([]);
  const [emailUsuario, setEmailUsuario] = useState('');
  const [etapasProcessoSeletivo, setEtapasProcessoSeletivo] = useState([]);
  const [existePreAnalise, setExistePreAnalise] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [processosAbertos, setProcessosAbertos] = useState([]);
  const [processoSeletivoId, setProcessoSeletivoId] = useState(null);
  const [showInserirProcesso, setShowInserirProcesso] = useState(false);
  const [showPreAnalise, setShowPreAnalise] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [tiposClassificacaoCurriculo, setTiposClassificacaoCurriculo] = useState([]);
  const printRef = useRef();
  const { profile: userData } = store.getState().user;

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (!usuarioId) return;

      try {
        setLoading(true);
        const resposta = await api.get(`userresumes?id=${usuarioId}`);
        const { data } = resposta;
        if (!data) return;
        const { resumeData, ...othersInfo } = data;
        const resumeObject = { ...resumeData, ...othersInfo };
        setDados(resumeObject);

        const {
          telefone1,
          usuario: { nome, email },
        } = resumeObject;

        const nomeDividido = nome.split(' ');
        setFirstName(nomeDividido[0]);
        nomeDividido.splice(0, 1);
        setLastName(nomeDividido.join(' '));
        setEmailUsuario(email);
        setTelefone(telefone1);
        const { data: preAnaliseExistente } = await api.get(`preanalisecurriculo/curriculo/${data.id}`);
        setExistePreAnalise(preAnaliseExistente && preAnaliseExistente.data && preAnaliseExistente.data.length > 0);
        try {
          const userImageUrl = await storage.doUserPhotoUrlLink(usuarioId);
          setImageUrl(userImageUrl || null);
        } catch (error) {
          // do nothing
        }
      } catch (err) {
        enqueueSnackbar(`Erro ao carregar dados do curriculo ${err.message}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [enqueueSnackbar, usuarioId]);

  useEffect(() => {
    const fecthData = async () => {
      const { data } = await api.get(`processoseletivo/active`);
      if (data.length === 0) return;
      data.forEach(item => {
        setProcessosAbertos(processo => [...processo, { id: item.id, title: item.nome }]);
      });
    };
    fecthData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get('tipoClassificacaoCurriculo');
      if (data.length === 0) return;
      data.forEach(item => [setTiposClassificacaoCurriculo(tipo => [...tipo, { id: item.id, title: item.nome }])]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!processoSeletivoId) return;
      const { data } = await api.get(`etapaprocessoseletivo/processoSeletivo/${processoSeletivoId}`);
      if (data.data.length === 0) return;
      data.data.forEach(item => setEtapasProcessoSeletivo(etp => [...etp, { id: item.id, title: item.etapa.nome }]));
    };
    fetchData();
  }, [processoSeletivoId]);

  const handleSubmit = useCallback(
    async (data, { resetForm }) => {
      dispatch(toggleLoading(true));
      const curriculoEtapaProcessoSeletivo = {
        curriculoId: data.id,
        processoSeletivoId: data.processoSeletivoId,
        etapaProcessoSeletivoId: data.etapaProcessoSeletivoId,
        dataCadastro: getLocalDate(),
        usuarioCadastro: userData.id,
      };

      const { data: retorno } = await api.post('curriculoetapaprocessoseletivo', curriculoEtapaProcessoSeletivo);
      if (retorno.status) {
        enqueueSnackbar(retorno.message, { variant: 'success' });
        resetForm();
        dispatch(toggleModal(false, null, null, null));
      } else {
        enqueueSnackbar(retorno.message, { variant: 'error' });
        dispatch(toggleModal(false, null, null, null));
      }
      dispatch(toggleLoading(false));
    },
    [dispatch, enqueueSnackbar, userData.id],
  );

  const hanldeSubmitPreAnalise = useCallback(
    async (data, { resetForm }) => {
      dispatch(toggleLoading(true));

      const preAnalise = {
        curriculoId: data.id,
        tipoClassificacaoCurriculoId: data.tipoClassificacaoCurriculoId,
        observacao: data.observacao,
        dataCadastro: getLocalDate(),
        usuarioCadastro: userData.id,
      };
      if (preAnalise.tipoClassificacaoCurriculoId === null) {
        dispatch(toggleLoading(false));

        return;
      }
      const { data: retorno } = await api.post('preanalisecurriculo', preAnalise);
      if (retorno.status) {
        resetForm();
        enqueueSnackbar(retorno.message, { variant: 'success' });
      } else {
        enqueueSnackbar(retorno.message, { variant: 'error' });
      }
      dispatch(toggleLoading(false));
    },
    [dispatch, enqueueSnackbar, userData.id],
  );

  const schemaPreAnalise = Yup.object().shape({
    id: Yup.number().required('Campo obrigatório'),
    tipoClassificacaoCurriculoId: Yup.number().required('Campo obrigatório'),
    observacao: Yup.string().required('Campo obrigatório'),
  });

  const schemaCurriculoEtapaProcessoSeletivo = Yup.object().shape({
    id: Yup.number().required('Campo obrigatório'),
    processoSeletivoId: Yup.number().required('Campo obrigatório'),
    etapaProcessoSeletivoId: Yup.number().required('Campo obrigatório'),
  });

  return (
    <DashContainer>
      {loading ? (
        <LoadingDataMessage>Carregando dados...</LoadingDataMessage>
      ) : (
        <>
          <Container ref={printRef}>
            <Scrollbars
              style={{ height: 'calc(100vh - 160px)', width: '100%' }}
              renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div style={{ paddingRight: '50px' }}>
                <div className="header">
                  <div className="full-name">
                    <span className="first-name">{firstName}</span>
                    <span className="last-name"> {lastName}</span>
                    <img className="user-photo" src={imageUrl || UserProfile} alt="Foto do Candidato" />
                  </div>

                  <div className="contact-info">
                    <span className="email">Email: </span>
                    <span className="email-val">{emailUsuario}</span>
                    <span className="separator" />
                    <span className="phone">Telefone: </span>
                    <span className="phone-val">{formatPhoneNumber(telefone)}</span>
                    <span className="separator" />
                    <span className="phone-val">{formatPhoneNumber(dados.telefone2)}</span>
                  </div>

                  <div className="contact-info">
                    <span className="email">CPF: </span>
                    <span className="email-">{dados.usuario && formatCPF(dados.usuario.cpf)}</span>
                    <span className="separator" />
                    <span className="email">RG: </span>
                    <span className="email-val">{dados.rg}</span>
                    <span className="separator" />
                    <span className="email">PIS/PASEP: </span>
                    <span className="email-val">{dados.pis}</span>
                    <span className="separator" />
                  </div>

                  <div className="contact-info">
                    <span className="phone">Idade: </span>
                    <span className="phone-val">{differenceInYears(new Date(), new Date(dados.dataNascimento))} anos</span>
                    <span className="separator" />
                    <span className="phone">Estado Civil: </span>
                    <span className="phone-val">{dados.estadoCivil && dados.estadoCivil.nome}</span>
                    <span className="separator" />
                    <span className="phone">Sexo: </span>
                    <span className="phone-val">{dados.sexo && dados.sexo.nome}</span>
                    <span className="separator" />
                    <span className="phone">Carteira de Habilitação: </span>
                    <span className="phone-val">{dados.cnh ? dados.cnh : 'Não possuo'}</span>
                  </div>

                  <div className="contact-info">
                    <span className="phone">Endereço: </span>
                    <span className="phone-val">
                      {dados.logradouro}, {dados.numero}, {dados.bairro} - {dados.cidade}.{dados.uf}
                    </span>
                  </div>

                  <div className="contact-info">
                    <span className="email">Possui Filhos: </span>
                    <span className="email-val">{dados.possuiFilhos ? 'Sim' : 'Não'}</span>
                    <span className="separator" />
                    <span className="email">Disponibilidade para Turnos: </span>
                    <span className="email-val">{dados.disponibilidadeHorarioTurno ? 'Sim' : 'Não'}</span>
                    <span className="separator" />
                    <span className="email">Pessoa Com Deficiência: </span>
                    <span className="email-val">{dados.portadorNecessidadeEspecial ? 'Sim' : 'Não'} </span>
                    {dados.portadorNecessidadeEspecial && <span className="email-val">CID: {dados.cidNecessidadeEspecial}</span>}
                    <span className="separator" />
                  </div>

                  <div className="contact-info">
                    <span className="email">Amigo ou Parente na empresa: </span>
                    <span className="email-val">{dados.possuiAmigoOuFamiliarNaEmpresa ? 'Sim' : 'Não'} </span>
                    <span className="email">
                      {dados.nomeAmigoOuFamiliarNaEmpresa} {dados.grauParentescoAmigoOuFamiliarNaEmpresa}
                    </span>
                    <span className="separator" />
                  </div>

                  <div className="contact-info">
                    <span className="email">Está trabalhando atualmente: </span>
                    <span className="email-val">{dados.estaTrabalhandoAtualmente ? 'Sim' : 'Não'}</span>
                    <span className="separator" />
                    <span className="email">Pretensão Salarial: </span>
                    <span className="email-val">
                      R$ {dados.pretensaoSalarial && dados.pretensaoSalarial.toString().replace('.', ',')}
                    </span>
                    <span className="separator" />
                  </div>
                  <div className="contact-info">
                    <span className="email">Escolaridade: </span>
                    <span className="email-val">{dados.escolaridade && dados.escolaridade.nome}</span>
                  </div>
                </div>

                <div className="details">
                  <div className="section">
                    <div className="section__title">Áreas de Interesse</div>

                    <div className="section__list">
                      {dados.setoresCurriculo &&
                        dados.setoresCurriculo.map(item => (
                          <Fragment key={item.id}>
                            <span>{item.setores.nome}</span>

                            <br />
                          </Fragment>
                        ))}
                    </div>
                  </div>

                  <div className="section">
                    {dados.historicoProfissional && dados.historicoProfissional.length > 0 && (
                      <>
                        <div className="section__title">Histórico Profissional</div>

                        <div className="section__list">
                          {dados.historicoProfissional.map(item => (
                            <div className="section__list-item" key={item.id}>
                              <div className="left">
                                <div className="name">{item.empresa}</div>

                                <div className="duration">
                                  {item.dataInicial && format(getUTCDate(item.dataInicial), 'MM/yyyy')} -{' '}
                                  {item.dataFinal && format(getUTCDate(item.dataFinal), 'MM/yyyy')}
                                </div>

                                <div className="addr">
                                  <UniBreakLines text={item.descricaoAtividades} />
                                </div>
                              </div>

                              <div className="right">
                                <div className="name">{item.cargo}</div>

                                <div className="desc">{item.setor}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoEducacional && dados.historicoEducacional.length > 0 && (
                      <>
                        <div className="section__title">Histórico Educacional</div>

                        <div className="section__list">
                          {dados.historicoEducacional.map(item => (
                            <div className="section__list-item" key={item.id}>
                              <div className="left">
                                <div className="name">{item.nomeInstituicao}</div>

                                <div className="addr">{item.cidade}</div>

                                <div className="duration">{item.descricao}</div>
                              </div>

                              <div className="right">
                                <div className="name">{item.graduacao && item.graduacao.nome}</div>

                                <div className="desc">
                                  {format(getUTCDate(item.inicioGraduacao), 'MM/yyyy')} -{' '}
                                  {format(getUTCDate(item.fimGraduacao), 'MM/yyyy')}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoCursos && dados.historicoCursos.length > 0 && (
                      <>
                        <div className="section__title">Histórico de Cursos</div>

                        <div className="section__list">
                          {dados.historicoCursos &&
                            dados.historicoCursos.map(item => (
                              <div className="section__list-item" key={item.id}>
                                <div className="left">
                                  <div className="name">{item.instituicao}</div>

                                  <div className="duration">{item.descricao}</div>
                                </div>

                                <div className="right">
                                  <div className="name">{item.nome}</div>

                                  <div className="desc">
                                    {format(getUTCDate(item.dataInicio), 'MM/yyyy')} -{' '}
                                    {format(getUTCDate(item.dataFim), 'MM/yyyy')}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoHabilidades && dados.historicoHabilidades.length > 0 && (
                      <>
                        <div className="section__title">Habilidades</div>

                        <div className="skills">
                          {dados.historicoHabilidades &&
                            dados.historicoHabilidades.map(item => {
                              const level = skilllevel.filter(skill => skill.value === item.nivel);

                              return (
                                <div className="skills__item" key={item.id}>
                                  <div className="left">
                                    <div className="section__list">
                                      {item.nome} em nível {level[0] && level[0].label}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoIdiomas && dados.historicoIdiomas.length > 0 && (
                      <>
                        <div className="section__title">Idiomas</div>

                        <div className="section__list">
                          {dados.historicoIdiomas &&
                            dados.historicoIdiomas.map(item => {
                              const level = languageLevel.filter(lang => lang.value === item.nivel);

                              return (
                                <div className="section__list-item" key={item.id}>
                                  {item.nome} nível {level[0] && level[0].label}
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </Container>
          <RightItemsContainer>
            <Link
              className="action-buttons"
              to={{
                pathname: `${routes.ADMIN_RESUME_VIEWER}/?id=${usuarioId}`,
                state: { userId: usuarioId },
              }}
              target="_blank"
            >
              <MdPrint size={24} className="print-button" />
            </Link>
            <ConfigContainer showInserirProcesso={showInserirProcesso}>
              <h4 onClick={() => setShowInserirProcesso(pre => !pre)}>
                Inserir em um Processo Seletivo
                {showInserirProcesso ? (
                  <MdKeyboardArrowUp size={24} style={{ maringTop: '15px' }} />
                ) : (
                  <MdKeyboardArrowDown size={24} style={{ marginTop: '5px' }} />
                )}
              </h4>
              <Form initialData={dados} onSubmit={handleSubmit} schema={schemaCurriculoEtapaProcessoSeletivo}>
                <Input id="id" name="id" style={{ display: 'none' }} />
                <ReactSelect
                  options={processosAbertos}
                  multiple={false}
                  name="processoSeletivoId"
                  label="Processo Seletivo"
                  closeOnSelect
                  fieldPointer="processoSeletivoId"
                  getValue={setProcessoSeletivoId}
                />
                <br />
                {etapasProcessoSeletivo.length > 0 && (
                  <>
                    <ReactSelect
                      options={etapasProcessoSeletivo}
                      multiple={false}
                      name="etapaProcessoSeletivoId"
                      label="Etapa do Processo Seletivo"
                      closeOnSelect
                      fieldPointer="etapaProcessoSeletivoId"
                    />
                    <br />
                    <Button text="Inserir no processo" handleClick={() => {}} className="button-insert" />
                  </>
                )}
              </Form>
            </ConfigContainer>
            <ProcessListContainer showPreAnalise={showPreAnalise}>
              <h4 onClick={() => setShowPreAnalise(pre => !pre)}>
                Pré Análise Curricular{' '}
                {existePreAnalise && <MdHistory size={24} style={{ color: '#f47920', marginTop: '5px' }} />}
                {showPreAnalise ? (
                  <MdKeyboardArrowUp size={24} style={{ maringTop: '15px' }} />
                ) : (
                  <MdKeyboardArrowDown size={24} style={{ marginTop: '5px' }} />
                )}
              </h4>
              <Form initialData={dados} onSubmit={hanldeSubmitPreAnalise} schema={schemaPreAnalise}>
                <Input id="id" name="id" style={{ display: 'none' }} />
                <ReactSelect
                  options={tiposClassificacaoCurriculo}
                  multiple={false}
                  name="tipoClassificacaoCurriculoId"
                  label="Pré Classificação do Currículo"
                  closeOnSelect
                  fieldPointer="tipoClassificacaoCurriculoId"
                />
                <Input id="observacao" name="observacao" multiline rows={4} placeholder="Observação" />
                <Button text="Registrar Pré Análise" handleClick={() => {}} className="button-insert" />
              </Form>
            </ProcessListContainer>
          </RightItemsContainer>
        </>
      )}
    </DashContainer>
  );
};

AdminResumeViewer.propTypes = {
  usuarioId: PropTypes.number.isRequired,
};

export default memo(AdminResumeViewer);
