import styled from 'styled-components';

export const Container = styled.div`
  .header {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 18px;
    background: #00995d;
    color: #fff;
    border-radius: 3px;
    margin-bottom: 3px;
  }

  .events-container {
    background: #fff;
    height: 56vh;
    list-style: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    li {
      list-style: none;
      background: #eee;
      margin-top: 3px;
      display: flex;
      flex-direction: column;
      padding: 5px 10px;
      border-left: 4px solid #411564;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      .applicant-name {
        font-weight: bold;
        color: #333;
      }

      .scheduler-date {
        color: #5b5c65;
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 2px;
      }
    }
  }
`;

export const LoadContainer = styled.div`
  font-size: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #411564;
  animation: blinker 1s linear infinite;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export const NoSchedule = styled.div`
  font-size: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #411564;
`;
