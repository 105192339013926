import React, { useState, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { MdEdit } from 'react-icons/md';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';

import { Form } from '@rocketseat/unform';

import { toggleLoading, setCurrentPage } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import ReactSwitch from '../../components/ReactSwitch';
import SearchBox from '../../components/SearchBox';
import api from '../../services/api';
import FormUsuarios from './form';
import { Container, FilterContainer } from './styles';

function Usuarios() {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = parseInt(global.window.innerHeight - 208) / 54;
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const iconStyles = {
    marginLeft: 7,
    cursor: 'pointer',
  };

  const handlePageChange = useCallback(pageNumber => {
    setActivePage(pageNumber);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage('Usuários'));
  }, [dispatch]);

  const fetchData = useCallback(async () => {
    dispatch(toggleLoading(true));
    const { data } = await api.get(`usuarios/${itemsPerPage}/${parseInt((activePage - 1) * itemsPerPage)}/${searchTerm}`);
    const { data: dados, total: qte } = data;
    if (dados) {
      setTotal(qte);
      setUsuarios(dados);
    }
    dispatch(toggleLoading(false));
  }, [activePage, dispatch, itemsPerPage, searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refresh]);

  const showForm = useCallback(
    dataLoad => {
      dispatch(
        toggleModal(true, <FormUsuarios close={toggleModal} data={{ dataLoad }} setRefresh={setRefresh} />, 'usuarios', ''),
      );
    },
    [dispatch],
  );

  const search = useCallback(value => {
    if (value.trim() !== '') {
      setUsuarios([]);
      setActivePage(1);
      setSearchTerm(value.toLowerCase());
    } else {
      setSearchTerm('');
    }
  }, []);

  return (
    <Container>
      <FilterContainer>
        <SearchBox onSearch={search} />
      </FilterContainer>
      <div className="table-responsive">
        <table className="table-outline mb-0 d-sm-table table table-hover">
          <thead className="thead-light">
            <tr>
              <th width={isMobile ? '90%' : '33%'}>Nome</th>
              {!isMobile && (
                <>
                  <th width="15%" style={{ textAlign: 'center' }}>
                    É funcionário
                  </th>
                  <th width="15%" style={{ textAlign: 'center' }}>
                    É Coordenador
                  </th>
                  <th>Grupo de Usuário</th>
                </>
              )}
              <th className="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            {usuarios &&
              usuarios.map(item => (
                <tr key={item.id} style={{ backgroundColor: item.eCoordenador ? '#c4cbcf' : '#fff' }}>
                  <td style={{ width: '40%' }}>
                    <div>{item.nome?.toUpperCase()}</div>
                    <div className="small text-muted">
                      <small>Id: {item.id} |</small>
                      <small> Email: {item.email}</small>
                    </div>
                  </td>
                  {!isMobile && (
                    <>
                      <td
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Form initialData={item}>
                          <ReactSwitch name="eFuncionario" id="eFuncionario" textoLigado="Sim" textoDesligado="Não" disabled />
                        </Form>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Form initialData={item}>
                          <ReactSwitch name="eCoordenador" id="eCoordenador" textoLigado="Sim" textoDesligado="Não" disabled />
                        </Form>
                      </td>
                      <td>{item.tipoUsuario.nome}</td>
                    </>
                  )}
                  <td>
                    <div className="btn-group" style={{ float: 'right' }}>
                      <MdEdit size={24} color="#5b5c65" style={iconStyles} onClick={() => showForm(item)} />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={total}
          pageRangeDisplayed={isMobile ? 3 : 10}
          onChange={handlePageChange}
          prevPageText="Anterior"
          nextPageText="Próxima"
          firstPageText="Primeira"
          lastPageText="Última"
        />
      </div>
    </Container>
  );
}

export default Usuarios;
