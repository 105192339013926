/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect, memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile } from 'react-device-detect';

import PropTypes from 'prop-types';

import api from '../../services/api';
import { Container, Header, DetailsContainer } from './styles';

const JobDescription = ({ job }) => {
  const [description, setDescription] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(`processoseletivo/jobdescription`, {
        headers: {
          id: job,
        },
      });
      const { data: dados } = data;
      if (!dados || dados.length === 0) return;
      setDescription(dados);
    };
    fetchData();
  }, [job]);

  return (
    <Container>
      {description.setor && (
        <>
          <Header>
            <h1>{description.nome}</h1>
            <span>{description.setor.nome}</span>
            <h6>{description.quantidade} vaga(s)</h6>
          </Header>
          <Scrollbars
            style={{ height: isMobile ? 'calc(100vh - 250px)' : 'calc(98% - 160px)', width: '100%' }}
            renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
          >
            <DetailsContainer>
              <h4>Perfil</h4>
              {description.requisitosMinimos && (
                <>
                  <h5>Requisitos mínimos</h5>
                  <p>{description.requisitosMinimos}</p>
                </>
              )}
              {description.experienciaDesejavel && (
                <>
                  <h5>Experiência desejável</h5>
                  <p>{description.experienciaDesejavel}</p>
                </>
              )}
              {description.conhecimentoDesejavel && (
                <>
                  <h5>Conhecimentos desejáveis</h5>
                  <p>{description.conhecimentoDesejavel}</p>
                </>
              )}
              {description.complementaPerfil && (
                <>
                  <h5>Complementa o perfil</h5>
                  <p>{description.complementaPerfil}</p>
                </>
              )}
              {description.descricaoCargo && (
                <>
                  <h5>Descrição das atividades</h5>
                  <p>{description.descricaoCargo}</p>
                </>
              )}
              {description.beneficios && (
                <>
                  <h5>Benefícios</h5>
                  <p>{description.beneficios}</p>
                </>
              )}
              {description.horario && (
                <>
                  <h5>Horário</h5>
                  <p>{description.horario}</p>
                </>
              )}
            </DetailsContainer>
          </Scrollbars>
        </>
      )}
    </Container>
  );
};

JobDescription.propTypes = {
  job: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

export default memo(JobDescription);
