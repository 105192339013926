import { Input, Select } from '@rocketseat/unform';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  margin-top: -20px;

  @media (max-width: 768px) {
    padding: 0;
  }

  .btn-group {
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }

  .bank-username {
    font-size: 0.9rem;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 0.75rem;
      text-overflow: ellipsis;
    }
  }

  .text-muted {
    font-size: 0.9rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  .user-sector {
    border-bottom: 1px dotted #f47920;
    color: #222;
    margin-right: 7px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }

  a.action-buttons {
    margin-left: 15px;
    color: #666;
  }

  .user-data-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: start;
  }

  .user-data-region {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0;
    text-align: left;
    padding-left: 10px;
  }

  .candidate-image {
    height: 69px;
    min-width: 69px;
    max-width: 69px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      border-radius: 5%;
      cursor: pointer !important;
      border: 4px solid #fff;
    }
  }
`;

export const ModalContainer = styled.div`
  max-width: 920px;
  width: 80%;
  height: auto;
  font-size: 16px;
  background-color: #411564;
  padding: 30px 20px 25px 20px;
  margin-bottom: 10px;
  z-index: 999;
  position: fixed;
  visibility: ${props => (props.showFilter ? 'visible' : 'hidden')};
  transition: all 0s linear 0.33s, opacity 0.33s linear;
  opacity: ${props => (props.showFilter ? 1 : 0)};
  ${props => (props.showFilter ? 'transition-delay: 0s;' : '')};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 7px 9px 19px -1px rgba(0, 0, 0, 0.85);
  border-radius: 3px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: scroll;
  }
`;

export const ListContainer = styled.div`
  color: #333;
  width: 100%;
  max-width: 1366px;

  /* @media (max-width: 1380px) {
    padding: 0 30px;
  } */

  @media (max-width: 1080px) {
    padding: 0;
  }

  span.trabalhando {
    border-top: 3px solid #00995d;
    color: #00995d;
    background: rgba(0, 153, 93, 0.2);
    font-weight: bold;
    padding: 2px 5px 4px 5px;
    margin-left: 8px;
  }

  span.desempregado {
    border-top: 3px solid #ed1651;
    color: #ed1651;
    background: rgba(237, 22, 81, 0.2);
    font-weight: bold;
    padding: 2px 5px 4px 5px;
    margin-left: 8px;
  }

  span.participou {
    border-top: 3px solid #f47920;
    color: #f47920;
    background: rgba(244, 121, 32, 0.2);
    font-weight: bolder;
    padding: 2px 5px 4px 5px;
  }

  span.nao-participou {
    border-top: 3px solid #c4cbcf;
    color: #c4cbcf;
    background: #eee;
    padding: 2px 5px 4px 5px;
  }

  tr {
    margin-top: 2px !important;
    border-bottom: 1px solid #fff !important;
  }

  td {
    padding: 0 10px 0 0 !important;
  }

  div.table-responsive {
    border-radius: none !important;
  }

  span.indicacao-trabalhador {
    border-top: 3px solid #ffcb08;
    color: #ffcb08;
    background: rgba(255, 203, 8, 0.2);
    font-weight: bold;
    padding: 2px 5px 4px 5px;
    margin-left: 8px;
  }

  span.indicacao-cooperado {
    border-top: 3px solid #411564;
    color: #411564;
    background: rgba(65, 21, 100, 0.2);
    font-weight: bold;
    padding: 2px 5px 4px 5px;
    margin-left: 8px;
  }

  .tiny-screen {
    @media (max-width: 1280px) {
      display: none;
    }
  }

  .mobile-hidden {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-resize {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ButtonShowFilter = styled.button`
  background: #ffcb08;
  color: #411564;
  border: none;
  width: 50px;
  height: 50px;
  padding: 5px 10px;
  position: fixed;
  z-index: 1000;
  border-radius: 50px;
  bottom: 6px;
  right: 25px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    right: 15px;
    bottom: 6px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  input#Pesquisar {
    color: #fff !important;
  }

  .item {
    top: -5px;
    margin-left: 110px;
    color: #333;
  }

  label {
    position: absolute;
    color: #fff !important;
    margin-top: 8px !important;
    margin-left: 10px !important;
    vertical-align: center;

    @media (max-width: 768px) {
      margin-top: 3px !important;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > div {
      margin-bottom: 15px;
    }

    .item {
      margin-top: 30px;
      margin-left: 0;
    }

    label {
      /* margin-left: 0 !important; */
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-flow: column;
  /* This aligns items to the end line on main-axis */
  justify-content: space-between;
  margin-top: -15px;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 0;
  }

  div.separator-div {
    display: flex;
    flex-direction: row wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .item {
    margin-top: -7px;
    margin-right: 5px;
    color: #333;

    label {
      color: #fff !important;
    }
  }

  .item-button {
    display: flex;
    /* margin-top: 29px !important; */
    align-content: right;
    align-items: right;
    justify-content: flex-end;
    margin: 10px 5px 0 0;

    @media (max-width: 768px) {
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0;

      div {
        width: 100%;
        margin-bottom: 20px;
      }

      button {
        padding: 0;
        margin-left: 0 !important;
        width: 100%;
      }
    }

    button {
      font-size: 18px !important;
      padding: 5px 65px !important;
      margin-left: 10px;
    }
  }
`;

export const HeaderFilter = styled.div`
  button {
    border: none;
    float: right;
  }
`;

export const ContainerForm = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  form {
    padding: 20px;
  }
`;

export const HeaderForm = styled.div`
  background: #411564;
  padding: 20px;
  color: #b1d34b;

  span {
    color: #fff;
  }
`;

export const CustomDiv = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #efefef;
  height: 100%;
  overflow-y: scroll;

  .pre-analise {
    margin-left: 20px;
  }
`;

export const NoteContainer = styled.div`
  border-left: 4px solid #00995d;
  background-color: #fff;
  padding: 15px;
  color: #000;
  margin-bottom: 15px;

  span {
    font-size: 18px;
  }

  p {
    color: #999;
    font-size: 13px;
  }
`;

export const HistoryTitle = styled.h4`
  /* margin-left: 15px; */
`;

export const InputResume = styled(Input)`
  width: 100%;
  display: block;
  caret-color: rgb(33, 150, 243);
  background-color: #fff;
  color: rgb(38, 43, 51);
  pointer-events: unset;
  border-radius: 3px;
  padding: 11px 16px;
  outline: none;
  border-width: 0px;
  border: 1px solid #aaa;
  transition: color 0.1s ease 0s;
  margin-bottom: 15px;

  &:focus {
    border: 2px dashed #f47920;
  }
`;

export const SelectResume = styled(Select)`
  width: 100%;
  display: block;
  caret-color: rgb(33, 150, 243);
  background-color: rgb(242, 245, 250);
  color: rgb(38, 43, 51);
  pointer-events: unset;
  border-radius: 3px;
  padding: 11px 16px;
  outline: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: color 0.1s ease 0s;
`;
