import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '@rocketseat/unform';
import { format as formatCPF, validate } from 'gerador-validador-cpf';
import * as Yup from 'yup';

import checkUserByCpf from '~/functions/checkUserByCpf';
import history from '~/services/history';

import logo from '../../assets/logo.png';
import Input from '../../components/ReactInput';
import { auth } from '../../firebase';
import { Container } from './styles';

function Recovery() {
  const onSubmit = useCallback(async data => {
    try {
      if (data.cpf !== '') {
        const responseCpfCheck = await checkUserByCpf(data.cpf);
        if (responseCpfCheck) {
          await auth.doPasswordReset(responseCpfCheck.email);
          toast.info(
            `Você receberá um e-mail com o link para a troca da sua senha no email: ${responseCpfCheck.email}.  Verifique se o e-mail não foi para sua caixa de spam ou lixeira`,
          );
          history.push('/');
        }
      } else if (data.emailRecuperacao) {
        await auth.doPasswordReset(data.emailRecuperacao);
        toast.info(
          'Você receberá um e-mail com o link para a troca da sua senha. Verifique se o e-mail não foi para sua caixa de spam ou lixeira.',
        );
        history.push('/');
      }
    } catch (error) {
      toast.error(`Não foi possível recuperar sua senha. Verifique seu e-mail e tente novamente. ${error.message}`);
    }
  }, []);

  const schemaRecovery = Yup.object().shape({
    emailRecuperacao: Yup.string().email('Email digitado não é válido, verifique!'),
    cpf: Yup.string()
      .test('e-formatavel', 'CPF Inválido', value => formatCPF(value) || !value)
      .test('e-valido', 'CPF Inválido', value => validate(value) || !value),
  });

  return (
    <Container>
      <div className="containerFields">
        <img src={logo} className="logo" alt="Unimed" />

        <Form className="login" onSubmit={onSubmit} schema={schemaRecovery}>
          <div className="email-recuperacao">
            <Input type="mail" name="emailRecuperacao" placeholder="Email" />
            <p>ou</p>
            <Input name="cpf" placeholder="CPF" />
          </div>
          <div className="submit">
            <button className="dark" type="submit">
              Recuperar
            </button>
          </div>
          <h6>
            <Link className="link" to="/">
              Voltar
            </Link>
          </h6>
        </Form>
      </div>
    </Container>
  );
}

export default memo(Recovery);
