import { Input } from '@rocketseat/unform';
import styled from 'styled-components';

export const CustomInput = styled(Input)`
  label {
    text-align: left;
  }

  width: 100%;
  display: block;
  caret-color: rgb(177, 211, 75);
  background-color: rgb(235, 235, 235);
  color: rgb(38, 43, 51);
  pointer-events: unset;
  border-radius: 3px;
  padding: 11px 16px;
  outline: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: color 0.1s ease 0s;
`;
