/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef, useContext, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDrop } from 'react-dnd';
import { MdAdd } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { toggleModal } from '~/store/modules/modal/actions';

import ProcessoSeletivo from '../../pages/ProcessoSeletivo';
import BoardContext from '../../pages/Vagas/context';
import Card from '../BoardCard';
import { Container } from './styles';

const BoardList = ({ data, index: listIndex, listId }) => {
  const ref = useRef();
  const { move, setores, setRefresh, coordenadores, etapas, caracteristicas } = useContext(BoardContext);
  const dispatch = useDispatch();

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {
      const draggedListIndex = item.listIndex;
      const targetListIndex = listIndex;

      if (draggedListIndex === targetListIndex) return;

      move(draggedListIndex, targetListIndex, item.index, item.index, listId, data[listIndex]);

      item.listIndex = targetListIndex;
    },
  });

  dropRef(ref);

  const showForm = useCallback(
    dataLoad => {
      dispatch(
        toggleModal(
          true,
          <ProcessoSeletivo
            close={toggleModal}
            data={{ dataLoad, setores, coordenadores, etapas, caracteristicas }}
            setRefresh={setRefresh}
          />,
          'processo-seletivo',
          '',
        ),
      );
    },
    [caracteristicas, coordenadores, dispatch, etapas, setRefresh, setores],
  );

  return (
    <Container done={data.done} ref={ref}>
      <header>
        <h2>{data.name}</h2>
        {data.creatable && (
          <>
            <button type="button" onClick={() => showForm({})}>
              <MdAdd size={24} color="#fff" />
            </button>
          </>
        )}
      </header>
      <ul>
        <Scrollbars
          style={{ height: '100%', width: '103%' }}
          autoHide
          renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
        >
          {data.processoSeletivo &&
            data.processoSeletivo.map(
              (card, index) =>
                card && (
                  <Card
                    key={card.id}
                    data={card}
                    edit={showForm}
                    index={index}
                    listIndex={listIndex}
                    cardId={card.id}
                    listId={listId}
                    setRefresh={setRefresh}
                  />
                ),
            )}
        </Scrollbars>
      </ul>
    </Container>
  );
};

BoardList.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  listId: PropTypes.number.isRequired,
};

export default BoardList;
