import React from 'react';

import { TextContainer } from '../styles';

function About() {
  return (
    <div>
      <h1>#Unijob</h1>
      <TextContainer>
        <p>
          Com o intuito de tornar mais ágil a captação de currículos, criamos o #Unijob, que estreitou o contato do candidato com
          nosso RH.
        </p>
        <p>Mantendo seu currículo atualizado você aumenta as suas chances de ser chamado para nossos processos seletivos.</p>
        <h5>Novidades</h5>
        <ul>
          <li>Agora salvamos a foto do seu perfil</li>
          <li>Envio de e-mail de notificação quando uma nova vaga é cadastrada</li>
          <li>Envio de e-mail de notificação quando um novo usuário é cadastrado</li>
          <li>Verificação dos campos obrigatórios no formulário do currículo</li>
          <li>Informação do percentual de campos obrigatórios preenchidos</li>
          <li>Possibilidade de trocar o e-mail cadastrado</li>
          <li>
            O sistema salva os dados do currículo automaticamente, após 3 segundos, o sistema chama o processo para salvar os
            dados
          </li>
        </ul>
      </TextContainer>
    </div>
  );
}

export default About;
