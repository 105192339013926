import * as Yup from 'yup';

export const SchemaAvaliacao = Yup.object().shape({
  id: Yup.number(),
  curriculoId: Yup.number(),
  etapaProcessoSeletivoId: Yup.number(),
  processoSeletivoId: Yup.number(),
  horarioAgendamento: Yup.date().required('O campo data de agendamento é obrigatório'),
  observacao: Yup.string(),
  pontuacaoProcessoSeletivo: Yup.array().of(
    Yup.object().shape({
      curriculoId: Yup.number().integer(),
      etapaProcessoSeletivoId: Yup.number(),
      processoSeletivoId: Yup.number('Este campo precisa ser numérico'),
      caracteristicaEtapaProcessoId: Yup.number().integer(),
      pontuacao: Yup.number().required('É obrigatório escolher uma pontuação'),
    }),
  ),
});

export const SchemaDesclassificacao = Yup.object().shape({
  id: Yup.number(),
  tipoClassificacaoCurriculoId: Yup.number('Escolha um motivo!').required('É necessário escolher o motivo da desclassificação'),
});
