import styled from 'styled-components';

export const Container = styled.div`
	background-color: rgb(242, 245, 250);
	display: block;
	margin: 5px 0;
	position: relative;
	font-size: 18px;

	label {
		padding: 6px 30px;
		width: 100%;
		display: block;
		text-align: left;
		color: #3c454c;
		cursor: pointer;
		position: relative;
		z-index: 2;
		transition: color 200ms ease-in;
		overflow: hidden;

		&:before {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			content: '';
			background-color: #411564;
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
			transform: translate(-50%, -50%) scale3d(1, 1, 1);
			transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
			opacity: 0;
			z-index: -1;
		}

		&:after {
			width: 32px;
			height: 32px;
			content: '';
			border: 2px solid #d1d7dc;
			background-color: #fff;
			background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
			background-repeat: no-repeat;
			background-position: 2px 3px;
			border-radius: 50%;
			z-index: 2;
			position: absolute;
			right: 30px;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			cursor: pointer;
			transition: all 200ms ease-in;
		}
	}

	input {
		width: 32px;
		height: 32px;
		order: 1;
		z-index: 2;
		position: absolute;
		right: 30px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		cursor: pointer;
		visibility: hidden;

		&:checked {
			~ label {
				color: #fff;
				&:before {
					-webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
					transform: translate(-50%, -50%) scale3d(56, 56, 1);
					opacity: 1;
				}

				&:after {
					background-color: #f47920;
					border-color: #f47920;
				}
			}
		}
	}
`;
