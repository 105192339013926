import { Input, Select } from '@rocketseat/unform';
import styled from 'styled-components';

import BorderDash from '../../assets/border-dash.svg';

export const Container = styled.div`
  transform-origin: center top;
  transition: transform 0.4s ease 0s;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 50%;
  min-height: 100vh;

  h5 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
  }

  .curriculoSaveButton {
    display: none;
  }

  @media (min-width: 1280px) {
    max-width: 960px;
  }

  @media (max-width: 768px) {
    width: 100%;

    .curriculoSaveButton {
      right: 5%;
      display: none;
    }

    .row-fields {
      display: grid !important;
      grid-template-columns: 1fr !important;
    }

    .column-field-double {
      display: block !important;
    }
  }
`;

export const Resume = styled.div`
  margin: -20px 0 0 0;
  width: 100%;
  color: #333;
  background-color: rgb(255, 255, 255);

  @media (max-width: 768px) {
    flex: 1 1 0%;

    /* margin: 0 0 0 -15px; */
  }

  span:not([class]) {
    color: #de3a11;
    background: #ffe3e3;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    font-weight: bolder;
    align-items: center;
    border-radius: 5px;
    display: inline-block;
  }

  div.subContainer {
    max-width: 860px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    flex: 1 1 0%;
    overflow: auto;
    margin: auto;
  }

  div.subSubContainer {
    overflow-x: hidden;
    padding: 48px;

    @media (max-width: 768px) {
      padding: 20px 40px 20px 40px;
    }
  }

  div.titulo {
    font-size: 32px;
    line-height: 34px;
    text-align: center;
    position: relative;
    margin-bottom: 40px;
    padding: 0px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.row-fields {
    /* margin-bottom: 24px; */
    display: flex;
    flex-flow: row wrap;

    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }

  div.column-field {
    margin-right: 40px;
    flex: 0 0 calc(50% - 20px);

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  div.column-field-double {
    width: calc(50% - 20px);
    margin-right: 40px;
    flex: 0 0 calc(50% - 20px);
    display: flex;
    flex-flow: row wrap;

    .sub-column-field {
      &:first-child {
        display: flex;
        align-items: flex-end;
        flex: 1 1 0%;
        margin-right: 8px;

        @media (max-width: 768px) {
          margin-right: 0 !important;
          display: block;
        }
      }
      &:last-child {
        display: flex;
        align-items: flex-end;
        flex: 1 1 0%;

        @media (max-width: 768px) {
          margin-right: 0 !important;
          display: block;
        }
      }
    }

    @media (max-width: 768px) {
      display: block !important;
      width: 100%;
    }
  }

  div.field-container {
    display: block;
    position: relative;
  }

  div.field-sub-container {
    display: block;

    label {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      color: rgb(152, 161, 179);
      margin-bottom: 6px;
      font-weight: 300;
    }

    div.input-container {
      display: block;
      position: relative;

      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
  }

  div.photo-preview-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    margin-bottom: 24px;
  }

  div.photo-preview {
    /* background: #00995d; */
    color: #ffffff;
    padding: 10px;
    height: 120px;
    width: 120px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      border-radius: 5%;
      cursor: pointer !important;
    }
  }

  div.button-photo-config {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #c4cbcf;
    padding: 10px 0;

    label {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      color: rgb(152, 161, 179);
      margin-bottom: 6px;
      margin-left: 10px;
      font-weight: 300;
    }

    button {
      border: none;
      background: none;
      color: #c4cbcf;

      font-size: 16px;
      line-height: 20px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      flex-flow: row nowrap;
      transition: color 0.1s ease 0s;
      padding: 2px 0px;
    }

    button.edit {
      &:hover {
        color: #00995d;
      }
    }

    button.remove {
      &:hover {
        color: #ed1651;
      }
    }
  }
`;

export const Preview = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 50%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  z-index: 3;
  flex: 1 1 0%;
  flex-flow: column nowrap;
  background: rgb(122, 133, 153) !important;

  @media (min-width: 1920px) {
    width: calc(100% - 960px);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DocumentContainer = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  top: 100px;
  bottom: 0px;
  left: 60px;
  right: 60px;
`;

export const SubDocumentContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const DocumentHeader = styled.div`
  position: absolute;
  top: 0px;
  display: flex;
  height: 40px;
  padding: 5px 1px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: calc(50vw - 20px);
  max-width: 600px;

  .rotate {
    animation: loading 0.6s linear infinite;
    @keyframes loading {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0);
      }
    }
  }

  div.saved {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    padding: 5px 0;

    span {
      position: relative;
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      margin-left: 10px;
    }
  }

  div.print {
    padding: 5px 0;
    a {
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      text-decoration: underline;
      text-decoration-color: rgba(255, 203, 8, 0.2);
      text-underline-position: under;

      &:hover {
        color: #ffcb08;
        text-decoration: underline;
        text-decoration-color: rgba(255, 203, 8, 0.8);
        text-underline-position: under;
      }
    }
  }
`;

export const Document = styled.div`
  padding: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 15px;
  margin-top: 35px;
`;

export const DocumentPosition = styled.div`
	background-color: rgb(255, 255, 255) !important;
	padding: 0 !important;
	border-radius: 6px;
	overflow: hidden;
	width: calc(50vw - 20px);
	height: calc(100vh - 180px);
	max-width: 600px;
	max-height: 828px;

	iframe {
		border-radius: 15px;
		width: 950px;
		height: 2540px;
		border: none;
		pointer-events: none;
		transform-origin: 0px 0px;
		background-color: rgb(255, 255, 255);
		/* transform: scale(0.540667, 0.536667); */
		/* transform: ${props =>
      `scale(calc(${props.largura - props.largura / 4.9} / ${props.altura}), calc(${props.largura + 120} / ${props.altura}))`}; */
		transform: ${props => `scale(${props.largura / 720}, ${props.largura / 720 - 0.07})`};
		will-change: transform;
		vertical-align: middle;
	}
`;

export const ButtonAdd = styled.div`
	font-size: 16px;
	line-height: 20px;
	display: flex;
	width: 100%;
	height: 36px;
	position: relative;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
	color: #f47920;
	cursor: pointer;
	outline: none;
	padding: 15px;
  font-weight: 500;
  margin-bottom: 40px;
  text-decoration: underline;
  text-decoration-color: #f47920;
  text-underline-position: under;
  transition: color 0.3s ease 0s;


  &:hover {
    color: #ffcb08;
    text-decoration: underline;
    text-decoration-color: rgba(255, 203, 8, 0.8);
    text-underline-position: under;
  }

	/* &:before {
		content: '';
		position: absolute;
		display: block;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		border-style: solid;
		border-width: 8px;
		border-image: url(${BorderDash}) 8 / 1 / 0 round;
	} */
`;

export const ItemDescription = styled.div`
  margin-bottom: 25px;
  color: #aaa;
`;

export const InputResume = styled(Input)`
  width: 100%;
  display: block;
  caret-color: rgb(33, 150, 243);
  background-color: rgb(242, 245, 250);
  color: rgb(38, 43, 51);
  pointer-events: unset;
  border-radius: 3px;
  padding: 11px 16px;
  outline: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: color 0.1s ease 0s;
  font-size: 18px;
  margin-bottom: 24px;
`;

export const SelectResume = styled(Select)`
  width: 100%;
  display: block;
  caret-color: rgb(33, 150, 243);
  background-color: rgb(242, 245, 250);
  color: rgb(38, 43, 51);
  pointer-events: unset;
  border-radius: 3px;
  padding: 11px 16px;
  outline: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: color 0.1s ease 0s;
`;

export const HistoricoProfissionalContainer = styled.div``;

export const SaveButton = styled.button`
  border: none;
  padding: 5px 15px;
  position: fixed;
  z-index: 2;
  margin: 10px 0 0 10px;
  bottom: 10px !important;
  right: 52%;
  border-radius: 70px;
  /* background-color: #411564 !important; */
  color: #fff !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  transition: all 2s ease-in;

  svg {
    margin-right: 10px;
  }

  &:disabled {
    background-color: #5b5c65 !important;
  }
`;

export const MobileSaveStatus = styled.div`
  border: none;
  padding: 10px;
  position: fixed;
  z-index: 2;
  margin: 10px 0 0 10px;
  bottom: 10px !important;
  right: 5%;
  border-radius: 50%;
  background-color: ${props => (props.saving ? '#411564' : 'rgba(200, 200, 200, 0.7)')};
  color: #fff !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.4s ease-in;
  animation: ${props => (props.saving ? 'at-ripple 1.5s infinite ease-in-out' : '')};

  @keyframes at-ripple {
    0% {
      box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1), 0 0 0 0 rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1),
        0 0 0 10px rgba(102, 102, 102, 0.1);
    }
    100% {
      box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1), 0 0 0 10px rgba(102, 102, 102, 0.1),
        0 0 0 20px rgba(102, 102, 102, 0);
    }
  }

  .rotate {
    animation: loading 0.6s linear infinite;
    @keyframes loading {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0);
      }
    }
  }
`;
