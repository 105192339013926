/* eslint-disable react/jsx-props-no-spreading */

import React, { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import { isAuthenticated } from '../../services/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.string.isRequired,
};

export default memo(PrivateRoute);
