import React, { memo, Fragment } from 'react';
import { MdWork } from 'react-icons/md';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import { format } from 'date-fns';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';

import { Container } from './styles';

const UniVerticalTimeLine = ({ events }) => (
  <Container>
    <VerticalTimeline layout="1-column">
      {events &&
        events.map(item => (
          <Fragment key={Math.random(10000)}>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#333', borderTop: '3px solid #ffcb08' }}
              contentArrowStyle={{ borderRight: '7px solid  #fff' }}
              date={format(new Date(item.date), 'dd/MM/yyyy')}
              iconStyle={{ background: item.removedFromProcess ? 'red' : 'rgb(0, 153, 93)', color: '#fff' }}
              icon={<MdWork />}
              position="right"
            >
              <h5 className="vertical-timeline-element-title">Vaga: {item.title}</h5>
              <h6 className="vertical-timeline-element-subtitle">Última Etapa: {item.subtitle}</h6>
              <p>Observação: {item.description}</p>
            </VerticalTimelineElement>
          </Fragment>
        ))}
    </VerticalTimeline>
  </Container>
);

UniVerticalTimeLine.propTypes = {
  events: PropTypes.instanceOf(Object).isRequired,
};

export default memo(UniVerticalTimeLine);
