import { Input, Select } from '@rocketseat/unform';
import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  background: #411564;
  padding: 20px;
  color: #b1d34b;
  width: 100vw;

  span {
    color: #fff;
  }
`;

export const FormContainer = styled.div`
  background: #fff;
  flex: auto;
  padding: 20px 0 20px 20px;
  width: 100%;
  overflow-x: hidden !important;

  button {
    float: right;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-right: 20px;
  }

  form label {
    display: block;
  }

  .half-width {
    grid-column: 2 / 3;
  }

  .full-width {
    grid-column: 1 / 3;
  }

  span:not([class]) {
    color: #de3a11;
    background: #ffe3e3;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    font-weight: bolder;
    align-items: center;
    border-radius: 5px;
    display: inline-block;
  }
`;

export const CustomDiv = styled.div`
  margin: 0;
`;

export const InputResume = styled(Input)`
  width: 100%;
  display: block;
  caret-color: rgb(33, 150, 243);
  background-color: rgb(235, 235, 235);
  color: rgb(38, 43, 51);
  pointer-events: unset;
  border-radius: 3px;
  padding: 11px 16px;
  outline: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: color 0.1s ease 0s;

  &:focus {
    border: 2px dashed #f47920;
  }
`;

export const SelectResume = styled(Select)`
  width: 100%;
  display: block;
  caret-color: rgb(33, 150, 243);
  background-color: rgb(242, 245, 250);
  color: rgb(38, 43, 51);
  pointer-events: unset;
  border-radius: 3px;
  padding: 11px 16px;
  outline: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: color 0.1s ease 0s;
`;

export const ListaEtapasExistentes = styled.div`
  /* background: rgba(40, 40, 40, 0.8); */
  color: #333;
  /* padding: 15px; */
  border-radius: 5px;
  /* border: 2px dashed #222; */
  min-height: 200px;
  /* max-height: 200px; */
  overflow: auto;

  li {
    background: #5b5c65;
    list-style: none;
    border-radius: 3px;
    margin-bottom: 5px;
    font-size: 15px !important;
    height: 36px;
    text-align: center;
    padding: 5px 15px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: rgba(91, 92, 101, 0.9);
    }

    svg {
      cursor: pointer;

      &:hover {
        color: #b1d34b;
      }
    }
  }
`;

export const ListaEtapas = styled.div`
  color: #333;
  min-height: 389px;

  li {
    background: #5b5c65;
    list-style: none;
    margin-bottom: 5px;
    font-size: 15px !important;
    height: auto;
    text-align: center;
    cursor: grab;
    padding: 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;

    &:hover {
      background: rgba(91, 92, 101, 0.9);
    }

    span.step-sequence {
      color: #222;
      font-weight: bolder;
      border-radius: 5em;
      padding: 3px 10px;
      position: absolute;
    }

    svg {
      cursor: pointer;
      margin-left: 10px;
    }
  }
`;

export const ComboDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1px 5px;

  div.title-etapa {
    padding-left: 50px;
  }
`;
