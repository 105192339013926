import axios from 'axios';

// const https = require('https');

// const agent = new https.Agent({ rejectUnauthorized: false });

export const baseUrl = process.env.NODE_ENV === 'production' ? 'http://186.248.139.10:3339' : 'http://localhost:3339';

const config = axios.create({
  baseURL: baseUrl,
  timeout: 180000,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Authorization',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  // httpsAgent: agent
});

export default config;
