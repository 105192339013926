import styled from 'styled-components';

export const Container = styled.div``;

export const Input = styled.input`
	height: 52px;
	font-weight: 500;
	border: 1px solid #c0d0e6;
	border-radius: 3px;
`;
