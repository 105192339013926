/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { toggleModal } from '~/store/modules/modal/actions';

import api from '../../services/api';
import JobDescription from '../JobDescription';
import { Container, ListContainer, ListItem, HeaderFilter, HeaderContainer } from './styles';

const JobViewer = () => {
  const [jobs, setJobs] = useState([]);
  const [message, setMessage] = useState('Carregando...');
  const [setores, setSetores] = useState([]);
  const [all, setAll] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const filterJobs = useCallback(
    setor => {
      const filteredData = jobs.filter(job => job.setor.nome === setor);
      const filteredSector = setores.filter(set => set === setor);
      setJobs(filteredData);
      setSetores(filteredSector);
    },
    [jobs, setores],
  );

  useEffect(() => {
    const fetchData = async () => {
      setMessage('Carregando...');
      try {
        const { data } = await api.get('processoseletivo/jobs');
        const { data: dados } = data;
        if (dados && dados.length > 0) {
          setJobs(dados);
          setSetores([]);
          const newSector = [];
          dados.forEach(item => {
            newSector.push(item.setor.nome);
          });

          const uniqueSectors = Array.from(new Set(newSector));
          setSetores(uniqueSectors);
        } else {
          setMessage(
            'Eita! 😥 Não temos vagas abertas no momento. Cadastre seu currículo e receba nossas vagas por e-mail. 😃 👍',
          );
        }
      } catch (error) {
        setMessage(
          'Opa! Tivemos um erro e não foi possível carregar as vagas disponíveis, tente recarregar esta página, e se persistir o erro tente novamente mais tarde.',
        );
        enqueueSnackbar(`Não foi possível carregar as vagas disponíveis. "Erro: ${error.message}"`, { variant: 'error' });
      }
    };
    fetchData();
  }, [all, enqueueSnackbar]);

  const showJob = useCallback(
    job => {
      dispatch(toggleModal(true, <JobDescription job={job} close={toggleModal} />, 'jobs', ''));
    },
    [dispatch],
  );

  return (
    <Container>
      <HeaderContainer>
        <section>
          <h1>#Unijob</h1>
          <h6>
            <p>
              Essas são nossas vagas abertas. Corra e faça o seu cadastro <Link to="/">aqui.</Link>
            </p>
            <p>
              Não se esqueça de sempre manter seus dados atualizados, para assim, aumentar as suas chances de ser chamado para
              nossos processos seletivos.
            </p>
          </h6>
          <ul>
            <HeaderFilter onClick={() => setAll(!all)}>Todos</HeaderFilter>{' '}
            {setores.length > 0 &&
              setores.map(setor => (
                <HeaderFilter key={setor} onClick={() => filterJobs(setor)}>
                  {setor}
                </HeaderFilter>
              ))}
          </ul>
        </section>
      </HeaderContainer>
      {!isMobile ? (
        <Scrollbars
          style={{ height: 'calc(45vh + 60px)', width: '100%', position: 'absolute', marginTop: '-60px' }}
          renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
        >
          <ListContainer>
            {jobs.length > 0 ? (
              jobs.map(job => (
                <ListItem key={job.id} onClick={() => showJob(job.id)}>
                  <div className="container-description-jobs">
                    <div className="job-name">{job.nome}</div>
                    <span>Saiba mais...</span>
                  </div>
                </ListItem>
              ))
            ) : (
              <div className="no-jobs">
                <p>{message}</p>
              </div>
            )}
          </ListContainer>
        </Scrollbars>
      ) : (
        <ListContainer>
          {jobs.length > 0 ? (
            jobs.map(job => (
              <ListItem key={job.id} onClick={() => showJob(job.id)}>
                <div className="container-description-jobs">
                  <div className="job-name">{job.nome}</div>
                  <span>Saiba mais...</span>
                </div>
              </ListItem>
            ))
          ) : (
            <div className="no-jobs">
              <p>{message}</p>
            </div>
          )}
        </ListContainer>
      )}
    </Container>
  );
};

export default memo(JobViewer);
