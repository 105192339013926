import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 30px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px 30px;
    overflow: hidden;
  }

  .MuiStepLabel-label {
    color: #333 !important;
  }

  .MuiStepper-root {
    padding: 5px 0px !important;
  }

  .MuiStepIcon-active {
    color: #f47920 !important;
  }

  .MuiStepIcon-completed {
    color: #00995d !important;
  }
`;

export const StepContentContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 245px);
  background: none;
  padding: 15px;
  border-radius: 5px;
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); */
  overflow: hidden;
  margin-top: 5px;
`;

export const StepInnerContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${props => parseInt(props.width / 290)}, 1fr);
  grid-gap: 15px;
  margin-right: 10px;
`;

export const StepItem = styled.div`
  background: rgba(35, 35, 35, 0.3);
  color: ${props => (props.desqualified ? '#fff0c7' : '#fff')};
  list-style: none;
  padding: 30px 10px 10px 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-decoration: ${props => (props.desqualified ? 'line-through' : 'none')};
  text-decoration-color: #ff0000;
  text-decoration-style: wavy;

  span {
    font-size: 16px;
  }

  span.nome-candidato-processo {
    font-size: 25px;
  }

  svg {
    cursor: pointer;
    align-self: flex-end;
  }

  button {
    border: 0;
    background: transparent;
    border-bottom: 1px solid rgba(35, 35, 35, 0.5);
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
`;

export const Button = styled.button`
  border: none;
  background: ${props => (props.backcolor ? props.backcolor : '#00995d')};
  color: #fff;
  padding: 5px 15px;
  margin: 10px 10px 10px 0;
  border-radius: 5px;
  width: 80px;
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); */

  &:hover {
    opacity: 0.8;
  }
`;

export const NoApplicantContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #411564;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
`;
