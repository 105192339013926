import React from 'react';

import { Container } from './styles';

const NotFound = () => (
  <Container>
    <h1>404</h1>
    <h5>Página não encontrada!</h5>
  </Container>
);

export default NotFound;
