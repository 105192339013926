import api from '~/services/api';

export default async function userHasResume(id) {
  try {
    const retorno = await api.get(`/curriculos/has-resume/${id}`);
    const { data } = retorno;
    if (!data.status) throw new Error(data.message);
    return data.hasResume;
  } catch (error) {
    return null;
  }
}
