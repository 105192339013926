import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  height: 100% !important;
  flex: 0 0 32vw;
  opacity: ${props => (props.done ? 0.6 : 1)};
  max-width: 450px;
  & + div {
    border-left: 1px solid rgba(200, 200, 200, 0.72);

    @media (max-width: 768px) {
      border-left: none;
      border-top: 1px solid rgba(200, 200, 200, 0.72);
    }
  }

  header {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;

    h2 {
      font-weight: 500;
      font-size: 16px;
      padding: 0 10px;
      color: #666;
    }

    button {
      width: 42px;
      height: 42px;
      border-radius: 50px;
      background: #411564;
      border: 0;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  ul {
    padding: 0 5px 0 10px;
    margin-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 215px);
  }
`;
