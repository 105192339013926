import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

// if (window.location.protocol.indexOf('https') === 0){
//   var el = document.createElement('meta')
//   el.setAttribute('http-equiv', 'Content-Security-Policy')
//   el.setAttribute('content', 'upgrade-insecure-requests')
//   document.head.append(el)
// }

const rootEl = global.document.getElementById('root');
ReactDOM.render(<App />, rootEl);
