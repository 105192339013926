import { createContext } from 'react';

export default createContext({
  list: [],
  move: () => {},
  setRefresh: () => {},
  setores: [],
  coordenadores: [],
  caracteristicas: [],
  etapas: [],
});
