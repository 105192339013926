import produce from 'immer';

const INITIAL_STATE = {
  alertOpen: false,
  message: 'Campo a ser excluído',
  title: 'Confirma exclusão',
  confirmLabel: 'Sim',
  cancelLabel: 'Cancelar',
  onConfirm: {},
  onCancel: {},
};

export default function alert(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@alert/TOGGLE': {
        draft.alertOpen = action.payload.alertOpen;
        draft.message = action.payload.message;
        draft.title = action.payload.title;
        draft.confirmLabel = action.payload.confirmLabel;
        draft.cancelLabel = action.payload.cancelLabel;
        draft.onConfirm = action.payload.onConfirm;
        draft.onCancel = action.payload.onCancel;
        break;
      }
      default:
    }
  });
}
