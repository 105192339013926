import React, { useState, useEffect, useMemo, memo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { getMonth, getDate, getYear, setHours, setMinutes, setSeconds } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { AreaChart, XAxis, YAxis, Tooltip, Area, Legend } from 'recharts';

import { toggleLoading, setCurrentPage } from '~/store/modules/main/actions';

import Process from '../../assets/active-process.svg';
import Calendar from '../../assets/calendar.svg';
import CurriculoIcon from '../../assets/curriculo2.svg';
import api from '../../services/api';
import { Container, UpContainer, DownContainer, CardContainer } from './styles';

const Main = () => {
  const [monthlyResumes, setMonthlyResumes] = useState('?');
  const [dailyResumes, setDailyResumes] = useState('?');
  const [qteProcessoAberto, setQteProcessoAberto] = useState('?');
  const [total, setTotal] = useState('?');
  const [dadosHomens, setDadosHomens] = useState([]);
  const [dadosMulheres, setDadosMulheres] = useState([]);
  const qteHomens = useMemo(() => dadosHomens.length, [dadosHomens]);
  const qteMulheres = useMemo(() => dadosMulheres.length, [dadosMulheres]);
  const [trabalhando, setTrabalhando] = useState([]);
  const qteTrabalhando = useMemo(() => trabalhando.length, [trabalhando]);
  const [chartData, setChartData] = useState([]);
  const [updatedResume, setUpdatedResume] = useState();
  const cardRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Generate date key 'MMM dd'
  // Replaces use of moment.js
  function getDateKey(date) {
    const y = date.getFullYear();
    const m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${m[date.getMonth()]} ${(y < 10 ? '0' : '') + y}`;
  }

  const utcDate = date => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const checkDate = setSeconds(setMinutes(setHours(date, 0), 0), 0);
    return utcToZonedTime(checkDate, timezone);
  };

  const fetchData = useCallback(async () => {
    dispatch(toggleLoading(true));

    const compareDate = utcDate(new Date());

    try {
      const { data } = await api.get(`/dashboard`);
      const { curriculoData: dados, processoSeletivoData } = data.data;
      const { total: qte } = data;
      if (dados.length > 0) {
        const mensal = dados.filter(
          item =>
            getMonth(utcDate(new Date(item.dataCadastro))) === getMonth(compareDate) &&
            getYear(utcDate(new Date(item.dataCadastro))) === getYear(compareDate),
        );
        const daily = dados.filter(
          item =>
            getDate(utcDate(new Date(item.dataCadastro))) === getDate(compareDate) &&
            getMonth(utcDate(new Date(item.dataCadastro))) === getMonth(compareDate) &&
            getYear(utcDate(new Date(item.dataCadastro))) === getYear(compareDate),
        );

        const updated = dados.filter(
          item =>
            getMonth(utcDate(new Date(item.dataAlteracao))) === getMonth(compareDate) &&
            getYear(utcDate(new Date(item.dataAlteracao))) === getYear(compareDate),
        );

        setDadosHomens(dados.filter(item => item.sexoId === 4));
        setDadosMulheres(dados.filter(item => item.sexoId === 1));
        setTrabalhando(dados.filter(item => item.estaTrabalhandoAtualmente === 0 && item.sexoId !== null));
        setTotal(qte);
        setMonthlyResumes(mensal.length);
        setDailyResumes(daily.length);
        setQteProcessoAberto(processoSeletivoData.length);
        setUpdatedResume(updated.length);

        const obj = dados
          .reduce(
            (acc, d) => {
              const p = getDateKey(new Date(d.dataCadastro));
              if (!Object.prototype.hasOwnProperty.call(acc[0], p)) acc[0][p] = [];
              acc[0][p].push(d);
              return acc;
            },
            [{}],
          )
          .reduce((acc, v) => {
            Object.keys(v).forEach(k => {
              acc.push({
                mes: k,
                curriculos: v[k].length,
                incompletos: v[k].filter(item => !item.sexoId).length,
              });
            });
            return acc;
          }, []);
        setChartData(obj);
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    dispatch(toggleLoading(false));
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    fetchData();
    dispatch(setCurrentPage('Dashboard'));
  }, [fetchData, dispatch]);

  return (
    <Container>
      <UpContainer>
        <div>
          <CardContainer>
            <div>
              <img src={CurriculoIcon} alt="Ícone de Currículos" />
              <h1>Currículos</h1>
              <h3>{total} cadastrados</h3>
              <span className="span-main">
                <b>
                  {qteHomens} ( {((qteHomens / total) * 100).toFixed(2)}% )
                </b>{' '}
                Homens
              </span>
              <span className="span-main">
                <b>
                  {qteMulheres} ( {((qteMulheres / total) * 100).toFixed(2)}% )
                </b>{' '}
                Mulheres
              </span>
              <span className="span-main">
                <b>
                  {total && total - (qteMulheres + qteHomens)} ({' '}
                  {((total && (total - (qteMulheres + qteHomens)) / total) * 100).toFixed(2)}% )
                </b>{' '}
                Não Informaram
              </span>
              <span className="span-main">
                <b>
                  {qteTrabalhando} ( {((qteTrabalhando / total) * 100).toFixed(2)}% )
                </b>{' '}
                desempregados
              </span>
            </div>
          </CardContainer>
          <CardContainer>
            <div>
              <img src={Calendar} alt="Ícone de Currículos" id="calendar-card-img" />
              <h4>Currículos este mês</h4>
              <span className="span-main">
                <b>{monthlyResumes}</b> cadastrado{monthlyResumes > 1 ? 's' : ''} este mês
              </span>
              <span className="span-main">
                <b>{dailyResumes}</b> cadastrado{dailyResumes > 1 ? 's' : ''} hoje
              </span>
              <span className="span-main">
                <b>{updatedResume}</b> atualizado{updatedResume > 1 ? 's' : ''} este mês
              </span>
            </div>
          </CardContainer>
          <CardContainer>
            <div>
              <img src={Process} alt="Ícone de Currículos" />
              <h4>Processos Ativos</h4>
              <span className="span-main">
                <b>{qteProcessoAberto}</b> processo
                {qteProcessoAberto > 1 ? 's seletivos abertos' : ' seletivo aberto'}
              </span>
            </div>
          </CardContainer>
        </div>
      </UpContainer>
      <DownContainer>
        <div className="chart-second-container">
          <CardContainer ref={cardRef}>
            <div>
              <h3>Quantidade de currículos por mês</h3>
            </div>
            <AreaChart
              width={cardRef.current && cardRef.current?.children[0]?.clientWidth}
              height={380}
              data={chartData}
              margin={{ top: 30, right: 45, left: 0, bottom: 15 }}
            >
              <defs>
                <linearGradient id="curriculos" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00995d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#00995d" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="incompletos" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ed1651" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#ed1651" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="mes" name="Meses" />
              <YAxis name="Quantidade" />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="curriculos" stroke="#00995d" fillOpacity={1} fill="url(#curriculos)" />
              <Area type="monotone" dataKey="incompletos" stroke="#ed1651" fillOpacity={1} fill="url(#incompletos)" />
            </AreaChart>
          </CardContainer>
        </div>
      </DownContainer>
    </Container>
  );
};

export default memo(Main);
