/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import { Check } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Container } from './styles';

// A função onChange estou mantendo para poder esconder ou mostrar textbox de acordo com a escolha
// Portanto não retirar essa função

const ReactSwitch = ({ name, textoLigado, textoDesligado, onChange, ...props }) => (
  <Container>
    <label className="tgl" style={{ fontSize: '13px' }}>
      <Check className="input-switch" type="checkbox" name={name} onChange={e => onChange(e.target.checked)} {...props} />
      <span className="null" data-on={textoLigado} data-off={textoDesligado} />
    </label>
  </Container>
);

ReactSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  textoLigado: PropTypes.string.isRequired,
  textoDesligado: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

ReactSwitch.defaultProps = {
  onChange: () => null,
};
export default memo(ReactSwitch);
