import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Form } from '@rocketseat/unform';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import ReactSelect from '../../components/ReactSelect';
import ReactSwitch from '../../components/ReactSwitch';
import api from '../../services/api';
import { ContainerForm, Header, InputResume, CustomDiv } from './styles';

function FormUsuarios({ data: dados, setRefresh }) {
  const [data, setData] = useState({});
  const [coordenador, setCoordenador] = useState(false);
  const [funcionario, setFuncionario] = useState(false);
  const [tiposUsuarios, setTiposUsuarios] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    const { data: tipoUsuario } = await api.get('tipoUsuario');
    if (tipoUsuario) {
      tipoUsuario.forEach(item => {
        setTiposUsuarios(tipos => [...tipos, { id: item.id, title: item.nome }]);
      });
    }
    setCoordenador(Boolean(dados.dataLoad.eCoordenador));
    setFuncionario(Boolean(dados.dataLoad.eFuncionario));
    setData(dados.dataLoad);
  }, [dados.dataLoad]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = useCallback(
    async formData => {
      dispatch(toggleLoading(true));
      let save = {};
      if (formData) {
        save = await api.put('usuarios', {
          ...formData,
          eCoordenador: +coordenador,
          eFuncionario: coordenador ? +coordenador : +funcionario,
          dataAlteracao: new Date(),
        });
      }
      const { status, message } = save.data;
      if (status) {
        enqueueSnackbar(message, { variant: 'success' });
        setRefresh(refresh => !refresh);
        dispatch(toggleModal(false, null, null, null));
      } else {
        enqueueSnackbar(message, { variant: 'error' });
      }
      dispatch(toggleLoading(false));
    },
    [coordenador, dispatch, enqueueSnackbar, funcionario, setRefresh],
  );

  return (
    <ContainerForm>
      <Header>
        <h1>Usuário</h1>
        <span>Administração de usuários</span>
      </Header>
      <Form onSubmit={handleSubmit} initialData={data}>
        <InputResume id="id" name="id" style={{ display: 'none' }} />
        <CustomDiv>
          <label htmlFor="nome">Nome do Usuário</label>
          <InputResume name="nome" id="nome" readOnly />
        </CustomDiv>
        <CustomDiv>
          <label htmlFor="email">Email</label>
          <InputResume name="email" id="email" readOnly />
        </CustomDiv>
        <CustomDiv>
          <ReactSelect
            name="tipoUsuarioId"
            label="Grupo de Usuário"
            options={tiposUsuarios}
            multiple={false}
            closeOnSelect
            fieldPointer="tipoUsuarioId"
          />
        </CustomDiv>
        <CustomDiv>
          <label htmlFor="estaTrabalhandoAtualmente">É coordenador? </label>
          <ReactSwitch
            name="eCoordenador"
            id="eCoordenador"
            textoLigado="Sim"
            textoDesligado="Não"
            onChange={setCoordenador}
            ligado={!!coordenador}
          />
          <br />
          <label htmlFor="estaTrabalhandoAtualmente">É funcionário? </label>
          <ReactSwitch
            name="eFuncionario"
            id="eFuncionario"
            textoLigado="Sim"
            textoDesligado="Não"
            onChange={setFuncionario}
            ligado={!!funcionario}
          />
        </CustomDiv>
        <button type="submit" color="#FFF" style={{ marginTop: '10px', right: '0' }}>
          Salvar
        </button>
      </Form>
    </ContainerForm>
  );
}

FormUsuarios.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default FormUsuarios;
