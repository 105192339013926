import React, { useState, useEffect, useRef, Fragment, memo, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdThumbUp, MdThumbDown, MdPrint } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, Input } from '@rocketseat/unform';
import { format, differenceInYears } from 'date-fns';
import { format as formatCPF } from 'gerador-validador-cpf';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { store } from '~/store';
import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import UserProfile from '../../../assets/user.png';
import Button from '../../../components/Button';
import UniBreakLines from '../../../components/UniBreakLines';
import { niveis as languageLevel } from '../../../configs/helpers/languagelevel';
import { niveis as skilllevel } from '../../../configs/helpers/skilllevel';
import * as routes from '../../../configs/Routes';
import { storage } from '../../../firebase';
import { getLocalDate, getUTCDate, formatPhoneNumber } from '../../../functions/curriculos';
import api from '../../../services/api';
import { Container, DashContainer, ConfigContainer, RightItemsContainer, ThumbsContainer, LoadingDataMessage } from './styles';

const AdminResumeViewer = ({ curriculoData, stepToGo, refresh, processoSeletivoId }) => {
  const [dados, setDados] = useState([]);
  const [emailUsuario, setEmailUsuario] = useState('');
  const [firstName, setFirstName] = useState('');
  const [imageUrl, setImageUrl] = useState(null);

  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [thumbsUp, setThumbsUp] = useState(false);
  const [thumbsDown, setThumbsDown] = useState(false);
  const printRef = useRef();
  const { profile: userData } = store.getState().user;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (!curriculoData) return;

      try {
        setLoading(true);
        const { data } = await api.get(`userresumes?id=${curriculoData.curriculo.usuarioId}`);
        if (data.length === 0) return;
        const { resumeData, ...othersInfo } = data;
        const resumeObject = { ...resumeData, ...othersInfo };
        setDados(resumeObject);

        const {
          telefone1,
          usuario: { nome, email },
        } = resumeObject;

        const nomeDividido = nome.split(' ');
        setFirstName(nomeDividido[0]);
        nomeDividido.splice(0, 1);
        setLastName(nomeDividido.join(' '));
        setEmailUsuario(email);
        setTelefone(telefone1);

        try {
          const userImageUrl = await storage.doUserPhotoUrlLink(curriculoData.curriculo.usuarioId);
          setImageUrl(userImageUrl || null);
        } catch (error) {
          // do nothing
        }
      } catch (err) {
        enqueueSnackbar(`Erro ao carregar dados do curriculo ${err.message}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [toggleLoading]); // eslint-disable-line

  const handleSubmit = useCallback(
    async (data, { resetForm }) => {
      dispatch(toggleLoading(true));

      const curriculoEtapaProcessoSeletivo = {
        id: curriculoData.id,
        etapaProcessoSeletivoId: thumbsUp ? stepToGo.id : curriculoData.etapaProcessoSeletivoId,
        dataAtualizacao: getLocalDate(),
        usuarioAtualizacao: userData.id,
        excluirDoProcesso: +thumbsDown,
      };

      const { data: retorno } = await api.put('curriculoetapaprocessoseletivo', curriculoEtapaProcessoSeletivo);

      const observacaoCurriculoEtapaProcessoSeletivo = {
        curriculoId: curriculoData.id,
        etapaProcessoSeletivoId: curriculoData.etapaProcessoSeletivoId,
        processoSeletivoId,
        observacao: data.observacao,
        dataCadastro: getLocalDate(),
        usuarioCadastro: userData.id,
      };

      const { data: retornoObservacao } = await api.post(
        'observacaocurriculoetapaprocessoseletivo',
        observacaoCurriculoEtapaProcessoSeletivo,
      );

      if (retorno.status && retornoObservacao.status) {
        if (thumbsDown) {
          refresh(curriculo =>
            curriculo.map(currentResume => {
              if (currentResume.id === curriculoData.id) {
                return { ...currentResume, excluirDoProcesso: 1 };
              } else {
                return { ...currentResume };
              }
            }),
          );
        } else {
          refresh(curriculo => {
            const curriculoEditado = curriculo.filter(c => c.id === curriculoData.id);
            curriculoEditado[0].etapaProcessoSeletivoId = stepToGo.id;
            return [...curriculo.filter(c => c.id !== curriculoData.id), ...curriculoEditado];
          });
        }

        enqueueSnackbar(retorno.message, { variant: 'success' });

        dispatch(toggleModal(false, null, null, null));
      } else {
        enqueueSnackbar(retorno.message ? retorno.message : retornoObservacao.message, { variant: 'error' });
      }

      resetForm();

      dispatch(toggleLoading(false));
    },
    [
      curriculoData.etapaProcessoSeletivoId,
      curriculoData.id,
      dispatch,
      enqueueSnackbar,
      processoSeletivoId,
      refresh,
      stepToGo.id,
      thumbsDown,
      thumbsUp,
      userData.id,
    ],
  );

  return (
    <DashContainer>
      {loading ? (
        <LoadingDataMessage>Carregando dados...</LoadingDataMessage>
      ) : (
        <>
          <Container ref={printRef}>
            <Scrollbars
              style={{ height: 'calc(100vh - 160px)', width: '100%' }}
              renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div style={{ paddingRight: '50px' }}>
                <div className="header">
                  <div className="full-name">
                    <span className="first-name">{firstName}</span>
                    <span className="last-name"> {lastName}</span>
                  </div>

                  <div className="contact-info">
                    <span className="email">Email: </span>
                    <span className="email-val">{emailUsuario}</span>
                    <span className="separator" />
                    <span className="phone">Telefone: </span>
                    <span className="phone-val">{formatPhoneNumber(telefone)}</span>
                    <span className="separator" />
                    <span className="phone-val">{formatPhoneNumber(dados.telefone2)}</span>
                  </div>

                  <div className="contact-info">
                    <span className="email">CPF: </span>
                    <span className="email-">{dados.usuario && formatCPF(dados.usuario.cpf)}</span>
                    <span className="separator" />
                    <span className="email">RG: </span>
                    <span className="email-val">{dados.rg}</span>
                    <span className="separator" />
                    <span className="email">PIS/PASEP: </span>
                    <span className="email-val">{dados.pis}</span>
                    <span className="separator" />
                  </div>

                  <div className="contact-info">
                    <span className="phone">Idade: </span>
                    <span className="phone-val">{differenceInYears(new Date(), new Date(dados.dataNascimento))} anos</span>
                    <span className="separator" />
                    <span className="phone">Estado Civil: </span>
                    <span className="phone-val">{dados.estadoCivil && dados.estadoCivil.nome}</span>
                    <span className="separator" />
                    <span className="phone">Sexo: </span>
                    <span className="phone-val">{dados.sexo && dados.sexo.nome}</span>
                    <span className="separator" />
                    <span className="phone">Carteira de Habilitação: </span>
                    <span className="phone-val">{dados.cnh ? dados.cnh : 'Não possuo'}</span>
                  </div>

                  <div className="contact-info">
                    <span className="phone">Endereço: </span>
                    <span className="phone-val">
                      {dados.logradouro}, {dados.numero}, {dados.bairro} - {dados.cidade}.{dados.uf}
                    </span>
                  </div>

                  <div className="contact-info">
                    <span className="email">Possui Filhos: </span>
                    <span className="email-val">{dados.possuiFilhos ? 'Sim' : 'Não'}</span>
                    <span className="separator" />
                    <span className="email">Disponibilidade para Turnos: </span>
                    <span className="email-val">{dados.disponibilidadeHorarioTurno ? 'Sim' : 'Não'}</span>
                    <span className="separator" />
                    <span className="email">Pessoa Com Deficiência: </span>
                    <span className="email-val">{dados.portadorNecessidadeEspecial ? 'Sim' : 'Não'} </span>
                    {dados.portadorNecessidadeEspecial && <span className="email-val">CID: {dados.cidNecessidadeEspecial}</span>}
                    <span className="separator" />
                  </div>

                  <div className="contact-info">
                    <span className="email">Amigo ou Parente na empresa: </span>
                    <span className="email-val">{dados.possuiAmigoOuFamiliarNaEmpresa ? 'Sim' : 'Não'} </span>
                    <span className="email">
                      {dados.nomeAmigoOuFamiliarNaEmpresa} {dados.grauParentescoAmigoOuFamiliarNaEmpresa}
                    </span>
                    <span className="separator" />
                  </div>

                  <div className="contact-info">
                    <span className="email">Está trabalhando atualmente: </span>
                    <span className="email-val">{dados.estaTrabalhandoAtualmente ? 'Sim' : 'Não'}</span>
                    <span className="separator" />
                    <span className="email">Pretensão Salarial: </span>
                    <span className="email-val">
                      R$ {dados.pretensaoSalarial && dados.pretensaoSalarial.toString().replace('.', ',')}
                    </span>
                    <span className="separator" />
                  </div>
                  <div className="contact-info">
                    <span className="email">Escolaridade: </span>
                    <span className="email-val">{dados.escolaridade && dados.escolaridade.nome}</span>
                  </div>
                </div>

                <div className="details">
                  <div className="section">
                    <div className="section__title">Áreas de Interesse</div>

                    <div className="section__list">
                      {dados.setoresCurriculo &&
                        dados.setoresCurriculo.map(item => (
                          <Fragment key={item.id}>
                            <span>{item.setores.nome}</span>

                            <br />
                          </Fragment>
                        ))}
                    </div>
                  </div>

                  <div className="section">
                    {dados.historicoProfissional && dados.historicoProfissional.length > 0 && (
                      <>
                        <div className="section__title">Histórico Profissional</div>

                        <div className="section__list">
                          {dados.historicoProfissional.map(item => (
                            <div className="section__list-item" key={item.id}>
                              <div className="left">
                                <div className="name">{item.empresa}</div>

                                <div className="duration">
                                  {item.dataInicial && format(getUTCDate(item.dataInicial), 'MM/yyyy')} -{' '}
                                  {item.dataFinal && format(getUTCDate(item.dataFinal), 'MM/yyyy')}
                                </div>

                                <div className="addr">
                                  <UniBreakLines text={item.descricaoAtividades} />
                                </div>
                              </div>

                              <div className="right">
                                <div className="name">{item.cargo}</div>

                                <div className="desc">{item.setor}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoEducacional && dados.historicoEducacional.length > 0 && (
                      <>
                        <div className="section__title">Histórico Educacional</div>

                        <div className="section__list">
                          {dados.historicoEducacional.map(item => (
                            <div className="section__list-item" key={item.id}>
                              <div className="left">
                                <div className="name">{item.nomeInstituicao}</div>

                                <div className="addr">{item.cidade}</div>

                                <div className="duration">{item.descricao}</div>
                              </div>

                              <div className="right">
                                <div className="name">{item.graduacao && item.graduacao.nome}</div>

                                <div className="desc">
                                  {format(getUTCDate(item.inicioGraduacao), 'MM/yyyy')} -{' '}
                                  {format(getUTCDate(item.fimGraduacao), 'MM/yyyy')}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoCursos && dados.historicoCursos.length > 0 && (
                      <>
                        <div className="section__title">Histórico de Cursos</div>

                        <div className="section__list">
                          {dados.historicoCursos &&
                            dados.historicoCursos.map(item => (
                              <div className="section__list-item" key={item.id}>
                                <div className="left">
                                  <div className="name">{item.instituicao}</div>

                                  <div className="duration">{item.descricao}</div>
                                </div>

                                <div className="right">
                                  <div className="name">{item.nome}</div>

                                  <div className="desc">
                                    {format(getUTCDate(item.dataInicio), 'MM/yyyy')} -{' '}
                                    {format(getUTCDate(item.dataFim), 'MM/yyyy')}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoHabilidades && dados.historicoHabilidades.length > 0 && (
                      <>
                        <div className="section__title">Habilidades</div>

                        <div className="skills">
                          {dados.historicoHabilidades &&
                            dados.historicoHabilidades.map(item => {
                              const level = skilllevel.filter(skill => skill.value === item.nivel);

                              return (
                                <div className="skills__item" key={item.id}>
                                  <div className="left">
                                    <div className="section__list">
                                      {item.nome} em nível {level[0] && level[0].label}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="section">
                    {dados.historicoIdiomas && dados.historicoIdiomas.length > 0 && (
                      <>
                        <div className="section__title">Idiomas</div>

                        <div className="section__list">
                          {dados.historicoIdiomas &&
                            dados.historicoIdiomas.map(item => {
                              const level = languageLevel.filter(lang => lang.value === item.nivel);

                              return (
                                <div className="section__list-item" key={item.id}>
                                  {item.nome} nível {level[0] && level[0].label}
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </Container>
          <RightItemsContainer>
            <Link
              className="action-buttons"
              to={{
                pathname: `${routes.ADMIN_RESUME_VIEWER}/?id=${curriculoData.curriculo.usuarioId}`,
                state: { userId: curriculoData.curriculo.usuarioId },
              }}
              target="_blank"
            >
              <MdPrint size={24} className="print-button" />
            </Link>
            <ConfigContainer>
              <h3>Aprovar ou Rejeitar para entrevista</h3>
              <p>
                Se possível insira um comentário sobre sua escolha, ele vai nos ajudar a entender melhor as características que
                você deseja ou não em um candidato, e também vamos ter um histórico sobre o candidato em futuros processos
                seletivos.
              </p>
              <Form initialData={dados} onSubmit={handleSubmit}>
                <Input id="id" name="id" style={{ display: 'none' }} />
                <Input id="observacao" name="observacao" multiline rows={4} placeholder="Observação" />
                <span>Aprovar ou Rejeitar?</span>
                <ThumbsContainer down={thumbsDown} up={thumbsUp}>
                  <MdThumbUp
                    size={24}
                    onClick={() => {
                      setThumbsUp(!thumbsUp);
                      setThumbsDown(false);
                    }}
                  />
                  <MdThumbDown
                    size={24}
                    onClick={() => {
                      setThumbsUp(false);
                      setThumbsDown(!thumbsDown);
                    }}
                  />
                </ThumbsContainer>
                <Button text="Salvar" color="#fff" backColor="#00995d" handleClick={() => {}} />
              </Form>
              <img className="user-photo" src={imageUrl || UserProfile} alt="Foto do Candidato" />
            </ConfigContainer>
          </RightItemsContainer>
        </>
      )}
    </DashContainer>
  );
};

AdminResumeViewer.propTypes = {
  curriculoData: PropTypes.instanceOf(Object).isRequired,
  stepToGo: PropTypes.instanceOf(Object).isRequired,
  refresh: PropTypes.func.isRequired,
  processoSeletivoId: PropTypes.number.isRequired,
};

export default memo(AdminResumeViewer);
