/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, memo, useCallback } from 'react';
import { MdSchool, MdGames, MdExtension, MdGroupWork } from 'react-icons/md';

import { differenceInYears } from 'date-fns';
import PropTypes from 'prop-types';

import UserProfile from '../../assets/user.png';
import { storage } from '../../firebase';
import { Container, UserImageContainer } from './styles';

const ApplicantCard = ({ data: { curriculo }, showForm }) => {
  const { estadoCivil, usuario, historicoProfissional, historicoCursos, historicoIdiomas, historicoHabilidades } = curriculo;
  const [imageUrl, setImageUrl] = useState();

  const fetchUserPhoto = useCallback(async () => {
    if (!usuario) return;
    setImageUrl(await storage.doUserPhotoUrlLink(usuario.id));
  }, [usuario]);

  useEffect(() => {
    fetchUserPhoto();
  }, [fetchUserPhoto]);

  return (
    <Container>
      <div className="center-upper">
        <div className="card" onClick={showForm}>
          <div className="additional">
            <div className="user-card">
              <div className="level center">{differenceInYears(new Date(), new Date(curriculo.dataNascimento))} anos</div>
              <div className="points center">{estadoCivil && estadoCivil.nome}</div>
              <UserImageContainer className="center">
                <img src={imageUrl || UserProfile} alt="Profile" />
              </UserImageContainer>
            </div>
            <div className="more-info">
              <h1>{usuario.nome}</h1>
              <div className="coords">
                <span>Bairro</span>
                <span>Cidade</span>
              </div>
              <div className="coords">
                <span>{curriculo.bairro}</span>
                <span>{curriculo.cidade}, MG</span>
              </div>
              <div className="stats">
                <div>
                  <div className="title">Cursos</div>
                  <MdSchool size={24} />
                  <div className="value">{historicoCursos.length}</div>
                </div>
                <div>
                  <div className="title">Habilidades</div>
                  <MdExtension size={24} />
                  <div className="value">{historicoHabilidades.length}</div>
                </div>
                <div>
                  <div className="title">Idiomas</div>
                  <MdGames size={24} />
                  <div className="value">{historicoIdiomas.length}</div>
                </div>
                <div>
                  <div className="title">Empregos</div>
                  <MdGroupWork size={24} />
                  <div className="value">{historicoProfissional.length}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="general center">
            <h1>{usuario.nome}</h1>
            <p>
              {estadoCivil && estadoCivil.nome} {!curriculo.possuiFilhos ? 'não possui' : 'possui'} filhos,{' '}
              {curriculo.disponibilidadeHorarioTurno ? '' : 'não'} tem disponibilidade para trabalhar em turnos, atualmente está{' '}
              {curriculo.estaTrabalhandoAtualmente ? 'trabalhando' : 'desempregado'}.
            </p>
            <span className="more">Passe o mouse e saiba mais...</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

ApplicantCard.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  showForm: PropTypes.func.isRequired,
};

export default memo(ApplicantCard);
