import React from 'react';

import { Container } from './styles';

function SignUpVerification() {
  return (
    <Container>
      <h1>#InfoJob</h1>
      <span>Você recebeu um email de verificação. Click no link recebido para ativar sua conta.</span>
    </Container>
  );
}

export default SignUpVerification;
