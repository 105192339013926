import React, { useState, useEffect, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { toggleLoading, setPageDescription } from '~/store/modules/main/actions';

import api from '../../../services/api';
import {
  Container,
  SubContainer,
  HeaderContainer,
  ItemsContainer,
  ItemHeaderContainer,
  TableContainer,
  TableColumns,
  ColumnHeader,
  ColumnItems,
  ColumnTotal,
  TableRows,
  TableSubRow,
  RowHeader,
  RowItems,
  RowTotal,
} from './styles';

const Summary = ({ processoSeletivoId }) => {
  const [processoSeletivo, setProcessoSeletivo] = useState({});
  const [etapas, setEtapas] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      dispatch(toggleLoading(true));
      if (!processoSeletivoId || candidates.length > 0) return;
      const {
        data: { data: dados },
      } = await api.get('processoseletivo/specific-process-summary', {
        headers: { id: processoSeletivoId },
      });

      setProcessoSeletivo(dados.processData);
      setEtapas(dados.steps);
      setCandidates(dados.candidatesRatings);
      dispatch(setPageDescription(dados.processData.nome));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      dispatch(toggleLoading(false));
    }
  }, [candidates.length, dispatch, enqueueSnackbar, processoSeletivoId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {processoSeletivo.nome !== undefined && (
        <Container>
          <h3>Avaliação Geral</h3>
          <SubContainer>
            <HeaderContainer>
              <>
                <div>
                  <label htmlFor="div">Coordenador</label>
                  {processoSeletivo.coordenador.nome}
                </div>
                <div>
                  <label htmlFor="div">Setor</label>
                  {processoSeletivo.setor.nome}
                </div>
                <div>
                  <label htmlFor="div">Cargo</label>
                  {processoSeletivo.nome}
                </div>
              </>
            </HeaderContainer>
            <ItemsContainer>
              <ItemHeaderContainer>
                <h5>Avalie o desempenho de cada profissional segundo os conceitos abaixo:</h5>
                <div>
                  <span>4 - Muito bom potencial</span>
                  <span>3 - Bom potencial</span>
                  <span>2 - Regular potencial</span>
                  <span>1 - Fraco potencial</span>
                </div>
              </ItemHeaderContainer>
              {etapas &&
                etapas.map(item => (
                  <TableContainer key={item.id}>
                    <TableColumns>
                      <ColumnHeader>
                        <div className="item-description">
                          <h6>
                            {item.etapa.nome} ({item.valorEtapa}%)
                          </h6>
                          <span>Característica / Competências</span>
                        </div>
                        <div className="nome-profissional">Nome dos Profissionais</div>
                      </ColumnHeader>
                      <ColumnItems>
                        {item.caracteristicaEtapaProcessos.map(caract => (
                          <div key={caract.id}>{caract.caracteristicaCompetencia.nome}</div>
                        ))}
                      </ColumnItems>
                      <ColumnTotal>Total</ColumnTotal>
                    </TableColumns>
                    <TableRows>
                      {candidates.map(can => (
                        <TableSubRow key={can.id}>
                          <RowHeader>{can.usuario.nome.toUpperCase()}</RowHeader>
                          <RowItems>
                            {can.pontuacaoProcessoSeletivos.map(
                              userPoint =>
                                userPoint.etapaProcessoSeletivoId === item.id && (
                                  <div key={userPoint.id}>{userPoint.pontuacao}</div>
                                ),
                            )}
                          </RowItems>
                          <RowTotal>
                            {can.pontuacaoProcessoSeletivos
                              .filter(pps => pps.etapaProcessoSeletivoId === item.id)
                              .reduce((sum, points) => sum + points.pontuacao, 0)}{' '}
                            (
                            {Math.round(
                              (can.pontuacaoProcessoSeletivos
                                .filter(pps => pps.etapaProcessoSeletivoId === item.id)
                                .reduce((sum, points) => sum + points.pontuacao, 0) /
                                (item.caracteristicaEtapaProcessos.length * 4)) *
                                100,
                            )}
                            %)
                          </RowTotal>
                        </TableSubRow>
                      ))}
                    </TableRows>
                  </TableContainer>
                ))}

              <TableContainer>
                <TableColumns backcolor="#411564">
                  <ColumnHeader>
                    <div className="item-description">
                      <h6>Resultado Geral</h6>
                      <span>Característica / Competências</span>
                    </div>
                    <div className="nome-profissional">Nome dos Profissionais</div>
                  </ColumnHeader>
                  <ColumnItems>
                    {etapas &&
                      etapas.map(etapa => (
                        <div key={etapa.id}>
                          {etapa.etapa.nome}
                          <br /> ({etapa.valorEtapa}%)
                        </div>
                      ))}
                  </ColumnItems>
                  <ColumnTotal backcolor="#411564">Total</ColumnTotal>
                </TableColumns>
                <TableRows>
                  {candidates.map(can => (
                    <TableSubRow key={can}>
                      <RowHeader>{can.usuario.nome.toUpperCase()}</RowHeader>
                      <RowItems>
                        {etapas &&
                          etapas.map(etapa => (
                            <div key={etapa.id}>
                              {Math.fround(
                                (can.pontuacaoProcessoSeletivos
                                  .filter(pps => pps.etapaProcessoSeletivoId === etapa.id)
                                  .reduce((sum, points) => sum + points.pontuacao, 0) *
                                  can.pontuacaoProcessoSeletivos.filter(pps => pps.etapaProcessoSeletivoId === etapa.id)[0]
                                    .etapaProcessoSeletivo.valorEtapa) /
                                  (etapa.caracteristicaEtapaProcessos.length * 4),
                              )}
                            </div>
                          ))}
                      </RowItems>
                      <RowTotal backcolor="#411564">
                        {/* Calculando o total de cada etapa e depois somando tudo */}
                        {etapas &&
                          etapas
                            .map(
                              etapa =>
                                (can.pontuacaoProcessoSeletivos
                                  .filter(pps => pps.etapaProcessoSeletivoId === etapa.id)
                                  .reduce((sum, points) => sum + points.pontuacao, 0) *
                                  can.pontuacaoProcessoSeletivos.filter(pps => pps.etapaProcessoSeletivoId === etapa.id)[0]
                                    .etapaProcessoSeletivo.valorEtapa) /
                                (etapa.caracteristicaEtapaProcessos.length * 4),
                            )
                            .reduce((sum, points) => sum + points, 0)}
                      </RowTotal>
                    </TableSubRow>
                  ))}
                </TableRows>
              </TableContainer>
            </ItemsContainer>
          </SubContainer>
        </Container>
      )}
    </>
  );
};

Summary.propTypes = {
  processoSeletivoId: PropTypes.number.isRequired,
};

export default memo(Summary);
