import styled from 'styled-components';

import DashLitleBackground from '../../assets/background-card-style.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  align-items: center;

  h3,
  h4 {
    color: #0a5f55;
  }
`;

export const UpContainer = styled.div`
  width: 100vw;
  max-width: 1366px;
  padding: 10px 0;

  @media (max-width: 768px) {
    padding: 0;
  }

  & > div {
    display: grid;
    grid-template-columns: minmax(0px, 2fr) 1fr;
    grid-template-rows: 300px 300px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1399px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(300px, auto) !important;
      grid-row-gap: 10px;
      padding: 0 20px;
    }

    @media (max-height: 825px) {
      grid-template-rows: 250px 250px;
    }
  }
`;

export const DownContainer = styled.div`
  width: 100vw;
  max-width: 1366px;
  padding: 10px 0;

  @media (max-width: 768px) {
    padding: 0;
  }

  div.chart-second-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 400px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1399px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(300px, auto) !important;
      grid-row-gap: 10px;
      padding: 10px 20px 20px 20px;
    }

    @media (max-height: 825px) {
      grid-template-rows: 250px 250px;
    }
  }
`;

export const CardContainer = styled.a`
  &:first-child {
    grid-column: span 1 / auto;
    grid-row: span 2 / auto;
    background: url(${DashLitleBackground}) repeat scroll 90% 0% / cover;
    padding: 40px;
    box-sizing: border-box;

    h1 {
      font-size: 50px;
      font-weight: bold;
      color: #333;
      margin-bottom: 27px;
    }

    img {
      width: 136px;
      height: 136px;
      margin-bottom: 35px;
    }

    .span-main {
      margin-bottom: 5px;
      width: 295px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      padding: 2px 5px 5px 5px;
    }

    @media (max-width: 768px) {
      grid-column: span 1 / auto;
    }
  }

  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  opacity: 1;
  background: url(${DashLitleBackground}) repeat scroll 90% 0% / cover;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 30px;
  border-image: initial;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  img {
    margin-bottom: 25px;
    width: 46px;
    height: 46px;
  }

  &:hover {
    /* border: 1px solid #411564 !important; */
    cursor: pointer;
    transform: translate3d(0, -1%, 0);
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
  }

  .span-main {
    display: block;
    width: 363px;
    font-size: 18px;
    line-height: 1.5;
    color: #333;
  }

  b {
    color: #411564;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    padding: 20px 25px !important;
  }

  .recharts-tooltip-label {
    color: #333;
  }

  .recharts-legend-item-text {
    color: #333;
  }

  .recharts-wrapper {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
  }

  .recharts-surface {
    /* padding-right: 30px; */
    /* padding-bottom: 45px; */
  }
`;
