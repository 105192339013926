import React, { useState, useCallback, memo } from 'react';
import { MdSearch } from 'react-icons/md';

import { Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { SearchBoxContainer } from './styles';

const SearchBox = ({ onSearch, placeholder = 'Pesquisar', placeholderColor = '#333' }) => {
  const [value, setValue] = useState('');

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      onSearch(value);
    },
    [onSearch, value],
  );

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <SearchBoxContainer placeholderColor={placeholderColor}>
      <div id="button-search">
        <div onClick={handleSubmit} onKeyPress={handleKeyPress} role="button" tabIndex="0">
          <MdSearch size={24} />
        </div>
      </div>
      <Input
        type="text"
        value={value}
        name="Pesquisar"
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
        aria-label="Pesquisar"
        onKeyPress={handleKeyPress}
      />
    </SearchBoxContainer>
  );
};

SearchBox.propTypes = {
  onSearch: PropTypes.instanceOf(Object).isRequired,
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
};

SearchBox.defaultProps = {
  placeholder: 'Pesquisar',
  placeholderColor: '#333',
};

export default memo(SearchBox);
