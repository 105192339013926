import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { ReactModal } from '~/components';

const CleanContainer = ({ children }) => {
  const { modalOpen } = useSelector(state => state.modal);
  return (
    <>
      {children}
      {modalOpen && <ReactModal />}
    </>
  );
};

CleanContainer.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default memo(CleanContainer);
