import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form } from '@rocketseat/unform';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import Button from '../../components/Button';
import api from '../../services/api';
import { ContainerForm, Header, InputResume, CustomDiv } from './styles';

function FormEtapas({ data: dados, setRefresh }) {
  const [data] = useState(dados.dataLoad);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSubmit = async formData => {
    dispatch(toggleLoading(true));
    let save = {};
    if (!formData.id) {
      save = await api.post('tipoclassificacaocurriculo', { ...formData, dataCadastro: new Date() });
    } else {
      save = await api.put('tipoclassificacaocurriculo', {
        ...formData,
        dataAlteracao: new Date(),
      });
    }

    const { status, message } = save.data;
    if (status) {
      enqueueSnackbar(message, { variant: 'success' });
      setRefresh(refresh => !refresh);
      dispatch(toggleModal(false, null, null, null));
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
    dispatch(toggleLoading(false));
  };

  return (
    <ContainerForm>
      <Header>
        <h1>Tipo Classificação do Currículo</h1>
        <span>Configuração do tipo de classificação dos currículos</span>
      </Header>
      <Form onSubmit={handleSubmit} initialData={data}>
        <InputResume id="id" name="id" style={{ display: 'none' }} />
        <CustomDiv>
          <label htmlFor="nome">Nome do tipo de classificação</label>
          <InputResume name="nome" id="nome" maxLength={100} />
        </CustomDiv>
        <Button handleClick={() => {}} text="Salvar" backColor="#5b5c65" color="#FFF" style={{ marginTop: '10px', right: '0' }} />
      </Form>
    </ContainerForm>
  );
}

FormEtapas.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default FormEtapas;
