import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { store } from '~/store';
import { toggleSnack } from '~/store/modules/snack/actions';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const variantColor = {
  success: '#00995d',
  warning: '#ffcb08',
  error: '#cc0505',
  info: '#0075c8',
};

const SnackBar = () => {
  const { icon, chave, message } = store.getState().snack;
  const snackOpen = useSelector(state => state.snack.snackOpen);
  const Icon = variantIcon[icon];
  const Color = variantColor[icon];
  const FontColor = icon === 'warning' ? '#333' : '#fff';
  const dispatch = useDispatch();

  return (
    <>
      <Snackbar
        key={chave}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => dispatch(toggleSnack(false, 'null', 'null', 'success'))}
        ContentProps={{
          'aria-describedby': 'message-id',
          style: { backgroundColor: Color },
        }}
        message={
          <span id="message-id" style={{ display: 'flex', alignItems: 'center', color: FontColor }}>
            <Icon style={{ opacity: 0.9, marginRight: '5px', fontSize: 20 }} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(toggleSnack(false, 'null', 'null', 'success'))}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default SnackBar;
