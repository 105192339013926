import React from 'react';

import { Container } from './styles';

const Evaluation = props => (
  <Container>
    <div>Im Evaluation</div>
  </Container>
);

export default Evaluation;
