import React from 'react';
import { Switch } from 'react-router-dom';

import * as Router from '../configs/Routes';
import BancoCurriculos from '../pages/BancoCurriculos';
import CategoriaCompetencia from '../pages/CaracteristicaCompetencia';
import Curriculos from '../pages/Curriculos';
import AdminResumeViewer from '../pages/Curriculos/AdminResumeViewer';
import ResumeViewer from '../pages/Curriculos/ResumeViewer';
import ErrosSugestao from '../pages/ErrosSugestao';
import Etapas from '../pages/Etapas';
import EtapasProcessoSeletivo from '../pages/EtapasProcessoSeletivo';
import JobViewer from '../pages/JobViewer';
import Login from '../pages/Login';
import Main from '../pages/Main';
import MyProcess from '../pages/MeusProcessos';
import NotFound from '../pages/NotFound';
import PreviewBox from '../pages/PreviewBox';
import Profile from '../pages/Profile';
import Recovery from '../pages/Recovery';
import Setores from '../pages/Setores';
import Settings from '../pages/Settings';
import SignIn from '../pages/SignIn';
import SignUpVerification from '../pages/SignUpVerification';
import TipoClassificacaoCurriculo from '../pages/TipoClassificacaoCurriculo';
import TipoUsuario from '../pages/TipoUsuario';
import Usuarios from '../pages/Usuarios';
import Vagas from '../pages/Vagas';
import Route from './Route';

const Routes = () => (
  <Switch>
    <Route path={Router.JOBS} component={JobViewer} />
    <Route path={Router.LOGIN} exact component={Login} />
    <Route path={Router.PREVIEW_BOX} component={PreviewBox} />
    <Route path={Router.RECOVERY} component={Recovery} />
    <Route path={Router.SIGNUP} component={SignIn} />

    <Route path={Router.ADD_JOBS} component={Vagas} isPrivate />
    <Route path={Router.ADMIN_RESUME_VIEWER} component={AdminResumeViewer} isPrivate />
    <Route path={Router.CATEGORY_SKILLS} component={CategoriaCompetencia} isPrivate />
    <Route path={Router.DEPARTMENT} component={Setores} isPrivate />
    <Route path={Router.BUG_REPORT} component={ErrosSugestao} isPrivate />
    <Route path={Router.HOME} component={Main} isPrivate />
    <Route path={Router.MY_PROCESS} component={MyProcess} isPrivate />
    <Route path={Router.PROFILE} component={Profile} isPrivate />
    <Route path={Router.RESUME} component={Curriculos} isPrivate />
    <Route path={Router.RESUME_STORE} component={BancoCurriculos} isPrivate />
    <Route path={Router.RESUME_VIEWER} component={ResumeViewer} isPrivate />
    <Route path={Router.SELECTIVE_PROCESS} component={EtapasProcessoSeletivo} isPrivate />
    <Route path={Router.SETTINGS} component={Settings} isPrivate />
    <Route path={Router.STEPS} component={Etapas} isPrivate />
    <Route path={Router.TYPE_RESUME_CLASSIFICATION} component={TipoClassificacaoCurriculo} isPrivate />
    <Route path={Router.VERIFY_EMAIL} component={SignUpVerification} isPrivate />
    <Route path={Router.USERS_GROUP} component={TipoUsuario} isPrivate />
    <Route path={Router.USERS} component={Usuarios} isPrivate />

    <Route path="*" component={() => <NotFound />} />
  </Switch>
);

export default Routes;
