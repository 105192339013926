import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Container, Button } from './styles';

const Buttuni = ({ text, handleClick, color, backColor }) => (
  <Container>
    <Button onClick={handleClick} color={color} backColor={backColor} type="submit">
      {text}
    </Button>
  </Container>
);

Buttuni.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  backColor: PropTypes.string,
};

Buttuni.defaultProps = {
  color: '',
  backColor: '',
};

export default memo(Buttuni);
