import { combineReducers } from 'redux';

import alert from './alert/reducer';
import auth from './auth/reducer';
import main from './main/reducer';
import modal from './modal/reducer';
import snack from './snack/reducer';
import user from './user/reducer';

export default combineReducers({
  alert,
  auth,
  main,
  modal,
  snack,
  user,
});
