import styled from 'styled-components';

export const Container = styled.span`
	.input-style {
		width: 100%;
		display: block !important;
		background-color: rgb(242, 245, 250) !important;
		color: rgb(38, 43, 51) !important;
		pointer-events: unset !important;
		border-radius: 3px !important;
		padding: 12px !important;
		text-align: center;
		outline: none !important;
		border-width: 0px !important;
		border-style: initial !important;
		border-color: initial !important;
		border-image: initial !important;
		transition: color 0.1s ease 0s !important;
		font-weight: bold !important;

		div {
			margin-left: 0 !important;
			margin-top: 0px !important;
			width: 100%;

			&:before {
				border: none !important;
			}

			&:after {
				border: none !important;
			}
		}

		input {
			border: none !important;
			font-size: 14px !important;
			margin-left: -6px !important;
			margin-top: 2px !important;
		}

		button {
			padding: 0px;
		}
	}

	.react-datepicker__year-select,
	.react-datepicker__month-select {
		border: none !important;
		border-radius: 5px !important;
		padding: 5px 10px !important;
		font-family: 'Roboto';
		outline: 0 !important;
	}

	.react-datepicker-wrapper {
		display: grid !important;
	}
`;
