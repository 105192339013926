import styled from 'styled-components';

export const Container = styled.div`
  background: #c4cbcf;
  position: ${props => (props.showConfig ? 'relative' : 'fixed')};
  top: ${props => (props.showConfig ? '0%' : '100%')};
  width: 96%;
  height: 100%;
  opacity: ${props => (props.showConfig ? 1 : 0)};
  /* top: 20px; */
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
  border-radius: 5px;
  padding: 15px;
  color: #222;
  transition: all 1s cubic-bezier(0, 1.48, 0.43, 0.79);
  margin: 5px 0 10px 0;

  .one-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  .valor-etapa {
    margin-bottom: 10px;
  }

  label {
    text-align: left;
  }

  .config-etapa {
    input {
      padding: 5px !important;
    }
  }
`;
