/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';

import { Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Container, DivPosterior } from './styles';

const RatingSlide = ({ onChange, data, nivel, name, handleSubmit }) => (
  <Container color={nivel.cor}>
    {data &&
      data.map(item => (
        <label
          htmlFor="nivel"
          key={`${item.value}-${item.label}`}
          color={nivel.cor}
          onClick={() => {
            onChange(item);
            handleSubmit(null, 1);
          }}
        >
          <Input type="radio" key={item.value} id={item.value} name={name} value={nivel.value} color={nivel.cor} />
        </label>
      ))}
    <DivPosterior translateX={(nivel.value - 1) * 100} quantidade={data.length} color={nivel.cor} />
  </Container>
);

RatingSlide.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  nivel: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
};

export default memo(RatingSlide);
