import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdInfo, MdArrowForward, MdDeleteSweep, MdSettings } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { Form, Scope } from '@rocketseat/unform';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { store } from '~/store';
import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import Button from '../../components/Button';
import DatePickerMaterial from '../../components/DatePickerMaterial';
import ReactInput from '../../components/ReactInput';
import ReactSelect from '../../components/ReactSelect';
import ReactSwitch from '../../components/ReactSwitch';
import api from '../../services/api';
import ConfiguracaoEtapa from './ConfiguracaoProcesso';
import schema from './Schema';
import { Container, Header, FormContainer, CustomDiv, ListaEtapas, ListaEtapasExistentes, ListHeader, ComboDiv } from './styles';

const ProcessoSeletivo = ({ data, setRefresh }) => {
  const {
    dataLoad: { etapaProcessoSeletivos },
    etapas: etapaList,
  } = data;
  const [setores] = useState(data.setores);
  const [dados, setDados] = useState(data.dataLoad);
  const [coordenadores] = useState(data.coordenadores);
  const [, setDivulgarVaga] = useState(true);
  const [etapas, setEtapas] = useState([]);
  const [caracteristicas] = useState(data.caracteristicas);
  const [lists, setList] = useState([]);
  const [loadEtapas, setLoadEtapas] = useState(false);
  const [percentualConfigurado, setPercentualConfigurado] = useState([]);
  const [showConfig, setShowConfig] = useState('');
  const { profile: userData } = store.getState().user;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const sendStepValue = (etapaProcessoSeletivoId, value) => {
    if (!value) {
      const percentualSemAEtapaZerada = percentualConfigurado.filter(p => p.etapaProcessoSeletivoId !== etapaProcessoSeletivoId);
      setPercentualConfigurado(percentualSemAEtapaZerada);
    } else {
      setPercentualConfigurado(percentual => [
        ...percentual.filter(p => p.etapaProcessoSeletivoId !== etapaProcessoSeletivoId),
        { etapaProcessoSeletivoId, value: parseInt(value) },
      ]);
    }
  };

  useEffect(() => {
    if (data.dataLoad.id) {
      setDivulgarVaga(Boolean(data.dataLoad.divulgarVaga));

      etapaProcessoSeletivos.forEach((item, index) => {
        if (item.valorEtapa) {
          sendStepValue(item.id, item.valorEtapa);
        }

        setList(lst => [
          ...lst,
          {
            id: item.id,
            etapaId: item.etapaId,
            sequencia: index + 1,
            title: item.etapa.nome,
            dataCadastro: item.dataCadastro,
            processoSeletivoId: dados.id,
            valorEtapa: item.valorEtapa,
            caracteristicaEtapaProcessos: item.caracteristicaEtapaProcessos,
            lancarValor: item.lancarValor,
            aptoInapto: item.aptoInapto,
          },
        ]);
      });
    }
    setLoadEtapas(true);
  }, []); // eslint-disable-line

  useEffect(() => {
    setDados({ ...dados, etapaProcessoSeletivos: lists });
  }, [lists]); // eslint-disable-line

  useEffect(() => {
    if (loadEtapas) {
      etapaList.forEach(etp => {
        if (!Object.values(lists).some(k => k.title === etp.title)) {
          setEtapas(e => [...e, etp]);
        }
      });
    }
  }, [loadEtapas]); // eslint-disable-line

  const handleSubmit = async formData => {
    dispatch(toggleLoading(true));
    let save = {};

    if (!formData.id) {
      save = await api.post('processoseletivo', {
        ...formData,
        dataCadastro: new Date(),
        usuarioCadastro: userData.id,
        dataInicio: new Date(formData.dataInicio),
        boardListId: 1,
        mediaAprovacao: parseInt(formData.mediaAprovacao),
        divulgarVaga: +formData.divulgarVaga,
      });
    } else {
      save = await api.put('processoseletivo', {
        ...formData,
        dataAlteracao: new Date(),
        usuarioAlteracao: userData.id,
        dataInicio: new Date(formData.dataInicio),
        mediaAprovacao: parseInt(formData.mediaAprovacao),
        divulgarVaga: +formData.divulgarVaga,
      });
    }

    const { status, message } = save.data;
    if (status) {
      enqueueSnackbar(message, { variant: 'success' });
      setRefresh(refresh => !refresh);
      dispatch(toggleModal(false, null, null, null));
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
    dispatch(toggleLoading(false));
  };

  return (
    <Container>
      <Header>
        <h1>Processo Seletivo</h1>
        <span>Esse cadastro irá aparecer no anúncio de vagas a partir da data de divulgação.</span>
      </Header>
      <FormContainer>
        <Scrollbars
          style={{ height: '100%', width: '100%' }}
          renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
        >
          <Form onSubmit={handleSubmit} initialData={dados} schema={schema}>
            <ReactInput id="id" name="id" style={{ display: 'none' }} />
            <CustomDiv>
              <label htmlFor="nome">Nome da Vaga</label>
              <ReactInput name="nome" id="nome" maxLength={250} />
            </CustomDiv>
            <CustomDiv>
              <label htmlFor="setorId">Setor</label>
              <ReactSelect
                options={setores}
                multiple={false}
                name="setorId"
                id="setorId"
                defaultValue={null}
                label={null}
                closeOnSelect
                fieldPointer="setorId"
              />
            </CustomDiv>
            <CustomDiv>
              <label htmlFor="coordenadorId">Responsável pelo processo</label>
              <ReactSelect
                options={coordenadores}
                multiple={false}
                name="coordenadorId"
                id="coordenadorId"
                defaultValue={null}
                label={null}
                closeOnSelect
                fieldPointer="coordenadorId"
              />
            </CustomDiv>
            <CustomDiv>
              <label htmlFor="quantidade">Quantidade de Vagas Ofertadas</label>
              <ReactInput name="quantidade" id="quantidade" maxLength={20} />
            </CustomDiv>
            <CustomDiv>
              <label htmlFor="salario">Salário</label>
              <ReactInput name="salario" id="salario" maxLength={20} />
            </CustomDiv>
            <CustomDiv>
              <ComboDiv>
                <div>
                  <label htmlFor="dataInicio">Início da Divulgação</label>
                  <DatePickerMaterial name="dataInicio" dateFormat="dd/MM/yyyy" type="keyboardDate" />
                </div>
                <div>
                  <label htmlFor="mediaAprovacao">Média para aprovação</label>
                  <ReactInput name="mediaAprovacao" id="mediaAprovacao" maxLength={20} type="number" />
                </div>
              </ComboDiv>
            </CustomDiv>
            <CustomDiv>
              <label htmlFor="horario">Horário</label>
              <ReactInput name="horario" id="horario" maxLength={500} />
            </CustomDiv>
            <CustomDiv>
              <label htmlFor="divulgarVaga">
                Divulgar Vaga (Caso não queira que a vaga fique disponível publicamente, deixe como não divulgar)
              </label>
              <ReactSwitch
                name="divulgarVaga"
                textoLigado="A vaga será divulgada"
                textoDesligado="A vaga NÃO será divulgada"
                onChange={setDivulgarVaga}
              />
            </CustomDiv>
            <CustomDiv>
              <p>
                <MdInfo size={24} style={{ marginRight: '5px', color: '#ffcb08' }} />
                Caso queira que algum campo não apareça na descrição, basta deixá-lo em branco, pois os campos que não forem
                preenchidos, automaticamente não aparecerão na tela de descrição da vaga. Alguns campos não aparecerem na
                divulgação, como responsável pelo processo, média para aprovação e as etapas do processo seletivo.
              </p>
            </CustomDiv>
            <CustomDiv className="full-width">
              <label htmlFor="beneficios">Benefícios</label>
              <ReactInput name="beneficios" id="beneficios" multiline rows="5" />
            </CustomDiv>
            <CustomDiv className="full-width">
              <label htmlFor="descricaoCargo">Descrição das Atividades</label>
              <ReactInput name="descricaoCargo" id="descricaoCargo" multiline rows="5" maxLength={500} />
            </CustomDiv>
            <CustomDiv className="full-width">
              <label htmlFor="requisitosMinimos">Requisitos Mínimos</label>
              <ReactInput name="requisitosMinimos" id="requisitosMinimos" multiline rows="5" maxLength={700} />
            </CustomDiv>
            <CustomDiv className="full-width">
              <label htmlFor="experienciaDesejavel">Experiência Desejável</label>
              <ReactInput name="experienciaDesejavel" id="experienciaDesejavel" multiline rows="5" maxLength={500} />
            </CustomDiv>
            <CustomDiv className="full-width">
              <label htmlFor="conhecimentoDesejavel">Conhecimento Desejável</label>
              <ReactInput name="conhecimentoDesejavel" id="conhecimentoDesejavel" multiline rows="5" maxLength={500} />
            </CustomDiv>
            <CustomDiv className="full-width">
              <label htmlFor="complementaPerfil">Complementa o Perfil</label>
              <ReactInput name="complementaPerfil" id="complementaPerfil" multiline rows="5" maxLength={500} />
            </CustomDiv>
            <ListaEtapasExistentes>
              <h5>Adicione a etapa para a lista ao lado clicando na seta</h5>
              <ul>
                {etapas &&
                  etapas.map((item, index) => (
                    <li key={item.id} index={index}>
                      {item.title}{' '}
                      <MdArrowForward
                        size={24}
                        onClick={() => {
                          setList(list => [
                            ...list,
                            {
                              etapaId: item.id,
                              sequencia: list.length,
                              dataCadastro: new Date(),
                              title: item.title,
                              processoSeletivoId: dados.id,
                            },
                          ]);
                          setEtapas(etapasFilter => etapasFilter.filter(it => it.id !== item.id));
                        }}
                      />
                    </li>
                  ))}
              </ul>
            </ListaEtapasExistentes>
            <ListaEtapas>
              <h5>
                As etapas aparecerão nessa ordem | Percentual Total: ({' '}
                {percentualConfigurado ? percentualConfigurado.reduce((sum, cur) => sum + parseInt(cur.value), 0) : 0} ) %
              </h5>
              <ul>
                {lists &&
                  lists.map((item, index) => (
                    <li key={item.id ? item.id : index + 100000} index={index}>
                      <Scope path={`etapaProcessoSeletivos[${index}]`}>
                        <ListHeader>
                          <span className="step-sequence">{index + 1}</span>
                          <div className="title-etapa">{item.title}</div>
                          <div>
                            <MdSettings
                              size={24}
                              onClick={() => setShowConfig(show => (show === item.title ? '' : item.title))}
                            />
                            <MdDeleteSweep
                              size={24}
                              onClick={() => {
                                setList(list => list.filter((value, i) => i !== index));
                                setEtapas(etapa => [...etapa, { id: item.etapaId, title: item.title }]);
                              }}
                            />
                          </div>
                        </ListHeader>
                        <ConfiguracaoEtapa
                          options={caracteristicas}
                          showConfig={showConfig === item.title}
                          setShowConfig={setShowConfig}
                          sendStepValue={sendStepValue}
                          etapaProcessoSeletivoId={item.id}
                          title={item.title}
                          lancarValor={item.lancarValor}
                          aptoInapto={item.aptoInapto}
                        />
                      </Scope>
                    </li>
                  ))}
              </ul>
            </ListaEtapas>
            <div />
            <Button handleClick={() => {}} text="Salvar" backColor="#5b5c65" color="#FFF" style={{ marginTop: '10px' }} />
          </Form>
        </Scrollbars>
      </FormContainer>
    </Container>
  );
};

ProcessoSeletivo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default ProcessoSeletivo;
