import React, { memo } from 'react';
import { MdMenu } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import { store } from '~/store';
import { toggleSideDrawer } from '~/store/modules/main/actions';

import UserProfile from '../../assets/user.png';
import * as Router from '../../configs/Routes';
import { styles } from '../../configs/Theme';
import { Container, HeaderContainer, Dashboard, UserButton } from './styles';

function MainBar() {
  const dispatch = useDispatch();
  const { profile: user } = store.getState().user;
  const { paginaAtual, pageDescription, sideDrawerOpen } = useSelector(state => state.main);
  const { imageUrl } = useSelector(state => state.user.profile);

  return (
    <Container>
      <HeaderContainer>
        <Dashboard>
          <MdMenu
            size={24}
            style={{ marginRight: '15px', cursor: 'Pointer' }}
            onClick={() => dispatch(toggleSideDrawer(!sideDrawerOpen))}
          />
          <h2>#Unijob</h2>
          <div className="description">
            <h3>{paginaAtual}</h3>
            <small>{pageDescription}</small>
          </div>
        </Dashboard>
        <UserButton>
          <Link to={Router.PROFILE}>
            <div>
              <span>{user.name}</span>
              <span>{user.email}</span>
            </div>
            <img src={imageUrl === null || imageUrl === '' ? UserProfile : imageUrl} alt="Profile" />
          </Link>
        </UserButton>
      </HeaderContainer>
    </Container>
  );
}

const mainWithStyles = withStyles(styles)(MainBar);

export default memo(mainWithStyles);
