import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 24px;

  label {
    margin-bottom: 0.5rem !important;
  }

  .react-select-unimed {
    border: none !important;
    width: 100%;
    background-color: rgb(242, 245, 250);
  }

  .css-1rhbuit-multiValue {
    background: none !important;
  }

  div[class$='-control'] {
    background-color: rgb(242, 245, 250);
    border: none !important;
    font-size: 18px;
    min-height: 46px !important;
  }
`;
