import produce from 'immer';

const INITIAL_STATE = {
  paginaAtual: 'Painel de Informações',
  pageDescription: null,
  sideDrawerOpen: false,
  loading: false,
  alert: false,
  itemsHeight: 45,
  itemDivisor: 70,
  server: 'http://localhost:3334',
  autoSaveNotification: false,
  userPhotoNotification: false,
  requiredFieldsNotification: false,
  downloadNotification: false,
};

export default function main(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@main/SET_CURRENT_PAGE': {
        draft.paginaAtual = action.payload.paginaAtual;
        break;
      }
      case '@main/SET_PAGE_DESCRIPTION': {
        draft.pageDescription = action.payload.pageDescription;
        break;
      }
      case '@main/TOGGLE_SIDE_DRAWER': {
        draft.sideDrawerOpen = action.payload.sideDrawerOpen;
        break;
      }
      case '@main/TOGGLE_LOADING': {
        draft.loading = action.payload.loading;
        break;
      }
      case '@main/TOGGLE_ALERT': {
        draft.alert = action.payload.alert;
        break;
      }
      case '@main/SET_SERVER': {
        draft.server = action.payload.server;
        break;
      }
      case '@main/SET_REQUIRED_FIELDS_NOTIFICATIONS': {
        draft.requiredFieldsNotification = action.payload.requiredFieldsNotification;
        break;
      }
      case '@main/SET_USER_PHOTO_NOTIFICATIONS': {
        draft.userPhotoNotification = action.payload.userPhotoNotification;
        break;
      }
      case '@main/SET_AUTO_SAVE_NOTIFICATIONS': {
        draft.autoSaveNotification = action.payload.autoSaveNotification;
        break;
      }
      case '@main/SET_DOWNLOAD_NOTIFICATION': {
        draft.downloadNotification = action.payload.downloadNotification;
        break;
      }
      default:
    }
  });
}
