/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useCallback, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form } from '@rocketseat/unform';
import { format as formatCPF, validate } from 'gerador-validador-cpf';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { signUpRequest } from '~/store/modules/auth/actions';
import logo from '../../assets/logo.png';
import Input from '../../components/ReactInput';
import api from '../../services/api';
import { Container } from './styles';
import Modal from './Modal';
import CheckBox from './CheckBox';

function SignIn() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const checkCpfInDataBase = useCallback(
    async cpf => {
      if (!cpf) return true;
      const newCpf = cpf.replace(/[^0-9]/g, '');
      let resultado = false;
      try {
        const response = await api.get(`usuarios/cpf/${newCpf}`);
        const { data } = response.data;
        resultado = Boolean(data?.cpf);
        return !resultado;
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'warning' });
        return true;
      }
    },
    [enqueueSnackbar],
  );

  const checkEmailInDataBase = useCallback(
    async email => {
      if (!email) return true;
      let resultado = false;

      try {
        const response = await api.get(`usuarios/email/${email}`);
        const {
          data: { data: findedData },
        } = response;
        resultado = !!findedData;

        return !resultado;
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'warning' });
        return true;
      }
    },
    [enqueueSnackbar],
  );

  const schemaRegister = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Campo obrigatório')
      .email('Email digitado não é valido, verifique!')
      .test('usuario-error', 'E-mail inválido', value => !value.toLowerCase().includes('usuario'))
      .test('teste-error', 'E-mail inválido', value => !value.toLowerCase().includes('teste'))
      .test('teste-error', 'E-mail inválido', value => !value.toLowerCase().includes('test'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('hotmai.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('hotmal.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('gmai.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('gmal.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('autlook.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('outlok.com'))
      .test('email-check', 'Gmail não possui .br', value => !value.toLowerCase().includes('gmail.com.br'))
      .test('email-check', 'Hotmail não possui .br', value => !value.toLowerCase().includes('hotmail.com.br'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('123.com.br'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('1234.com.br'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('12345.com.br'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('123456.com.br'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('123.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('1234.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('12345.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().includes('123456.com'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().startsWith('123456'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().startsWith('12345'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().startsWith('1234'))
      .test('email-check', 'E-mail inválido', value => !value.toLowerCase().startsWith('123'))
      .test('email-check', 'E-mail já cadastrado', value => checkEmailInDataBase(value)),
    nome: Yup.string()
      .required('Campo obrigatório')
      .min(5, 'Mínimo 5 caracteres...')
      .test('usuario-error', 'Nome não é válido', value => !value.toLowerCase().includes('usuario'))
      .test('usuario-error', 'Nome não é válido', value => !value.toLowerCase().includes('usuário'))
      .test('usuario-error', 'Nome não é válido', value => !value.toLowerCase().includes('123456'))
      .test('usuario-error', 'Nome não é válido', value => !value.toLowerCase().includes('123'))
      .test('teste-error', 'Nome não é válido', value => !value.toLowerCase().includes('teste'))
      .test('test-error', 'Nome não é válido', value => !value.toLowerCase().includes('test')),
    cpf: Yup.string()
      .required('Campo obrigatório')
      .test('e-formatavel', 'CPF Inválido', value => formatCPF(value))
      .test('e-valido', 'CPF Inválido', value => validate(value))
      .test('ja-existe', 'CPF já cadastrado', value => checkCpfInDataBase(value).then(response => response)),
    senha: Yup.string()
      .required('Campo obrigatório')
      .min(5, 'Senha deve ter de 5 a 10 caracteres')
      .max(10, 'Senha deve ter de 5 a 10 caracteres'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('senha'), null], 'As senhas precisam ser iguais'),
    emailConfirmation: Yup.string()
      .trim()
      .oneOf([Yup.ref('email'), null], 'Os emails precisam ser iguais'),
  });

  const handleSubmitRegister = useCallback(
    ({ nome, email, senha, cpf }) => {
      dispatch(signUpRequest(nome, email.trim(), senha, cpf));
      // enqueueSnackbar('Servidor Indisponível neste momento', { variant: 'error' });
    },
    [dispatch],
  );
  const [show, setShow] = useState(false);

  return (
    <Container>
      <div>
        <img src={logo} className="logo" alt="Unimed" />
        <Form schema={schemaRegister} id="register" className="login" tabIndex="502" onSubmit={handleSubmitRegister}>
          <Input type="text" name="nome" placeholder="Nome Completo" />
          <Input type="mail" name="email" placeholder="Email" />
          <Input
            type="mail"
            name="emailConfirmation"
            placeholder="Confirmar Email"
            onPaste={e => {
              e.preventDefault();
              return false;
            }}
          />
          <Input type="text" name="cpf" placeholder="CPF" />
          <Input type="password" name="senha" placeholder="Senha" />
          <Input type="password" name="passwordConfirmation" placeholder="Confirmar Senha" />
          <div className="submit">
            <button className="dark" type="submit">
              {loading ? 'Carregando...' : 'Registrar'}
            </button>
          </div>
          <label>
              <div>
                <CheckBox
                  label="Eu aceito que meus dados serão utilizados nos processos..."
                />
                {/* <a className='check' name='check' onClick={abrirModal}>Acesse aqui o termo!!!</a> */}
              </div>
              <div className='App'>
                <button className="botaoModal" onClick={() => setShow(true)}>Show Modal!!!</button>
                <Modal show={show} />
              </div>
                {/* <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={fecharModal}
                    contentLabel="Modal1">
                      <h1 >Termo LGPD que ainda será feito!</h1>
                    <div className='fechar'>
                    <button
                      onClick={fecharModal}>Fechar</button>
                    </div>
                </Modal> */}
            </label>
          <h6>
            <Link className="link" to="/">
              Voltar
            </Link>
          </h6>
          <div>
            {/* <ModalCss> */}

            {/* </ModalCss> */}
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default memo(SignIn);
