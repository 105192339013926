import React, { memo, useState, useCallback, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { differenceInYears } from 'date-fns';
import PropTypes from 'prop-types';

import UserProfile from '../../assets/user.png';
import { storage } from '../../firebase';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 290,
  },
  media: {
    height: 210,
    objectFit: 'cover',
  },
  typography: {
    color: '#411564',
    fontSize: '16px',
  },
});

const MediaCard = ({ data: { curriculo }, showForm }) => {
  const { estadoCivil, usuario } = curriculo;
  const [imageUrl, setImageUrl] = useState();

  const fetchUserPhoto = useCallback(async () => {
    if (!usuario) return;
    try {
      setImageUrl(await storage.doUserPhotoUrlLink(usuario.id));
    } catch (error) {
      // do nothing
    }
  }, [usuario]);

  useEffect(() => {
    fetchUserPhoto();
  }, [fetchUserPhoto]);
  const classes = useStyles();

  return (
    <Card className={classes.root} onClick={showForm}>
      <CardActionArea>
        <CardMedia className={classes.media} image={imageUrl || UserProfile} title="User Photo" />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6" className={classes.typography}>
            {usuario.nome.toUpperCase()}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {differenceInYears(new Date(), new Date(curriculo.dataNascimento))} anos {estadoCivil && estadoCivil.nome}{' '}
            {!curriculo.possuiFilhos ? 'não possui' : 'possui'} filhos, {curriculo.disponibilidadeHorarioTurno ? '' : 'não'} tem
            disponibilidade para trabalhar em turnos, atualmente está{' '}
            {curriculo.estaTrabalhandoAtualmente ? 'trabalhando' : 'desempregado'}.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Abrir Dados
        </Button>
      </CardActions>
    </Card>
  );
};

MediaCard.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  showForm: PropTypes.func.isRequired,
};

export default memo(MediaCard);
