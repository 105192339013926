/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect, useMemo, useRef, useCallback, memo } from 'react';
import {
  MdPowerSettingsNew,
  MdReportProblem,
  MdPermIdentity,
  MdFingerprint,
  MdDeleteForever,
  MdExitToApp,
  MdInfo,
  MdPhotoCamera,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { FileInput } from '@rocketseat/unform';
import { useSnackbar } from 'notistack';

import { store } from '~/store';
import { signOut } from '~/store/modules/auth/actions';
import { toggleLoading, setCurrentPage } from '~/store/modules/main/actions';
import { updateUserImageURL } from '~/store/modules/user/actions';

import UserProfile from '../../assets/user.png';
import { auth, storage } from '../../firebase';
import api from '../../services/api';
import { SelectedView } from './route-view';
import {
  Container,
  SubContainer,
  LeftSide,
  RightSide,
  PhotoContainer,
  ButtonContainer,
  ImageContainer,
  FieldInput,
  CustomButton,
  DeleteButton,
} from './styles';

function Profile() {
  const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
  const [activePage] = useState(1);
  const itemsPerPage = parseInt(global.window.innerHeight - 208) / 49;
  const { enqueueSnackbar } = useSnackbar();
  const [formUserData, setFormUserData] = useState([]);
  const [itemView, setItemView] = useState('UserData');
  const [userPhoto, setUserPhoto] = useState(null);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();

  const preview = useMemo(() => userPhoto || null, [userPhoto]);
  const righSideRef = useRef(null);

  const { profile: user } = store.getState().user;

  // const imageMaxSize = 2000000000;
  // const acceptedFileTypesArray = acceptedFileTypes.split(',').map(item => item.trim());
  const fileInputRef = useRef(null);
  // const [imgSrc, setImgSrc] = useState();
  // const [imgSrcExt, setImgSrcExt] = useState();
  // const [crop] = useState({ aspct: 1 / 1 });

  // const verifyFile = files => {
  //   if (files && files.length > 0) {
  //     const currentFile = files[0];
  //     const currentFileType = currentFile.type;
  //     const currentFileSize = currentFile.size;
  //     if (currentFileSize > imageMaxSize) {
  //       alert(`Este arquivo não é permitido. ${currentFileSize} este tamanho é muito grande.`);
  //       return false;
  //     }
  //     if (!acceptedFileTypesArray.includes(currentFileType)) {
  //       alert('Este arquivo não é permitido. Somente é permitido arquivos de imagens.');
  //       return false;
  //     }
  //     return true;
  //   }
  // };

  const fetchPhoto = useCallback(async () => {
    const { data } = await api.get(`loginhistory/${itemsPerPage}/${parseInt((activePage - 1) * itemsPerPage)}`, {
      headers: { id: user.id },
    });
    const { data: userLogin } = data;
    setFormUserData({ nome: user.name, cpf: user.cpf, loginHistory: userLogin, ...user });
    setUserId(user.id);
    try {
      const imageUrl = await storage.doUserPhotoUrlLink(user.id);
      setUserPhoto(imageUrl);
    } catch (error) {
      // do nothing
    }
  }, [activePage, itemsPerPage, user]);

  useEffect(() => {
    dispatch(setCurrentPage('Perfil'));
    fetchPhoto();
  }, [activePage, dispatch, fetchPhoto, itemsPerPage]);

  const submitPasswordHandler = useCallback(
    async (data, { resetForm }) => {
      dispatch(toggleLoading(true));
      try {
        if (data.novaSenha !== data.confirmarSenha) {
          enqueueSnackbar('Nova senha digitada não confere', { variant: 'warning' });
          return;
        }
        auth
          .doSignInWithEmailAndPassword(formUserData.email, data.senhaAntiga)
          .then(() => {
            auth
              .doPasswordUpdate(data.confirmarSenha)
              .then(() => {
                enqueueSnackbar('Senha atualizada com sucesso', { variant: 'success' });
              })
              .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
              });
          })
          .catch(() => {
            enqueueSnackbar('Senha atual não confere', { variant: 'warning' });
          });
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [dispatch, enqueueSnackbar, formUserData.email],
  );

  const submitErrorHandler = useCallback(
    async (data, { resetForm }) => {
      dispatch(toggleLoading(true));
      const error = { ...data, usuarioId: formUserData.id, dataCadastro: new Date(), usuarioCadastro: formUserData.id };
      const { data: retorno } = await api.post('errosreportados', error);
      if (retorno.status) {
        enqueueSnackbar(retorno.message, { variant: 'success' });
      } else {
        enqueueSnackbar(retorno.message, { variant: 'error' });
      }
      resetForm();
      dispatch(toggleLoading(false));
    },
    [dispatch, enqueueSnackbar, formUserData.id],
  );

  const submitUpdateEmailHandler = useCallback(
    async data => {
      dispatch(toggleLoading(true));
      try {
        await auth.doEmailUpdate(data.email);
        await api.put('usuarios', { id: userId, email: data.email });
        enqueueSnackbar(
          'Email atualizado com sucesso. Pedimos para sair e acessar o sistema novamente para atualizar a interface.',
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [dispatch, enqueueSnackbar, userId],
  );

  const submitDeleteProfileHandler = useCallback(async () => {
    enqueueSnackbar(
      'No momento ainda estamos desenvolvendo esta funcionalidade, se realmente deseja deletar seus dados, favor solicitar no formulário de sugestão',
      { variant: 'info' },
    );
  }, [enqueueSnackbar]);

  const handleDeletePhoto = useCallback(async () => {
    try {
      await storage.doRemoveUserPhoto(formUserData.id);
      setUserPhoto(null);
      dispatch(updateUserImageURL(null));
      enqueueSnackbar('Photo removida com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [dispatch, enqueueSnackbar, formUserData.id]);

  const handleProgress = useCallback(
    async (progress, event) => {
      try {
        dispatch(toggleLoading(true));
        if (progress !== 100) return;

        await storage.doUploadUserPhoto(event.currentTarget.result, formUserData.id);
        const imageUrl = await storage.doUserPhotoUrlLink(formUserData.id);
        setUserPhoto(imageUrl);
        dispatch(updateUserImageURL(imageUrl));
        enqueueSnackbar('Foto salva com sucesso', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        dispatch(toggleLoading(false));
      }
    },
    [dispatch, enqueueSnackbar, formUserData],
  );

  const getView = useCallback(
    () =>
      SelectedView[itemView]({
        submitPasswordHandler,
        submitErrorHandler,
        formUserData,
        submitUpdateEmailHandler,
        submitDeleteProfileHandler,
      }),
    [formUserData, itemView, submitDeleteProfileHandler, submitErrorHandler, submitPasswordHandler, submitUpdateEmailHandler],
  );

  return (
    <Container>
      {formUserData && (
        <SubContainer>
          <div style={{ display: 'flex' }}>
            <LeftSide>
              <PhotoContainer>
                <ImageContainer>
                  <FileInput
                    ref={fileInputRef}
                    name="file"
                    accept={acceptedFileTypes}
                    multiple={false}
                    onStartProgress={handleProgress}
                    onChange={event => setUserPhoto(event.target.files[0])}
                    // onChange={event => fileChangeHandler(event)}
                    style={{ cursor: 'pointer' }}
                  />
                  <FieldInput>
                    <MdPhotoCamera size={20} />
                  </FieldInput>
                  <img src={preview || UserProfile} alt="Profile" />
                  {preview && <DeleteButton onClick={handleDeletePhoto}>Excluir Foto</DeleteButton>}
                </ImageContainer>
                <span>{formUserData.nome}</span>
              </PhotoContainer>
              <ButtonContainer>
                <CustomButton
                  ativo={itemView === 'UserData'}
                  color="#fff"
                  onClick={() => {
                    setItemView('UserData');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  <p>Perfil</p>
                  <MdPermIdentity size={24} />
                </CustomButton>
                <CustomButton
                  ativo={itemView === 'PasswordConfiguration'}
                  color="#fff0c7"
                  onClick={() => {
                    setItemView('PasswordConfiguration');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  <p>Senha Secreta</p>
                  <MdFingerprint size={24} />
                </CustomButton>
                <CustomButton
                  ativo={itemView === 'ErrorReport'}
                  color="#ffcb08"
                  onClick={() => {
                    setItemView('ErrorReport');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  <p>Erros / Sugestões</p>
                  <MdReportProblem size={24} />
                </CustomButton>
                <CustomButton
                  ativo={itemView === 'DeleteData'}
                  color="#f47920"
                  onClick={() => {
                    setItemView('DeleteData');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  <p>Excluir Meus Dados</p>
                  <MdDeleteForever size={24} />
                </CustomButton>
                <CustomButton
                  ativo={itemView === 'LoginHistory'}
                  color="#a3238e"
                  onClick={() => {
                    setItemView('LoginHistory');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  <p>Histórico de Acesso</p>
                  <MdExitToApp size={24} />
                </CustomButton>
                <CustomButton
                  ativo={itemView === 'About'}
                  color="#00995d"
                  onClick={() => {
                    setItemView('About');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  <p>Sobre o Sistema</p>
                  <MdInfo size={24} />
                </CustomButton>
                <CustomButton color="#e61c1c" onClick={() => dispatch(signOut())}>
                  <p>Sair do Sistema</p>
                  <MdPowerSettingsNew size={24} />
                </CustomButton>
              </ButtonContainer>
            </LeftSide>
            <RightSide ref={righSideRef}>{getView()}</RightSide>
          </div>
        </SubContainer>
      )}
    </Container>
  );
}

export default memo(Profile);
