import styled from 'styled-components';

export const Container = styled.div`
  z-index: 999999 !important;

  margin-bottom: 24px;

  .MuiFormControl-root {
    width: 100%;
    background: rgb(242, 245, 250);
    border-radius: 3px;
  }

  .MuiInputBase-input {
    width: 100%;
    display: block !important;
    background-color: rgb(242, 245, 250) !important;
    color: rgb(38, 43, 51) !important;
    pointer-events: unset !important;
    border-radius: 3px !important;
    padding: 12px !important;
    text-align: center;
    outline: none !important;
    border-width: 0px !important;
    border-style: initial !important;
    border-color: initial !important;
    border-image: initial !important;
    transition: color 0.1s ease 0s !important;
    /* font-weight: bold !important; */
    border: none !important;
    font-size: 18px !important;
  }

  .MuiInput-underline {
    &:before {
      border: none !important;
    }
    &:after {
      border: none !important;
    }
  }
`;
