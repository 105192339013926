/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentPage } from '~/store/modules/main/actions';

import BugIcon from '../../assets/bug.svg';
import DepartmentIcon from '../../assets/department.svg';
import ProcessStepsIcon from '../../assets/process-steps.svg';
import ResumeClassificationIcon from '../../assets/resume-classification.svg';
import CaracteristicaIcon from '../../assets/skills.svg';
import UserGroupIcon from '../../assets/user-group.svg';
import UserIcon from '../../assets/user.svg';
import * as routes from '../../configs/Routes';
import { Container, UpContainer, CardContainer } from './styles';

const Settings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPage('Configurações'));
  }, []); // eslint-disable-line

  return (
    <Container>
      <UpContainer>
        <div>
          <CardContainer to={routes.CATEGORY_SKILLS}>
            <div>
              <img src={CaracteristicaIcon} alt="Ícone de Permissões" />
              <h4>Características e Competências</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.STEPS}>
            <div>
              <img src={ProcessStepsIcon} alt="Ícone de Etapas" />
              <h4>Etapas do Processo</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.USERS_GROUP}>
            <div>
              <img src={UserGroupIcon} alt="Ícone de Grupo de Usuários" />
              <h4>Grupo de Usuários</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.DEPARTMENT}>
            <div>
              <img src={DepartmentIcon} alt="Ícone de Tipo de Classificação de Currículo" />
              <h4>Setores</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.TYPE_RESUME_CLASSIFICATION}>
            <div>
              <img src={ResumeClassificationIcon} alt="Ícone de Tipo de Classificação de Currículo" />
              <h4>Tipo Classificação Currículo</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.USERS}>
            <div>
              <img src={UserIcon} alt="Ícone de Usuário" />
              <h4>Usuários</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.BUG_REPORT}>
            <div>
              <img src={BugIcon} alt="Ícone de Usuário" />
              <h4>Erros / Sugestões</h4>
            </div>
          </CardContainer>
        </div>
      </UpContainer>
    </Container>
  );
};
export default memo(Settings);
