import styled from 'styled-components';

export const Container = styled.div`
	background-color: ${({ color }) => `rgba(${color}, 0.1)`};
	display: flex;
	position: relative;
	height: 46px;
	transform: translateZ(0px);
	pointer-events: auto;
	overflow: hidden;
	border-radius: 4px;
	transition: background-color 0.2s ease 0s;

	label {
		display: inline-block;
		position: relative;
		-webkit-box-flex: 1;
		flex-grow: 1;
		cursor: pointer;
		touch-action: manipulation !important;
		margin-bottom: 0 !important;

		&:before {
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			right: -1px;
			height: 100%;
			background-color: ${({ color }) => `rgba(${color}, 1)`};
			opacity: 0;
			transition: opacity 0.1s ease 0s;
			border-radius: 4px;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0px;
			top: 50%;
			width: 1px;
			height: 14px;
			margin-top: -7px;
			background-color: ${({ color }) => `rgba(${color}, 1)`};
			opacity: 0.4;
		}

		&:first-child::after {
			display: none;
		}

		&:hover::before {
			opacity: 0.1;
		}

		input {
			position: absolute;
			top: 0px;
			left: 0px;
			opacity: 0;
			pointer-events: none;
			-webkit-rtl-ordering: logical;
			-webkit-appearance: radio;
			background-color: transparent;
			color: inherit;
			font-size: inherit;
			line-height: inherit;
			overflow: visible;
			touch-action: manipulation;
			box-sizing: border-box;
			padding: 0;
		}
	}
`;

export const DivPosterior = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: ${props => `${100 / props.quantidade}%`};
	height: 100%;
	will-change: transform, opacity;
	opacity: 1;
	transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
	transform: ${({ translateX }) => `translateX(${translateX}%)`};

	&:before {
		content: '';
		position: absolute;
		left: -3px;
		right: -3px;
		top: 0px;
		height: 100%;
		border-radius: 4px;
		transition: background-color 0.2s ease 0s;
		background-color: ${({ color }) => `rgba(${color}, 1)`};
	}
`;
