import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  justify-content: center;
  align-content: center;
  overflow: auto;
  background: #ddd;

  h1 {
    color: #b1d34b;
    font-size: 65px;
  }

  h6 {
    color: #fff;
    margin-bottom: 30px !important;
    font-weight: 400;
    font-size: 18px;

    a {
      color: #ffcb08;
      text-decoration: underline;
      text-decoration-color: rgba(255, 203, 8, 0.5);
      text-underline-position: under;
    }
  }

  @media (max-width: 320px) {
    h1 {
      color: #b1d34b;
      font-size: 25px;
    }

    h6 {
      color: #fff;
      margin-bottom: 30px !important;
      font-weight: 400;
      font-size: 12px;

      a {
        color: #ffcb08;
        text-decoration: solid !important;
        text-decoration-color: rgba(255, 203, 8, 0.5);
      }
    }
  }
`;

export const ListContainer = styled.ul`
  /* margin-top: -60px; */
  z-index: 1;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: -30px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    clear: both;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .no-jobs {
    font-size: 40px;
    font-weight: bold;
    margin-top: 100px;
    color: #111;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    line-height: 45px;
    padding: 0 20px;

    @media (max-width: 768px) {
      display: block;
      overflow-wrap: break-word;
      word-wrap: break-word;
      width: 100vw;
      height: 100vh;

      p {
        white-space: pre-wrap;
      }
    }
  }
`;

export const ListItem = styled.li`
  margin: auto;
  width: 70%;
  padding: 10px 20px;
  background: #222;
  cursor: pointer;
  border-radius: 3px;
  list-style: none;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  margin-bottom: 10px;
  font-size: 16px;
  border: 2px solid rgba(0, 0, 0, 0.001);
  color: #ccc;
  overflow: hidden;

  .container-description-jobs {
    display: flex;
    justify-content: space-between;
  }

  span {
    color: #fff0c7;
    border-bottom: 2px solid rgba(200, 200, 200, 0.2);
  }

  &:hover {
    border: 2px solid rgba(177, 211, 75, 0.5);
  }

  @media (max-width: 320px) {
    width: 150px;
    height: 100px;

    .job-name {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    background: #fff;
    position: relative;
    /* background: linear-gradient(90deg, rgba(244, 121, 32, 1) 10%, rgba(255, 255, 255, 1) 10%); */
    /* padding-left: 13%; */
    color: #333;
    width: 82%;
    min-height: 170px;
    /* max-height: 150px; */
    margin-top: 8px;
    margin-left: 15px;
    border-radius: 15px;
    border: none;
    overflow: hidden;
    clear: both;
    display: inline-flex;
    flex-direction: column;
    padding: 0;

    .container-description-jobs {
      position: relative;
      height: 170px;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center;
      align-content: center;
      align-items: flex-start !important;
      z-index: 1;
      clear: both;

      &:before {
        content: '';
        position: absolute;
        background: #f47920;
        margin-left: -20px;
        width: 20%;
        height: 100%;
        border-radius: 0 100% 100% 0 / 0 50% 50% 0;
        transform: scaleY(1.5);
        z-index: -1;
      }
    }

    .job-name {
      white-space: normal;
      word-wrap: break-word;
      font-size: 1.4rem;
      padding: 0 2% 0 18%;
      overflow: hidden;
      height: 90px;
    }

    span {
      font-size: 1.2rem;
      color: rgba(65, 21, 100, 1);
      border-bottom: 2px solid rgba(65, 21, 100, 0.2);
      margin-left: 18%;
    }

    &:last-child {
      margin-right: 15px;
    }

    &:hover {
      border: none;
    }
  }
`;

export const HeaderContainer = styled.div`
  padding: 30px 30px 10px 30px;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 55vh;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: 60vh;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(65, 21, 100);
    background: linear-gradient(55deg, rgba(65, 21, 100, 1) 0%, rgba(10, 95, 85, 1) 95%, rgba(10, 95, 85, 1) 100%);
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
    transform: scaleX(1.5);

    @media (max-width: 768px) {
      transform: scaleX(2.5);
    }
  }

  section {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 900px;
    text-align: center;
  }
`;

export const HeaderFilter = styled.li`
  background: rgba(0, 0, 0, 0.3);
  list-style: none;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  margin-bottom: 5px;

  @media (max-width: 320px) {
    font-size: 12px;
  }
`;
