import * as Yup from 'yup';

function transform(value, originalValue) {
  if (originalValue.trim() === '' || originalValue.trim() === '*') {
    return null;
  } else {
    return value;
  }
}

const schema = Yup.object().shape({
  id: Yup.number()
    .nullable()
    .transform(transform),
  nome: Yup.string().required('O campo Nome é obrigatório'),
  setorId: Yup.number('O campo Setor é obrigatório').required('O Campo setor é obrigatório'),
  coordenadorId: Yup.number('O campo Responsável Pelo Processo é obrigatório').required('O Campo coordenador é obrigatório'),
  quantidade: Yup.number()
    .integer()
    .nullable()
    .transform(transform),
  salario: Yup.string(),
  dataInicio: Yup.date().required('O campo Data de Início da Divulgação é obrigatório'),
  mediaAprovacao: Yup.string(),
  horario: Yup.string(),
  divulgarVaga: Yup.bool(),
  beneficios: Yup.string(),
  descricaoCargo: Yup.string(),
  requisitosMinimos: Yup.string(),
  experienciaDesejavel: Yup.string(),
  conhecimentoDesejavel: Yup.string(),
  complementaPerfil: Yup.string(),
  etapaProcessoSeletivos: Yup.array().of(
    Yup.object().shape({
      id: Yup.number()
        .nullable()
        .transform(transform),
      etapaId: Yup.number()
        .nullable()
        .transform(transform),
      sequencia: Yup.number()
        .nullable()
        .transform(transform),
      processoSeletivoId: Yup.number()
        .nullable()
        .transform(transform),
      valorEtapa: Yup.number()
        .nullable()
        .transform(transform),
      lancarValor: Yup.bool(),
      aptoInapto: Yup.bool(),
      caracteristicaEtapaProcessos: Yup.array(),
    }),
  ),
});

export default schema;
