import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div``;

export const ReactInputMasks = styled(InputMask)`
	display: block;
	caret-color: rgb(33, 150, 243);
	background-color: rgb(242, 245, 250);
	color: rgb(38, 43, 51);
	pointer-events: unset;
	border-radius: 3px;
	padding: 11px 16px;
	outline: none;
	border-width: 0px;
	border-style: initial;
	border-color: initial;
	border-image: initial;
	transition: color 0.1s ease 0s;
`;
