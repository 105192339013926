import React, { useEffect, useState, memo, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdPrint } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, Choice, Scope } from '@rocketseat/unform';
import { useSnackbar } from 'notistack';
import Props from 'prop-types';

import { store } from '~/store';
import { toggleLoading } from '~/store/modules/main/actions';
import { toggleModal } from '~/store/modules/modal/actions';

import Button from '../../../components/Button';
import DatePickerMaterial from '../../../components/DatePickerMaterial';
import ReactInput from '../../../components/ReactInput';
import ReactSelect from '../../../components/ReactSelect';
import SchedulerMini from '../../../components/SchedulerMini';
import * as routes from '../../../configs/Routes';
import { formatPhoneNumber, getLocalDate } from '../../../functions/curriculos';
import api from '../../../services/api';
import { SchemaAvaliacao, SchemaDesclassificacao } from './Schema';
import {
  DashContainer,
  ButtonContainer,
  FieldContainer,
  SchedulerContainer,
  UserDataContainer,
  RejectButtonContainer,
  CarateristicasContainer,
  ChoiceContainer,
  LabelChoice,
  DesclassificacaoContainer,
} from './styles';

function Interview({ curriculoData, stepToGo, processoSeletivoId, refresh }) {
  const {
    etapaProcessoSeletivoId,
    etapaProcessoSeletivos,
    curriculo: { usuario, telefone1, telefone2 },
  } = curriculoData;
  const { profile: userData } = store.getState().user;
  const { enqueueSnackbar } = useSnackbar();
  const [caracteristicas, setCaracteristicas] = useState([]);
  const options = [
    { value: 1, label: 'Não atende' },
    { value: 2, label: 'Atende Parcialmente' },
    { value: 3, label: 'Atende' },
    { value: 4, label: 'Supera' },
  ];
  const [candidateData, setCandidateData] = useState(curriculoData);
  const [etapa] = useState(
    curriculoData.etapaProcessoSeletivos.filter(etp => etp.id === curriculoData.etapaProcessoSeletivoId)[0],
  );

  const [tiposClassificacaoCurriculo, setTiposClassificacaoCurriculo] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!etapaProcessoSeletivos) return;
    let pontuacaoProcessoSeletivo = [];
    etapaProcessoSeletivos.forEach(async item => {
      if (item.id === etapaProcessoSeletivoId) {
        const {
          data: { data: caracteristicaEtapaProcessos },
        } = await api.get('/caracteristicaetapaprocesso/byetapaprocessoseletivo', { headers: { id: item.id } });
        caracteristicaEtapaProcessos.forEach(caract => {
          setCaracteristicas(car => [...car, caract.caracteristicaCompetencia]);
          const novaPontuacao = {
            caracteristicaEtapaProcessoId: caract.id,
            etapaProcessoSeletivoId: caract.etapaProcessoSeletivoId,
            curriculoId: curriculoData.curriculoId,
            processoSeletivoId: curriculoData.processoSeletivoId,
          };
          pontuacaoProcessoSeletivo = [...pontuacaoProcessoSeletivo, novaPontuacao];
        });
        setCandidateData(can => ({
          ...can,
          pontuacaoProcessoSeletivo,
        }));
      }
    });
  }, [curriculoData.curriculoId, curriculoData.processoSeletivoId, etapaProcessoSeletivoId, etapaProcessoSeletivos]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get('tipoClassificacaoCurriculo');
      if (data.length === 0) return;
      data.forEach(item => [setTiposClassificacaoCurriculo(tipo => [...tipo, { id: item.id, title: item.nome }])]);
    };
    fetchData();
  }, []);

  const handleSubmit = useCallback(
    async data => {
      dispatch(toggleLoading(true));
      const curriculoEtapaProcessoSeletivo = {
        id: curriculoData.id,
        etapaProcessoSeletivoId: stepToGo.id,
        dataAtualizacao: getLocalDate(),
        usuarioAtualizacao: userData.id,
        horarioAgendamento: data.horarioAgendamento,
      };

      const { data: retorno } = await api.put('curriculoetapaprocessoseletivo', curriculoEtapaProcessoSeletivo);
      if (retorno.status) {
        const observacaoCurriculoEtapaProcessoSeletivo = {
          curriculoId: curriculoData.id,
          etapaProcessoSeletivoId: curriculoData.etapaProcessoSeletivoId,
          processoSeletivoId,
          observacao: data.observacao,
          dataCadastro: getLocalDate(),
          usuarioCadastro: userData.id,
          horarioAgendamento: data.horarioAgendamento,
        };

        const { data: retornoObservacao } = await api.post(
          'observacaocurriculoetapaprocessoseletivo',
          observacaoCurriculoEtapaProcessoSeletivo,
        );

        if (retornoObservacao.status) {
          try {
            if (data.pontuacaoProcessoSeletivo) {
              data.pontuacaoProcessoSeletivo.forEach(async pontuacao => {
                await api.post('pontuacaoprocessoseletivo', {
                  ...pontuacao,
                  dataCadastro: new Date(),
                  usuarioCadastro: userData.id,
                });
              });
            }

            refresh(curriculo => {
              const curriculoEditado = curriculo.filter(c => c.id === curriculoData.id);
              curriculoEditado[0].etapaProcessoSeletivoId = stepToGo.id;
              return [...curriculo.filter(c => c.id !== curriculoData.id), ...curriculoEditado];
            });

            enqueueSnackbar(retorno.message, { variant: 'success' });
            dispatch(toggleModal(false, null, null, null));
          } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(retornoObservacao.message, { variant: 'error' });
        }
      } else {
        enqueueSnackbar(retorno.message, { variant: 'error' });
      }
      dispatch(toggleLoading(false));
    },
    [
      curriculoData.etapaProcessoSeletivoId,
      curriculoData.id,
      dispatch,
      enqueueSnackbar,
      processoSeletivoId,
      refresh,
      stepToGo,
      userData.id,
    ],
  );

  const handleRemoveCandidate = async data => {
    dispatch(toggleLoading(true));
    const curriculoEtapaProcessoSeletivo = {
      id: curriculoData.id,
      dataAtualizacao: getLocalDate(),
      usuarioAtualizacao: userData.id,
      excluirDoProcesso: 1,
    };

    const { data: retorno } = await api.put('curriculoetapaprocessoseletivo', curriculoEtapaProcessoSeletivo);

    if (retorno.status) {
      const observacaoCurriculoEtapaProcessoSeletivo = {
        curriculoId: curriculoData.id,
        etapaProcessoSeletivoId: curriculoData.etapaProcessoSeletivoId,
        processoSeletivoId,
        tipoClassificacaoCurriculoId: data.tipoClassificacaoCurriculoId,
        observacao: 'Não foi possível fazer contato',
        dataCadastro: getLocalDate(),
        usuarioCadastro: userData.id,
      };

      const { data: retornoObservacao } = await api.post(
        'observacaocurriculoetapaprocessoseletivo',
        observacaoCurriculoEtapaProcessoSeletivo,
      );

      if (retornoObservacao.status) {
        refresh(curriculo => [...curriculo.filter(c => c.id !== curriculoData.id)]);
        enqueueSnackbar(retorno.message, { variant: 'success' });
        dispatch(toggleModal(false, null, null, null));
      } else {
        enqueueSnackbar(retornoObservacao.message, { variant: 'error' });
      }
    } else {
      enqueueSnackbar(retorno.message, { variant: 'errror' });
    }
    dispatch(toggleLoading(false));
  };

  return (
    <DashContainer>
      <Form onSubmit={handleSubmit} initialData={candidateData} schema={SchemaAvaliacao}>
        <Link
          className="action-buttons"
          to={{
            pathname: `${routes.ADMIN_RESUME_VIEWER}/?id=${curriculoData.curriculo.usuarioId}`,
            state: { userId: curriculoData.curriculo.usuarioId },
          }}
          target="_blank"
        >
          <MdPrint size={24} className="print-button" />
        </Link>
        <h3>{etapa.label}</h3>
        <UserDataContainer>
          <h4>{usuario.nome}</h4>
          <span className="user-contact">Telefone 1: {formatPhoneNumber(telefone1)}</span>
          <span className="user-contact">Telefone 2: {formatPhoneNumber(telefone2)}</span>
          <span className="user-contact">Email: {usuario.email}</span>
        </UserDataContainer>
        <Scrollbars
          style={{ height: 'calc(100vh - 360px)', width: '102.5%', overflowX: 'hidden' }}
          renderTrackHorizontal={rest => <div {...rest} style={{ display: 'none' }} className="track-horizontal" />} // eslint-disable-line
        >
          <FieldContainer>
            <ReactInput name="id" style={{ display: 'none' }} />
            <ReactInput name="curriculoId" style={{ display: 'none' }} />
            <ReactInput name="etapaProcessoSeletivoId" style={{ display: 'none' }} />
            <ReactInput name="processoSeletivoId" style={{ display: 'none' }} />
            <label htmlFor="horarioAgendamento">Agendar próxima etapa para:</label>
            <DatePickerMaterial
              id="horarioAgendamento"
              name="horarioAgendamento"
              dateFormat="dd/MM/yyyy HH:mm"
              type="keyboardDateTime"
            />
            <ReactInput name="observacao" label="Observação:" placeholder="Observação" rows="5" multiline />
            {caracteristicas && caracteristicas.length > 0 && options.length > 0 && (
              <>
                <h5>Características e Competências</h5>
                {caracteristicas.map((item, index) => (
                  <Scope path={`pontuacaoProcessoSeletivo[${index}]`} key={item.id}>
                    <ReactInput id="id" name="id" style={{ display: 'none' }} />
                    <ReactInput id="curriculoId" name="curriculoId" style={{ display: 'none' }} />
                    <ReactInput id="etapaProcessoSeletivoId" name="etapaProcessoSeletivoId" style={{ display: 'none' }} />
                    <ReactInput id="processoSeletivoId" name="processoSeletivoId" style={{ display: 'none' }} />
                    <ReactInput
                      id="caracteristicaEtapaProcessoId"
                      name="caracteristicaEtapaProcessoId"
                      style={{ display: 'none' }}
                    />
                    <CarateristicasContainer>
                      <LabelChoice htmlFor="pontuacao">{item.nome}</LabelChoice>
                      <ChoiceContainer>
                        <Choice name="pontuacao" options={options} />
                      </ChoiceContainer>
                    </CarateristicasContainer>
                  </Scope>
                ))}
              </>
            )}
            {etapa.lancarValor && (
              <>
                <h5>Pontuação da Etapa</h5>
                <Scope path="pontuacaoProcessoSeletivo[0]">
                  <ReactInput id="id" name="id" style={{ display: 'none' }} />
                  <ReactInput id="curriculoId" name="curriculoId" style={{ display: 'none' }} />
                  <ReactInput id="etapaProcessoSeletivoId" name="etapaProcessoSeletivoId" style={{ display: 'none' }} />
                  <ReactInput id="processoSeletivoId" name="processoSeletivoId" style={{ display: 'none' }} />
                  <ReactInput name="pontuacao" />
                </Scope>
              </>
            )}
          </FieldContainer>
        </Scrollbars>
        <ButtonContainer>
          <Button text="Confirmar Presença" color="#fff" backColor="#00995d" handleClick={() => {}} />
        </ButtonContainer>
      </Form>
      <SchedulerContainer>
        <SchedulerMini etapaProcessoSeletivoId={etapaProcessoSeletivoId} stepToGo={stepToGo} />
        <DesclassificacaoContainer>
          <Form onSubmit={handleRemoveCandidate} schema={SchemaDesclassificacao}>
            <ReactSelect
              options={tiposClassificacaoCurriculo}
              multiple={false}
              name="tipoClassificacaoCurriculoId"
              label="Motivo desclassificação"
              closeOnSelect
              fieldPointer="tipoClassificacaoCurriculoId"
              className="desclassificacao"
              menuPlacement="top"
            />
            <RejectButtonContainer>
              <Button text="Desclassificar candidato" color="#fff" backColor="#ff0000" handleClick={() => {}} />
            </RejectButtonContainer>
          </Form>
        </DesclassificacaoContainer>
      </SchedulerContainer>
    </DashContainer>
  );
}

Interview.propTypes = {
  curriculoData: Props.instanceOf(Object).isRequired,
  stepToGo: Props.instanceOf(Object).isRequired,
  processoSeletivoId: Props.number.isRequired,
  toggleLoading: Props.func.isRequired,
  toggleModal: Props.func.isRequired,
  refresh: Props.func.isRequired,
};

export default memo(Interview);
