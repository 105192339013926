import styled from 'styled-components';

export const Container = styled.div`
	background: #fff;
	h1 {
		color: #b1d34b;
	}

	span {
		color: #ffcb08;
	}

	h6 {
		color: #fff;
	}

	height: 100%;
`;

export const Header = styled.div`
	background: rgb(65, 21, 100);
	padding: 20px 30px 20px 20px;
	color: #fff;

	@media (max-width: 768px) {
		margin-bottom: 10px;

		h1 {
			font-size: 22px;
		}
	}
`;

export const DetailsContainer = styled.div`
	height: 100%;
	width: 100%;
	padding: 10px 20px;
	color: #222;
`;
