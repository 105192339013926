import styled from 'styled-components';

export const DashContainer = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100vh;
  width: 100%;
  height: 100%;
  padding: 10px 20px;

  h3 {
    color: #411564;
  }

  h5 {
    color: #411564;
    margin-top: 10px;
  }

  label {
    margin-top: 5px;
  }

  @media print {
    background-color: #fff;
    display: block !important;
    grid-template-columns: 1fr;
  }

  a.action-buttons {
    margin-left: 15px;
    color: #666;
    cursor: pointer;
    right: 55px;
    position: absolute;
    top: 16px;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  margin-top: 15px;
  bottom: 20px;
`;

export const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #00995d;
  padding: 15px 25px 15px;
  border-radius: 3px;
  color: #fff;
  margin-top: 18px;
  margin-bottom: 10px;
  border-left: 6px solid #0a5f55;

  h4 {
    color: #fff;
  }
`;

export const FieldContainer = styled.div`
  margin-right: 15px;

  span:not([class]) {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(255, 0, 0, 0.6);
    padding: 2px 10px 0;
  }
`;

export const SchedulerContainer = styled.div`
  margin-top: 40px;
  padding: 10px 0 0 20px;
`;

export const CarateristicasContainer = styled.div`
  background: rgba(255, 240, 199, 0.7);
  margin-bottom: 3px;
  padding: 0 10px;
  border-left: 5px solid #ffcb08;
  border-radius: 3px;
  position: relative;

  span:not([class]) {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(255, 0, 0, 0.6);
    padding: 2px 10px 0;
  }
`;

export const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  label {
    margin-top: -4px;
    margin-left: -100px;
  }
`;

export const LabelChoice = styled.label`
  font-weight: bold;
  color: #411564;
`;

export const DesclassificacaoContainer = styled.div`
  position: relative;
  margin-top: 20px;
  bottom: 10px;
  /* width: 100%; */

  form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;

    .desclassificacao {
      /* width: 26vw; */
    }

    span:not([class]) {
      position: absolute;
      top: 4px;
      right: 4px;
      color: white;
      background: rgba(255, 0, 0, 0.6);
      padding: 2px 10px 0;
    }
  }
`;

export const RejectButtonContainer = styled.div`
  width: 293px;
  margin-left: 30px;
  margin-top: 32px;
`;
