import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgb(255, 255, 255);
  user-select: none;
  pointer-events: auto !important;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(230, 235, 244);
  border-image: initial;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: height 0.8s ease 0s, opacity 0.8s ease 0s !important;
  padding: 20px;

  @media (max-width: 768px) {
    /* width: 82vw; */
  }

  label {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    color: rgb(152, 161, 179);
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 300;
    overflow: hidden;
    transition: color 0.15s ease 0s;
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  flex-flow: row nowrap;

  &:hover {
    color: #411564 !important;

    .tools-container {
      opacity: 1;
    }

    .hover-title {
      color: #f47920;
    }
  }

  @media (max-width: 768px) {
    .tools-container {
      opacity: 1;
      color: #411564;
    }
  }
`;

export const SubHeaderContainer = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: 0px;
  margin-right: 8px;
`;

export const BodyForm = styled.div`
  margin-top: ${props => (props.show ? '24px' : '0px')};
  height: ${props => (props.show ? '608px' : '0px')};
  opacity: ${props => (props.show ? '1' : '0')};
  overflow: ${props => (props.show ? 'visible' : 'hidden')};
  transition: height 0.4s ease 0s, opacity 0.8s ease 0s, margin-top 0.5s ease 0s !important;

  @media (max-width: 1280px) {
    height: ${props => (props.show ? 'auto' : '0px')};
  }

  textarea {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DescriptionContainer = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 0%;
  overflow: hidden;
  font-weight: 600;
  transition: color 0.15s ease 0s;

  &:hover {
    color: #f47920;

    .tools-container svg {
      color: #f47920 !important;
    }
  }
`;

export const PeriodoContainer = styled.small`
  color: rgb(152, 161, 179);
  margin-right: 5px;
  font-size: 13px !important;
  transition: color 0.15s ease 0s;
`;

export const ToolsContainer = styled.div`
  color: rgb(152, 161, 179);
  display: flex;
  flex-flow: row nowrap;
  /* opacity: 0; */

  .tools-button {
    cursor: pointer;
    margin-right: 4px;
    display: block;
    position: relative;
  }
`;

export const DeleteContainer = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  width: 50px;
  margin-left: 20px;
  margin-top: -15px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
