/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect, memo } from 'react';
import { MdDelete, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import DatePicker from '../../../components/DatePickerMaterial';
import ReactSwitch from '../../../components/ReactSwitch';
// import UniTextEditor from '../../../components/UniTextEditor';
import { alertaExclusao, getUTCDate } from '../../../functions/curriculos';
import { InputResume } from '../styles';
import {
  Container,
  Header,
  SubHeaderContainer,
  ToolsContainer,
  DeleteContainer,
  BodyForm,
  DescriptionContainer,
  PeriodoContainer,
} from './styles';

const HistoricoProfissional = ({ itemIndex, remove, data, setHistorico, dadosItem, refresh, handleSubmit, cid }) => {
  const [cargo, setCargo] = useState('(não especifiado)');
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [, setDescricaoAtividades] = useState('');
  const [empresa, setEmpresa] = useState('(não especificado)');
  const [, setSetor] = useState('');
  const [showBody, setShowBody] = useState(false);
  const [empregoAtual, setEmpregoAtual] = useState(false);

  useEffect(() => {
    setCargo(dadosItem.cargo);
    setEmpresa(dadosItem.empresa);
    setDataFinal(dadosItem.dataFinal);
    setDataInicial(dadosItem.dataInicial);
    setEmpregoAtual(Boolean(dadosItem.empregoAtual));
    if (!dadosItem.cargo) {
      setShowBody(true);
    }
  }, [dataInicial, dataFinal]);

  useEffect(() => {
    if (empregoAtual) setDataFinal(new Date());
  }, [empregoAtual]);

  const innerRef = useRef(null);

  return (
    <Container ref={innerRef}>
      <Header>
        <SubHeaderContainer onClick={() => setShowBody(!showBody)}>
          <DescriptionContainer className="hover-title">
            {cargo} na empresa {empresa}
          </DescriptionContainer>
          <PeriodoContainer>
            {dataInicial && format(getUTCDate(dataInicial), 'MMM yyyy')} {dataInicial && dataFinal && ' - '}
            {dataFinal && format(getUTCDate(dataFinal), 'MMM yyyy')}
          </PeriodoContainer>
        </SubHeaderContainer>
        <ToolsContainer className="tools-container">
          {showBody ? (
            <MdKeyboardArrowUp size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          ) : (
            <MdKeyboardArrowDown size={24} className="tools-button" onClick={() => setShowBody(!showBody)} />
          )}
        </ToolsContainer>
        <DeleteContainer className="tools-container">
          <MdDelete
            size={24}
            className="tools-button"
            onClick={() =>
              alertaExclusao(`Confirma a exclusão do cargo ${cargo} na empresa ${empresa}?`, () =>
                remove(data, setHistorico, itemIndex, 'historicoProfissional', dadosItem.id, refresh, cid),
              )
            }
          />
        </DeleteContainer>
      </Header>
      <BodyForm show={showBody}>
        <InputResume id="id" name="id" type="hidden" />
        <InputResume id="cid" name="cid" type="hidden" />
        <InputResume id="sequencia" name="sequencia" type="hidden" />
        <div className="row-fields">
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="empregoAtual">Emprego Atual?</label>
              <ReactSwitch
                id="empregoAtual"
                name="empregoAtual"
                textoLigado="Sim"
                textoDesligado="Não"
                onChange={setEmpregoAtual}
              />
            </div>
          </div>
        </div>
        <div className="row-fields">
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="cargo">Cargo *</label>
              <InputResume id="cargo" name="cargo" onChange={e => setCargo(e.target.value)} maxLength={50} />
            </div>
          </div>
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="empresa">Empresa *</label>
              <InputResume id="empresa" name="empresa" onChange={e => setEmpresa(e.target.value)} maxLength={100} />
            </div>
          </div>
        </div>
        <div className="row-fields">
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="dataInicial">Período Inicial *</label>
              <DatePicker id="dataInicial" name="dataInicial" dateFormat="dd/MM/yyyy" type="keyboardDate" onBlur={handleSubmit} />
            </div>
            <div className="sub-column-field">
              <div className="field-sub-container">
                <label htmlFor="dataFinal">Período Final {!empregoAtual && '*'}</label>
                <DatePicker id="dataFinal" name="dataFinal" dateFormat="dd/MM/yyyy" type="keyboardDate" onBlur={handleSubmit} />
              </div>
            </div>
          </div>
          <div className="column-field">
            <div className="field-sub-container">
              <label htmlFor="setor">Setor</label>
              <InputResume id="setor" name="setor" onChange={e => setSetor(e.target.value)} maxLength={2500} />
            </div>
          </div>
        </div>
        <div className="row-fields">
          <label htmlFor="descricaoAtividades">Descrição de atividades</label>
          <InputResume
            id="descricaoAtividades"
            name="descricaoAtividades"
            onChange={e => setDescricaoAtividades(e.target.value)}
            multiline
            rows={10}
            style={{ textAlign: 'justify', marginBottom: '0px !important' }}
          />
        </div>
      </BodyForm>
    </Container>
  );
};

HistoricoProfissional.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  setHistorico: PropTypes.func.isRequired,
  dadosItem: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cid: PropTypes.string,
};

HistoricoProfissional.defaultProps = {
  cid: null,
};

export default memo(HistoricoProfissional);
