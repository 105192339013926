import React from 'react';

import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { differenceInYears } from 'date-fns';

const styles = StyleSheet.create({
  document: {
    backgroundColor: '#fff',
  },
  page: {
    padding: '2rem 3rem',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    height: '100vh',
  },
  header: {
    marginBottom: 30,
    flexGrow: 1,
  },
  fullName: {
    fontSize: 38,
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  firstName: {
    fontWeight: 700,
  },
  lastName: {
    fontWeight: 300,
  },
  contactInfo: {
    marginBottom: 2,
  },
  phoneEmail: {
    color: '#999',
    fontWeight: 300,
  },
  phoneEmailVal: {
    color: '#333',
    fontWeight: 400,
  },
  separator: {
    height: 10,
    display: 'inline-block',
    borderLeft: '2px solid #999',
    margin: '0 10px',
  },
  position: {
    fontWeight: 'bold',
    display: 'inline-block',
    marginRight: 10,
    textDecoration: 'underline',
  },
  body: {
    marginTop: 5,
  },
});
function PreviewBox() {
  return (
    <Document
      author="Luke Skywalker"
      keywords="awesome, resume, start wars"
      subject="The resume of Luke Skywalker"
      title="Resume"
      onRender={() => {}}
    >
      <Page size="A4">
        <View style={styles.header}>
          <View style={styles.fullName}>
            <Text style={styles.firstName}>Mateus </Text>
            <Text style={styles.lastName}> Cabral da Silva</Text>
          </View>
          <View style={styles.contactInfo}>
            <Text style={styles.phoneEmail}>Email: </Text>
            <Text style={styles.phoneEmailVal}>contato@orangedev.com.br</Text>
            <Text style={styles.separator} />
            <Text style={styles.phoneEmail}>Telefone: </Text>
            <Text style={styles.phoneEmailVal}>(35) 3223-2171</Text>
            <Text style={styles.separator} />
            <Text style={styles.phoneEmailVal}>(35) 9.8812-2171</Text>
          </View>
          <View style={styles.contactInfo}>
            <Text style={styles.phoneEmail}>Idade: </Text>
            <Text style={styles.phoneEmailVal}>{differenceInYears(new Date(), new Date('1985-01-18'))} anos</Text>
            <Text style={styles.separator} />
            <Text style={styles.phoneEmail}>Estado Civil: </Text>
            <Text style={styles.phoneEmailVal}>Casado</Text>
            <Text style={styles.separator} />
            <Text style={styles.phoneEmail}>Carteira de Habilitação: </Text>
            <Text style={styles.phoneEmailVal}>B</Text>
          </View>
        </View>
        <View style={styles.body}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  );
}

export default PreviewBox;
