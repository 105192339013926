import React from 'react';

import { Form, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import { RowContainer, FieldContainer } from '../styles';

function ErrorReport({ submitErrorHandler }) {
  return (
    <div>
      <h1>Reportar Erro / Sugerir Melhorias</h1>
      <Form onSubmit={submitErrorHandler}>
        <Input style={{ display: 'none' }} name="teste1" type="text" />
        <Input style={{ display: 'none' }} name="teste2" type="text" />
        <Input style={{ display: 'none' }} name="teste3" type="text" />
        <RowContainer>
          <FieldContainer width={570}>
            <label htmlFor="titulo">Título</label>
            <Input name="titulo" maxLength={200} />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer width={570}>
            <label htmlFor="descricao">Descrição</label>
            <Input name="descricao" multiline rows="8" maxLength={1000} />
          </FieldContainer>
        </RowContainer>
        <Button color="#fff" backColor="#00995d" handleClick={() => {}} text="Enviar" />
      </Form>
    </div>
  );
}

ErrorReport.propTypes = {
  submitErrorHandler: PropTypes.func.isRequired,
};

export default ErrorReport;
