/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';

import { Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Container } from './styles';

const ReactRadioBig = ({ name, options, onChange, regime, submitHandler }) => {
  const getLastName = fullName => {
    const nameArray = fullName.split(' ');
    return nameArray.pop();
  };

  return (
    <Container>
      <div className="option-group">
        <div className="option-container">
          {options &&
            options.map(item => (
              <Input
                className="option-input"
                onChange={() => {}}
                value={regime}
                checked={item.id === regime}
                type="radio"
                name={name}
                key={item.id}
              />
            ))}

          {options &&
            options.map(item => (
              <label
                className="option"
                htmlFor={item.id}
                onClick={() => {
                  onChange(item.id);
                  submitHandler();
                }}
                key={item.id}
                aria-hidden
              >
                <span className="option__indicator" />
                <span className="option__label">
                  <sub className="sub">{isMobile ? getLastName(item.title) : item.title}</sub>
                </span>
              </label>
            ))}
        </div>
      </div>
    </Container>
  );
};

ReactRadioBig.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  regime: PropTypes.number.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default memo(ReactRadioBig);
