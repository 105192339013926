import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 50px);
  width: 250px;
  margin-top: 80px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow-x: hidden;
  color: #222;
  text-align: right;
  padding-top: 0 15px;
  cursor: pointer;
  background-color: rgba(40, 40, 40, 0.9);
  transition: all 0.5s;
  transform: ${props => (props.open ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, 0, 0)')};

  &:after {
    transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;
