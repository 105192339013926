import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';

import PropTypes from 'prop-types';

import ReactInput from '../../../components/ReactInput';
import ReactSelect from '../../../components/ReactSelect';
import ReactSwitch from '../../../components/ReactSwitch';
import { Container, Header, FormContainer } from './styles';

export default function ConfiguracaoProcesso({
  options,
  showConfig,
  setShowConfig,
  sendStepValue,
  etapaProcessoSeletivoId,
  title,
  lancarValor,
  aptoInapto,
}) {
  const [lancarVal, setLancarVal] = useState(Boolean(lancarValor));
  const [apto, setApto] = useState(Boolean(aptoInapto));

  return (
    <Container showConfig={showConfig}>
      <Header>
        Configurar etapa do processo - {title} <MdClose size={24} onClick={() => setShowConfig(false)} />
      </Header>
      <FormContainer>
        <ReactInput name="id" style={{ display: 'none' }} />
        <ReactInput name="etapaId" style={{ display: 'none' }} />
        <ReactInput name="sequencia" style={{ display: 'none' }} />
        <ReactInput name="title" style={{ display: 'none' }} />
        <ReactInput name="processoSeletivoId" style={{ display: 'none' }} />
        <div className="one-row">
          <div>
            <ReactInput
              name="valorEtapa"
              label="Valor da Etapa (%)"
              className="valor-etapa"
              onInput={e => sendStepValue(etapaProcessoSeletivoId, e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="lancarValor">Lançar Valor</label>
            <ReactSwitch name="lancarValor" textoLigado="Sim" textoDesligado="Não" onChange={setLancarVal} checked={lancarVal} />
          </div>
          <div>
            <label htmlFor="lancarValor">Usar Apto ou Inapto</label>
            <ReactSwitch name="aptoInapto" textoLigado="Sim" textoDesligado="Não" onChange={setApto} checked={apto} />
          </div>
        </div>
        {!lancarVal && !apto && (
          <ReactSelect
            multiple
            name="caracteristicaEtapaProcessos"
            options={options}
            closeOnSelect={false}
            label="Características e Competências"
            fieldPointer="caracteristicaCompetenciaId"
            className="config-etapa"
          />
        )}
      </FormContainer>
    </Container>
  );
}

ConfiguracaoProcesso.propTypes = {
  sendStepValue: PropTypes.func.isRequired,
  showConfig: PropTypes.bool.isRequired,
  setShowConfig: PropTypes.func.isRequired,
  etapaProcessoSeletivoId: PropTypes.number,
  options: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  lancarValor: PropTypes.number.isRequired,
  aptoInapto: PropTypes.number.isRequired,
};

ConfiguracaoProcesso.defaultProps = {
  etapaProcessoSeletivoId: null,
};
